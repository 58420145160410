/* global EM */
import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";
import Routes from "../app/Routes";

export default class OrganizationTabs extends Component {
    render() {
        let domain = this.props.domain ? this.props.domain.Name : '';
        return (
            <Nav tabs>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.organizations, { DomainName: domain })}>
                        {EM.t('header.organizations')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.departments, { DomainName: domain })}>
                        {EM.t('header.departments')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="nav-link" to={Routes.compose(Routes.client.roles, { DomainName: domain })}>
                        {EM.t('header.roles')}
                    </NavLink>
                </NavItem>
            </Nav>
        );
    }
}
