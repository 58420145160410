/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

class Attributes extends Component {
    constructor(props){
        super(props);
        EM.attributes.load();     
        EM.attributeTypeLookup.load();  
        //EM.resourceRequests.load(); 
        this.onBeforeImport = this.onBeforeImport.bind(this);
    }

    onBeforeImport(data){
        //do any pre-import processing as needed      
        return data;
    }

    render() {    
        let data = null;
        let columns = null;
        const entity = EM.attributes;   
        const attributeTypeLookup = EM.attributeTypeLookup;
            
        if (EM.allLoaded(entity) && EM.allLoaded(attributeTypeLookup)){    
            data = entity.get();            
            columns = [{
                dataField: 'AttributeTypeLookupId',
                text: entity.columns('type'),
                fromEntity: EM.attributeTypeLookup,
                width: 150,
                validators: [ Validators.required ]
            },{
                dataField: 'Scope',
                text: entity.columns('scope'),
                asWorkItem: true,
                width: 150,
                filterOptions: {
                    text: true
                }
            },{
                dataField: 'Name',
                text: entity.columns('attribute'),
                width: 200,
                validators: [ Validators.required ],
                filterOptions: {
                    text: true
                }         
            }, {
                dataField: 'Value',
                text: entity.columns('value'),
                validators: [ Validators.required ],
                width: 150,
                filterOptions: {
                    text: true
                }
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable onBeforeImport={this.onBeforeImport} entity={entity} data={data} columns={columns} className="table-overflow-container"/>
            </div>
        );
    }
}

export default Attributes;
