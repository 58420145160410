/* eslint eqeqeq: "off" */
import React, { Component } from 'react';
import StaffingEmployeeDetail from './StaffingEmployeeDetail';
import StaffingUtil from '../../util/StaffingUtil';
import Dates from '../../util/Dates';

export default class StaffingEmployeeRow extends Component{  
    render(){
        let {employee, range, assignments, isActive, onClick, maxWidth} = this.props;

        let assignmentDateGroups = StaffingUtil.getAssignmentDateGroups(assignments);
        let filteredAssignments = assignments ? assignments.filter(assignment => {
            return Dates.doMonthRangesOverlap(assignment.range, range);
        }) : [];
        let marginBuffer = 0;
        let cellWidth = this.props.cellWidth;
        const uniqueWorkItems = [];
        const uniqueAssignments = filteredAssignments?.filter(item => {
        const isDuplicate = uniqueWorkItems.includes(item.WorkItemName);
        if (!isDuplicate) {
          uniqueWorkItems.push(item.WorkItemName);
          return true;
        }
        return false;
        });
        return (
            <div className={"item " + (isActive ?'active':'') + (employee.Active ? '' : ' text-muted')} onClick={onClick}>                
                <div className="item-header" id={employee.EmployeeId} title={employee.FirstName + ' ' + employee.LastName + ' (' + employee.Role + ')'}>                    
                    {employee.Contractor ? '* ' : ''}{employee.FirstName} {employee.LastName}
                </div>
                {range.dates.map((month, monIndex) => {   
                    let dateKey = range.beginIndex + monIndex;
                    let value = assignmentDateGroups[dateKey];
                    let cls = StaffingUtil.getColorClass(value, employee.TimeAllocation || 1);
                    if (value > 0){
                        let output = <div className={"item-element " + cls} key={monIndex} style={{ marginLeft: marginBuffer * cellWidth }}>{(value*100).toFixed()}%</div>
                        marginBuffer = 0;
                        return output;
                    }else{
                        marginBuffer++;
                        return null;
                    }
                })}
                {isActive ?
                    <StaffingEmployeeDetail 
                        employee={employee}
                        range={range}
                        cellWidth={cellWidth}
                        rowHeight={this.props.rowHeight}
                        assignments={assignments}
                        combinedAssignments = {uniqueAssignments}
                        maxWidth={maxWidth}
                        isActive={isActive}
                        onUpdate={this.props.onUpdate}
                        onAdd={this.props.onAdd}
                        onDelete={this.props.onDelete}
                        onDuplicate={this.props.onDuplicate}
                        onToggleLock={this.props.onToggleLock}
                        onEdit={this.props.onEdit}
                    />
                : null }
            </div>
        );
    }
}