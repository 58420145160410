/* global EM */
import React, { Component } from 'react';
import { Modal } from 'reactstrap';

export default class PanelModal extends Component {
    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'panel ' + (EM.currentTheme === 'Dark'?'dark-mode ': ' ') + this.props.className}>
                {this.props.children}
            </Modal>
        );
    }
}
