/* global EM */
import React, { Component } from 'react';
import Select from 'react-select';
import { Button, ButtonGroup, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class DemandReportOptionsDialog extends Component {
    constructor(props) {
        super(props);

        this.tableTypes = [ 
            { value: 'listing', label: EM.t('reports.demand.tableTypes.listing') }, 
            { value: 'primaryDifference', label: EM.t('reports.demand.tableTypes.primaryDifference') }  
        ]; 

        this.state = {
            tableType: this.tableTypes[0],
            showChart: true,
            primary: 'primary',
            secondary: 'secondary',
            tertiary: 'tertiary'
        };

        this.onClose = this.onClose.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
    }

    onClose(){
        this.props.onClose();
    }

    onGenerateReport(){
        let cleanState = Object.assign({}, this.state, { tableType: this.state.tableType.value });        
        this.props.onGenerateReport(cleanState);
    }

    render() {               
        return (
            <Modal ref={el => (this.componentRef = el)} fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'demand-report-options ' + this.props.className} key="demand-report-options">
                <ModalHeader toggle={this.onClose}>
                    {EM.t('demand.reportOptions.title')}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>{EM.t('demand.reportOptions.tableType')}:</Label>
                        <Select
                            className="single-select"
                            classNamePrefix="single-select"
                            isSearchable={false}
                            options={this.tableTypes}
                            value={this.state.tableType}
                            placeholder={EM.t('demand.reportOptions.tableType')}
                            onChange={(selectedItem) => {
                                this.setState({ tableType: selectedItem });
                            }}
                        /> 
                    </FormGroup>
                    <FormGroup>
                        <Label>{EM.t('demand.reportOptions.showChart')}</Label>
                        <ButtonGroup className="d-block">
                            <Button active={this.state.showChart} onClick={() => {
                                this.setState({ showChart: true });
                            }}>{EM.t('util.common.yes')}</Button>
                            <Button active={!this.state.showChart} onClick={() => {
                                this.setState({ showChart: false });
                            }}>{EM.t('util.common.no')}</Button>
                        </ButtonGroup>    
                    </FormGroup>                                            
                </ModalBody>
                <ModalFooter>                                 
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                    <Button color="success" onClick={this.onGenerateReport}>{EM.t('demand.reportOptions.generateReport')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}