/* eslint no-extend-native: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from "react-redux";
import Authenticator from './app/Authenticator';
import AuthenticatorLegacy from './app/AuthenticatorLegacy';
import AuthenticatorOIDC from './app/AuthenticatorOIDC';
import API from './app/API';
import EntityManager from './entities/EntityManager';
import reducers from './entities/reducers';
import App from './app/App';
import Routes from './app/Routes';
import ErrorBlock from './components/ErrorBlock';
import LoginBlock from './components/LoginBlock';
import LoginSelector from './components/LoginSelector';
import LoggingInNotice from './components/LoggingInNotice';
import PreLoginError from './components/PreLoginError';
import Common from './util/Common';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './style/index.scss';
import * as serviceWorker from './serviceWorker';

(async function(){
    Common.registerGlobal();
    serviceWorker.register();

    let instance = { key: null, loginModes: [ 'Enterprise' ] };

    let qsObj = Routes.parseQuery();
    if (qsObj.host){
        if (qsObj.host === 'clear'){
            window.localStorage.removeItem('rm.manual_host');
        }else{
            window.localStorage.setItem('rm.manual_host', qsObj.host);
        }
        window.location = window.location.origin;
    }

    let host = window.localStorage.getItem('rm.manual_host') || window.location.host;
    let hostBits = host.split('.');
    if (
        hostBits.length > 2 && 
        hostBits[0] !== 'test' &&
        hostBits[0] !== 'nexttest' &&
        hostBits[0] !== 'prod' &&
        hostBits[0] !== 'www' && 
        hostBits[0] !== 'next' 
    ){
        try{
            const instancesResp = await fetch(API.getBaseAddress() + 'instance?host=' + host).then(responseText => { return responseText.json()}).catch(() => {});            
            if (instancesResp.status === 'success'){                
                instance = instancesResp.data;
            }else{
                console.log('Instance API call returned:', instancesResp.message);    
                ReactDOM.render(<PreLoginError error={{ message: 'Instance API call returned: ' + instancesResp.message }}/>, document.getElementById('root'));
                return;
            }        
        }catch(e){
            console.log('Instance API call failed:', e);
            ReactDOM.render(<PreLoginError error={e}/>, document.getElementById('root'));
            return;
        }
    }

    let loginMode = window.sessionStorage.getItem('rm.login_mode');
    if (!loginMode){
        ReactDOM.render(<LoginSelector instance={instance} onSelectLoginMode={ mode => {
            window.sessionStorage.setItem('rm.login_mode', mode);
            login(mode, instance);
        }} />, document.getElementById('root')); 
        return;
    }else{
        login(loginMode, instance);
    }
})();

async function login(loginMode, instance){
    try{
        console.log('Login mode: ' + loginMode);

        //const AuthenticatorClass = (loginMode === 'Enterprise' ? Authenticator : AuthenticatorLegacy);
        const AuthenticatorClass = (loginMode === 'Enterprise' ? Authenticator : (loginMode === 'OIDC' ? AuthenticatorOIDC : AuthenticatorLegacy));
        await AuthenticatorClass.load();

        const authenticator = new AuthenticatorClass(instance);  
        if (authenticator.initialize){
            await authenticator.initialize();
        }

        if (!await authenticator.isAuthenticated()){
            try{                
                if (authenticator.loginStyle === 'popup'){
                    ReactDOM.render(<LoggingInNotice />, document.getElementById('root'));
                    await authenticator.login().then(() => {
                        ReactDOM.render(<div id="pre-load"/>, document.getElementById('root'));
                    });
                }else{
                    authenticator.login();
                    return;
                }        
            }catch(e){
                console.log(e);
                ReactDOM.render(<LoginSelector logout={authenticator.logout} instance={instance} onSelectLoginMode={mode => {
                    window.sessionStorage.setItem('rm.login_mode', mode);
                    window.location.reload();
                }} />, document.getElementById('root'));
                return;
            } 
        }
        
        if (authenticator.isCallback || (window !== window.parent && !window.opener)){
            console.log('Silent request via iFrame completed.');
            return;
        }

        initialize(authenticator, instance, loginMode);
    }catch(e){
        ReactDOM.render(<PreLoginError error={e}/>, document.getElementById('root'));
    }
}

async function initialize(authenticator, instance, loginMode){
    const api = new API(authenticator, instance.key, loginMode);
    const store = createStore(reducers, applyMiddleware(thunk.withExtraArgument(api)));
    window.EM = new EntityManager(store, authenticator, api);
    
    var success = await window.EM.initialize();
    
    let component = null;
    if (success){
        component = <App />;
        let qsObj = Routes.parseQuery();
        if (qsObj.code){
            window.history.replaceState({}, document.title, '/'); 
        }
    }else{
        let state = store.getState();
        if (state.error){
            if (state.error.errorCode === 'account:100' || state.error.errorCode === 'account:101'){                
                component = <LoginBlock authenticator={authenticator}/>;
            }else{
                component = <ErrorBlock />;
            }
        }else{
            component = <ErrorBlock />;
        }
    }   

    ReactDOM.render(<Provider store={store}>{component}</Provider>, document.getElementById('root')); 
}