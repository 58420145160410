/* global EM */
import React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PanelModal from '../PanelModal';
import EntityTable from '../EntityTables/EntityTable';
import _ from 'underscore';
import { Validators } from '../../util/EntityValidators';

export default class IdentifyOrphansModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discrepancies: null
        };

        this.closeDialog = this.closeDialog.bind(this);

        EM.roles.load();
        EM.activities.load();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isShowing !== this.props.isShowing) {
            return true;
        }
        if (nextProps.assignments !== this.props.assignments) {
            return true;
        }
        if (nextProps.schedule !== this.props.schedule) {
            return true;
        }
        if (nextState.discrepancies !== this.state.discrepancies) {
            return true;
        }
        return false;
    }

    componentDidMount(){
        this.processDiscrepancies();
    }

    processDiscrepancies() {     
        if (!this.props.defaultScheduleContent) return [];        
        let indexes = this.props.defaultScheduleContent.indexes;

        let assignmentGroups = _.groupBy(this.props.assignments, (item) => {
            if (!item) return;
            return item.WorkItemName.trim();
        });        
        let assignmentGroupKeys = Object.keys(assignmentGroups).sort();

        let scheduleGroups = _.groupBy(this.props.defaultScheduleContent.data, (item) => {
            return item[indexes.workitem].trim();
        });

        let additionalTaskSetting = EM.getSetting('AdditionalTasks');  
        let additionalTasks = {};
        if (additionalTaskSetting){
            additionalTaskSetting.split(',').map((task) => {
                return additionalTasks[task.trim()] = true;
            });
        }

        let discrepancies = [];

        assignmentGroupKeys.forEach(groupKey => {
            let matchingScheduleItem = scheduleGroups[groupKey];
            let matchingAdditionalTask = additionalTasks[groupKey];

            if (!matchingScheduleItem && !matchingAdditionalTask){
                _.each(assignmentGroups[groupKey], (assignment) => {
                    discrepancies.push(Object.assign({ reason: 'workitem-mismatch' }, assignment));
                });
            }

            if (matchingScheduleItem){
                _.each(assignmentGroups[groupKey], (assignment) => {
                    let activityFound = EM.activities.findByKey(assignment.ActivityName);
                    if (!activityFound){
                        discrepancies.push(Object.assign({ reason: 'activity-mismatch' }, assignment));
                    }
                });
            }
        });
        
        this.setState({ discrepancies });
    }

    closeDialog() {
        this.props.close(true);
    }

    highlightDiscrepancy(cell, row) {
        if (
            (row.reason === 'workitem-mismatch' && row.WorkItemName === cell) || 
            (row.reason === 'activity-mismatch' && row.ActivityName === cell)
        ) {
            return (<span className="highlight d-inline-block pl-1 pr-1">{cell||'<blank>'}</span>);
        } else {
            return cell;
        }
    }

    render() {
        if (!this.props.isShowing) return null;
        if (!this.props.defaultScheduleContent) return null;

        let data = null;
        let columns = null;
        const entity = EM.assignments;

        if (EM.allLoaded(entity, EM.employees) && this.state.discrepancies) {
            data = this.state.discrepancies; 
            let roleLookup = (cell, row) => {
                let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
                return EM.roles.lookupValue(roleId);                        
            }

            columns = [
                {
                    dataField: 'AssignmentId',
                    text: entity.columns('id'),
                    hidden: true,
                    editable: false
                },{                
                    dataField: 'role',
                    text: entity.columns('role'),
                    isReference: true,
                    editable: false,
                    width: 150,
                    formatter: roleLookup,
                    sortValue: roleLookup,
                    filterValue: roleLookup,
                    filterOptions: {
                        dynamicEntity: EM.roles
                    }
                },{ 
                    dataField: 'EmployeeId', 
                    text: entity.columns('employee'), 
                    fromEntity: EM.employees,        
                    width: 150
                },{ 
                    dataField: 'WorkItemName', 
                    text: entity.columns('task'),
                    asWorkItem: true,        
                    validators: [ Validators.required ],
                    width: 200,
                    formatter: this.highlightDiscrepancy,
                    filterOptions: {
                        text: true
                    }
                },{ 
                    dataField: 'ActivityName', 
                    text: entity.columns('activity'),
                    width: 200,
                    formatter: this.highlightDiscrepancy,
                    filterOptions: {
                        text: true
                    }
                },{ 
                    dataField: 'BeginDate', 
                    text: entity.columns('begin'), 
                    asDate: true,     
                    width: 90,                
                },{ 
                    dataField: 'EndDate', 
                    text: entity.columns('end'),                
                    asDate: true,                    
                    width: 90,   
                },{
                    dataField: 'Value',
                    text: entity.columns('value'),
                    width: 90,
                    asPercentage: true
                },{
                    dataField: 'Label',
                    text: entity.columns('label'),
                    width: 150
                },{ 
                    dataField: 'Status', 
                    text: entity.columns('status'),
                    asAssignmentStatus: true,
                    width: 100
                },{
                    dataField: 'CreatedOn',
                    text: EM.t('util.meta.created'),
                    isDateReference: 'tight',
                    asMeta: true,
                    width: 75
                },{
                    dataField: 'CreatedBy',
                    asUserIcon: true,
                    asMeta: true,
                    width: 30,
                    text: ''
                },{
                    dataField: 'ModifiedOn',
                    text: EM.t('util.meta.modified'),
                    isDateReference: 'tight',
                    asMeta: true,
                    width: 75
                },{
                    dataField: 'ModifiedBy',
                    asUserIcon: true,
                    asMeta: true,
                    width: 30,
                    text: ''
                }];
        }

        return (
            <div>
                <PanelModal 
                    isOpen={this.props.isShowing} 
                    className={'panel panel-full ' + this.props.className} 
                    key="assignments-dialog-modal" 
                >
                    <ModalHeader toggle={this.closeDialog}>
                        {EM.t('assignments.identifyOrphans')}
                        <i className="beta"></i>
                    </ModalHeader>
                    <ModalBody>
                        <EntityTable 
                            pageTitleClassName="hide-title-text page-title-bar-subtle"
                            suppressMeta={true} 
                            hideAddRecord={true} 
                            hideAudit={true}
                            hideFilters={true}
                            hideImport={true} 
                            hideClearAll={true} 
                            hideDelete={false} 
                            ref="discrepanciesTable" 
                            entity={entity} 
                            data={data} 
                            columns={columns} 
                            pageSize={1000}
                            className="table-overflow-container dialog-table"
                            onAfterDelete={() => {
                                this.props.reloadAssignments().then(() => {
                                    this.processDiscrepancies();
                                })
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="pull-left"
                            onClick={this.closeDialog}>{EM.t('util.closeButton')}
                        </Button>
                    </ModalFooter>
                </PanelModal>
            </div>
        );
    };
}
