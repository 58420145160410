/* global EM */
import Entity from './Entity';
import * as budgetActions from './actions/budgets';

export default class BudgetsEntity extends Entity{
    async calculate(projectionId, isSilent){
        let state = this.store.getState();
        if (!state.domain)return;       
        EM.markDomainClean(state.domain.DomainId);
        return await this.dispatch(budgetActions.calculateBudgets(state.domain.DomainId, this, projectionId, isSilent));
    }
}