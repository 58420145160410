/* global EM */
import React from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PanelModal from '../PanelModal';
import EntityTable from '../EntityTables/EntityTable';
import Dates from '../../util/Dates';
import CSV from '../../util/CSV';
import PipelineRunner from '../../entities/pipelines/PipelineRunner';
import _ from 'underscore';

export default class ReconcileDatesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discrepancies: null,
            unlockedOnly: true
        };

        this.closeDialog = this.closeDialog.bind(this);

        EM.roles.load();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isShowing !== this.props.isShowing) {
            return true;
        }
        if (nextProps.assignments !== this.props.assignments) {
            return true;
        }
        if (nextProps.schedule !== this.props.schedule) {
            return true;
        }
        if (nextState.discrepancies !== this.state.discrepancies) {
            return true;
        }
        if (nextState.unlockedOnly !== this.state.unlockedOnly) {
            console.log(nextState.unlockedOnly, this.state.unlockedOnly);
            return true;
        }
        return false;
    }

    componentDidMount() {
        this.processDiscrepancies();
    }

    processDiscrepancies() {
        if (!this.props.defaultScheduleContent) return [];
        let assignmentGroups = _.groupBy(this.props.assignments, (item) => {
            if (!item) return;
            return item.WorkItemName + '#' + item.ActivityName;
        });
        let indexes = this.props.defaultScheduleContent.indexes;
        let scheduleGroups = _.groupBy(this.props.defaultScheduleContent.data, (item) => {
            return item[indexes.workitem].trim() + '#' + item[indexes.activity].trim();
        });

        let employeeLookup = _.indexBy(this.props.employees, 'EmployeeId');

        let discrepancies = [];
        _.each(scheduleGroups, (group, groupKey) => {
            let matchingAssignments = assignmentGroups[groupKey];
            if (!matchingAssignments) return;

            let scheduleMasterRecord = group[0]; //There could be more than one match, so just selecting the first
            let masterBeginDate = Dates.fromISO(scheduleMasterRecord[indexes.begin]);
            let masterBeginDateStr = Dates.toMonthYearStrShort(masterBeginDate);
            let masterEndDate = Dates.fromISO(scheduleMasterRecord[indexes.end]);
            let masterEndDateStr = Dates.toMonthYearStrShort(masterEndDate);

            _.each(matchingAssignments, (assignment) => {
                let beginDate = Dates.toMonthYearStrShort(Dates.fromISO(assignment.BeginDate));
                let endDate = Dates.toMonthYearStrShort(Dates.fromISO(assignment.EndDate));
                let employee = employeeLookup[assignment.EmployeeId];
                if (!employee) return;
                let role = EM.roles.byId(employee.RoleId);

                if (beginDate !== masterBeginDateStr || endDate !== masterEndDateStr) {
                    if (this.state.unlockedOnly && assignment.Status === 'Locked') return;
                    discrepancies.push(Object.assign({}, assignment, {
                        NewBeginDate: masterBeginDate.toISO(),
                        BeginChanged: beginDate !== masterBeginDateStr,
                        NewEndDate: masterEndDate.toISO(),
                        EndChanged: endDate !== masterEndDateStr,
                        RoleId: role ? role.RoleId : '-1'
                    }));
                }
            });
        });

        this.setState({ discrepancies });
    }

    /*highlightBeginDiscrepancy(cell, row) {
        let value = Dates.toMonthYearStrShort(Dates.fromISO(cell));
        if (row.BeginChanged) {
            return (<span className="highlight d-inline-block pl-1 pr-1">{value}</span>);
        } else {
            return value;
        }
    }

    highlightEndDiscrepancy(cell, row) {
        let value = Dates.toMonthYearStrShort(Dates.fromISO(cell));
        if (row.EndChanged) {
            return (<span className="highlight d-inline-block pl-1 pr-1">{value}</span>);
        } else {
            return value;
        }
    }*/

    makeUpdates(targetButton) {
        let discrepancies = this.state.discrepancies;
        let selectedRowIds = _.pluck(this.refs.discrepanciesTable.selectedRows, "AssignmentId");
        let selectedAssignments = _.filter(discrepancies, (item) => {
            return selectedRowIds.indexOf(item.AssignmentId) > -1;
        });

        if (selectedAssignments.length > 0) {
            _.each(selectedAssignments, (item) => {
                item.BeginDate = Dates.fromISO(item.NewBeginDate).toFormat("LL/01/yyyy");
                item.EndDate = Dates.fromISO(item.NewEndDate).toFormat("LL/01/yyyy");
            })

            targetButton.disabled = true;
            let self = this;
            window.setTimeout(() => {
                EM.assignments.import(selectedAssignments).then(() => {
                    self.props.close(true);
                });
            }, 100);
        }
    }

    closeDialog() {
        this.props.close(false);
    }

    render() {
        if (!this.props.isShowing) return null;
        if (!this.props.defaultScheduleContent) return null;
        let data = null;
        let columns = null;
        const entity = EM.assignments;

        if (EM.allLoaded(entity, EM.employees) && this.state.discrepancies) {
            data = this.state.discrepancies;
            columns = [
                {
                    dataField: 'AssignmentId',
                    text: entity.columns('id'),
                    hidden: true,
                    editable: false
                },
                {
                    dataField: 'EmployeeId',
                    text: entity.columns('employee'),
                    width: 150,
                    editable: false,
                    fromEntity: EM.employees
                },
                {
                    dataField: 'RoleId',
                    text: entity.columns('role'),
                    width: 150,
                    editable: false,
                    fromEntity: EM.roles
                },
                {
                    dataField: 'WorkItemName',
                    text: entity.columns('task'),
                    width: 200,
                    editable: false,
                    filterOptions: {
                        text: true
                    }
                },
                {
                    dataField: 'ActivityName',
                    text: entity.columns('activity'),
                    width: 200,
                    editable: false,
                    filterOptions: {
                        text: true
                    }
                },
                {
                    dataField: 'BeginDate',
                    text: entity.columns('oldBegin'),
                    width: 90,
                    editable: false,
                    asDate: true
                },
                {
                    dataField: 'NewBeginDate',
                    text: entity.columns('newBegin'),
                    width: 90,
                    editable: false,
                    asDate: true,
                    highlightChangeAs: 'BeginChanged'
                },
                {
                    dataField: 'EndDate',
                    text: entity.columns('oldEnd'),
                    width: 90,
                    editable: false,
                    asDate: true
                },
                {
                    dataField: 'NewEndDate',
                    text: entity.columns('newEnd'),
                    width: 90,
                    formatter: this.highlightEndDiscrepancy,
                    editable: false,
                    asDate: true,
                    highlightChangeAs: 'EndChanged'
                },
                {
                    dataField: 'Value',
                    text: entity.columns('value'),
                    editable: false,
                    width: 90,
                    asPercentage: true,
                    filterOptions: {
                        number: true
                    }
                },
                {
                    dataField: 'Label',
                    text: entity.columns('label'),
                    editable: false,
                    width: 150,
                    filterOptions: {
                        text: true
                    }
                }, {
                    dataField: 'Status',
                    text: entity.columns('status'),
                    asAssignmentStatus: true,
                    editable: false,
                    width: 100
                }, {
                    dataField: 'CreatedOn',
                    text: EM.t('util.meta.created'),
                    isDateReference: 'tight',
                    asMeta: true,
                    width: 75
                }, {
                    dataField: 'CreatedBy',
                    asUserIcon: true,
                    asMeta: true,
                    width: 30,
                    text: ''
                }, {
                    dataField: 'ModifiedOn',
                    text: EM.t('util.meta.modified'),
                    isDateReference: 'tight',
                    asMeta: true,
                    width: 75
                }, {
                    dataField: 'ModifiedBy',
                    asUserIcon: true,
                    asMeta: true,
                    width: 30,
                    text: ''
                }];
        }

        return (
            <div>
                <PanelModal
                    isOpen={this.props.isShowing}
                    className={'panel panel-full ' + this.props.className}
                    key="assignments-dialog-modal"
                >
                    <ModalHeader toggle={this.closeDialog}>
                        {EM.t('assignments.reconcileSchedule')}
                        <small>{EM.t('assignments.discrepanciesDescription')} </small>
                    </ModalHeader>
                    <ModalBody>
                        <EntityTable
                            pageTitleClassName="hide-title-text page-title-bar-subtle"
                            suppressMeta={true}
                            hideAddRecord={true}
                            hideAudit={true}
                            hideFilters={false}
                            hideImport={true}
                            hideClearAll={true}
                            hideDelete={true}
                            ref="discrepanciesTable"
                            entity={entity}
                            data={data}
                            columns={columns}
                            pageSize={1000}
                            className="table-overflow-container dialog-table"
                            customExportFunc={async () => {
                                let dataToTransform = this.refs.discrepanciesTable?.selectedRows && this.refs.discrepanciesTable?.selectedRows.length > 0 ? this.refs.discrepanciesTable?.selectedRows : data;
                                let dataTransformed = dataToTransform.map(row => {
                                    return {
                                        ID: row.AssignmentId,
                                        Employee: EM.employees.lookupValue(row.EmployeeId),
                                        Task: row.WorkItemName,
                                        Activity: row.ActivityName,
                                        Begin: Dates.toDateShort(Dates.fromISO(row.NewBeginDate)),
                                        End: Dates.toDateShort(Dates.fromISO(row.NewEndDate)),
                                        Value: Math.round(row.Value * 100),
                                        Label: row.Label,
                                        Status: row.Status || 'Assigned'
                                    }
                                });
                                let dataTable = await PipelineRunner.dataToTable(dataTransformed);
                                CSV.save(entity.name, dataTable);
                            }}
                            customExportLabel={EM.t('assignments.exportForReimport')}
                        >
                            <UncontrolledDropdown className="ml-1" size="sm">
                                <DropdownToggle caret color="light">{this.state.unlockedOnly ? 'Unlocked Assignments' : 'All Assignments'}</DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={() => {
                                        this.setState({ unlockedOnly: true }, () => {
                                            this.processDiscrepancies();
                                        });
                                    }}>Unlocked Assignments</DropdownItem>
                                    <DropdownItem onClick={() => {
                                        this.setState({ unlockedOnly: false }, () => {
                                            this.processDiscrepancies();
                                        });
                                    }}>All Assignments</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </EntityTable>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="pull-left"
                            onClick={this.closeDialog}>{EM.t('assignments.cancel')}</Button>
                        {EM.isDomainResourceManager() ?
                            <Button
                                className="btn-danger pull-right"
                                onClick={(event) => {
                                    this.makeUpdates(event.target);
                                }}>
                                {EM.t('assignments.updateAssignments')}
                            </Button>
                            : null}
                    </ModalFooter>
                </PanelModal>
            </div>
        );
    };
}