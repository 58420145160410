/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';
import { Type } from 'react-bootstrap-table2-editor';


class AverageWorkHours extends Component {
    constructor(props){
        super(props);
        EM.averageWorkingHours.load();
        EM.countryCurrencies.load();
    }
    render(){
      let data = null;
      let columns = null;
      const entity = EM.averageWorkingHours;
      if(EM.allLoaded(entity,EM.countryCurrencies)){
        data = entity.get();
        let countryCurrencies = EM.countryCurrencies.get();
        let countryLookup = (cell,row)=>{
            return row.Country        
      }
      let monthyHoursLookup =((cell, row) => {
        return row.HoursAvailable / 12
      })
      let options = countryCurrencies.map((item) => {
        return {value:item.Country,label:item.Country}
      })
        columns = [
            {
                dataField: "Country",
                text:(entity.columns('Country')).split('.').pop(),
                formatter: countryLookup,
                filterValue: countryLookup,
                sortValue:countryLookup,
                editor : {
                  type: Type.SELECT,
                  options: options
                },
                validators: [Validators.required,Validators.countryExists],
                filterOptions: {
                  select: true,
                  options: options,
              },
            },
            {
                dataField: "HoursAvailable",
                text: "Hours Available",
                validators: [Validators.required,Validators.positiveNumber],
                filterOptions: {
                  text: true,
              },
            },
            {
              dataField: "MonthlyAvailableHours",
              text: "Monthly Available Hours",
              creatable: false,
              isReference: true,
              formatter: monthyHoursLookup,
              filterValue: monthyHoursLookup,
              sortValue: monthyHoursLookup,
              filterOptions: {
                text: true,
            },
          },
          {
              dataField: "Label",
              text: "Label",
              filterOptions: {
                text: true,
            },
          },
        ];
      }   

    return(
        <div key="contents" className="page container-fluid">
             <BudgetsTabs {...this.props}/>
             <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container tabs"
             hideDelete={!EM.isDomainFinanceManager()}
             hideAddRecord={!EM.isDomainFinanceManager()}
             />
        </div>
    )
  }
}

export default AverageWorkHours;