/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import logo from '../images/logo.svg';
import MetaInfo from './MetaInfo';

export default function LoginSelector(props){  
    let loginModes = props.instance.loginModes;
    let showEnterprise = loginModes.indexOf('Enterprise') > -1;
    let showLegacy = loginModes.indexOf('Legacy') > -1;
    let showOIDC = loginModes.indexOf('OIDC') > -1;
    let customProps = props.instance.customLoginProps || {};

    return (
        <div className="landing-page login-selector">
            <div>
                <div className="header">
                    <img src={logo} alt="logo" />
                </div>
                <div className="content clearfix">
                    <p className="mb-2" role="heading" aria-level="1">Welcome to Intrinsic Insights RM! Click the button below to log in.</p>      
                    <div className="float-right">
                        {showEnterprise ? 
                            <button onClick={() => {
                                props.onSelectLoginMode('Enterprise');
                            }} className="btn btn-success pl-4 pr-4">{showOIDC ? 'RM ':''}Log In</button>            
                        : null }                         
                        {showOIDC ? 
                            <button onClick={() => {
                                props.onSelectLoginMode('OIDC');
                            }} className="btn btn-info ml-2">{customProps.buttonText || 'Log In'}</button>    
                        : null}                     
                    </div>
                </div> 
                <div className="content clearfix">
                    {showOIDC ? 
                        <p className="text-muted mb-2">
                            If you need to login as an Intrinsic Clinical Systems employee, <a onClick={() => {
                                props.onSelectLoginMode('Enterprise');
                            }}>click here to proceed</a>.
                        </p>
                    : null }                
                    {showLegacy ? 
                        <p className="text-muted mb-2">
                            If your organization has not yet adopted Insights RM's Enterprise login model, please <a onClick={() => {
                                props.onSelectLoginMode('Legacy');
                            }}>click here to login</a>.
                        </p>
                    : null } 
                    <p className="text-muted mb-2">
                        If you're seeing this screen repeatedly, please verify that you have "Allowed Popups" for this website in your browser's settings.
                    </p>
                    <p className="text-muted mb-0">
                        {props.logout ? 
                            <span>
                                If you experience issues logging in, <a href="#" onClick={() => props.logout()}>please click here to log out</a> of existing sessions before proceeding.&nbsp;
                            </span>
                        : null }
                        If the issue persists, please <a href="https://intrinsiccs.atlassian.net/servicedesk/customer/portal/2">submit a support ticket.</a> 
                    </p> 
                </div>  
            </div>  
            <div className="footer">
                <MetaInfo />
            </div>         
        </div>
    );
}