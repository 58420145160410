/* global EM */
export default class ProjectionsManager{
    constructor(){
        this.dataSetCache = null;
    }

    getAvailableDataSets(){
        if (!this.dataSetCache){
            function remap(entity){
                let def = {
                    id: 'default',
                    value: 'default:' + entity.name,
                    group: entity.name,
                    label: 'Default ' + entity.modelNameSingular,
                    isDefault: true
                };
                return [def, ...entity.get().map(item => { 
                    let id = item[entity.idField];
                    let hasProj = item.Projections && item.Projections[0];
                    return {
                        id: id,
                        value: entity.name + id,
                        group: entity.name,
                        label: item.Name,
                        isDefault: item.IsDefault,
                        createdOn: hasProj ? item.Projections[0].CreatedOn : item.CreatedOn
                    };
                })];
            }

            let output = [];
            output.push({ label: EM.projections.t('title'), options: remap(EM.schedules) });
            output.push({ label: EM.headcounts.t('title'), options: remap(EM.headcounts) });
            output.push({ label: EM.actuals.t('title'), options: remap(EM.actuals) });
            this.dataSetCache = output;
        }

        return this.dataSetCache;
    }

    async loadDefaultProjections(){
        let projectionsOverride = EM.getSetting('ManualProjectionOverride');
        if (projectionsOverride){
            return await this.loadDataset({ group: 'actuals', id: projectionsOverride });
        }else{
            return await this.loadDataset({ group: 'schedules', id: 'default' });
        }
    } 

    async loadDataset(dataset){
        if (dataset.value === null){
            return null;
        }else{
            let entityClean = dataset.group.toLowerCase();
            if (!EM[entityClean])throw new Error('Entity not found: ' + entityClean);

            let item = null;
            if (dataset.id === 'default'){
                item = EM[entityClean].findDefaultItem();
            }else{
                item = EM[entityClean].byId(dataset.id);
            }
            if (!item)throw new Error('Item not found: ' + entityClean + ' - ' + dataset.id);

            let method = '_' + (entityClean === 'schedules' ? 'projectionsLoad' : entityClean + 'Load');

            return await this[method](item).catch(err => {
                console.warn(err);
            });
        }
    }

    async loadDatasetsFromPrefs(preferences, defaultScheduleId){
        let datasets = await this._getDatasetsFromPrefs(preferences, defaultScheduleId);
        return datasets;        
    }   

    async _getDatasetsFromPrefs(preferences, defaultScheduleId){
        let primary = null;
        let pData = preferences.primary.dataset;
        if (pData && pData.id !== null){
            primary = await this.loadDataset(pData).catch((ex) => {
                EM.warn('Failed to load primary dataset: ' + ex.message);
            });        
        }else{
            primary = await this.loadDataset({ group: 'schedules', id: defaultScheduleId }).catch((ex) => {
                EM.warn('Failed to load primary dataset: ' + ex.message);
            });
        }

        let secondary = null;
        let sData = preferences.secondary.dataset;        
        if (sData){
            secondary = await this.loadDataset(sData).catch((ex) => {
                EM.warn('Failed to load secondary dataset: ' + ex.message);                
            });
        }

        let tertiary = null;
        let tData = preferences.tertiary.dataset;
        if (tData){
            tertiary = await this.loadDataset(tData).catch((ex) => {
                EM.warn('Failed to load tertiary dataset: ' + ex.message);                
            });
        }        

        return { primary, secondary, tertiary };
    }

    async _projectionsLoad(schedule){ //Used to load projections
        let domain = EM.getActiveDomain();
        let domainIdToSearch = domain.ParentDomainId ? domain.ParentDomainId : domain.DomainId;
        if (EM.projections.isOverridden())domainIdToSearch = domain.DomainId;
        let newestProjections = schedule.Projections.find((projection) => {
            return projection.Status.toLowerCase().indexOf('success') === 0 && projection.DomainId === domainIdToSearch;
        });

        if (!newestProjections){
            throw new Error('No projections found.');
        }

        await EM.projections.loadFile(newestProjections.ProjectionId, false);
        
        let projections = EM.projections.getFile(newestProjections.ProjectionId);            
        if (projections){
            projections.name = schedule.Name;
        }
        return projections;
    } 
    
    async _headcountsLoad(headcount){
        await EM.headcounts.loadFile(headcount.HeadcountId, false);        
        let headcountData = EM.headcounts.getFile(headcount.HeadcountId);        
        if (headcountData){
            headcountData.name = headcount.Name;
        }
        return headcountData;
    }

    async _actualsLoad(actual){
        await EM.actuals.loadFile(actual.ActualId, false);        
        let actualData = EM.actuals.getFile(actual.ActualId);        
        if (actualData){
            actualData.name = actual.Name;        
        }
        return actualData;
    }    
}