import React, { Component, Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import Routes from '../app/Routes';
import Schedules from './Schedules';
import Activities from './Activities';
import Complexities from './Complexities';
import Allocations from './Allocations';
import Organizations from './Organizations';
import Departments from './Departments';
import Roles from './Roles';
import Algorithms from './Algorithms';
import Employees from './Employees';
import Attributes from './Attributes';
import Headcounts from './Headcounts';
import Actuals from './Actuals';
import Assignments from './Assignments';
import GeneralSettings from './GeneralSettings';
import Security from './Security';
import AdvancedSettings from './AdvancedSettings';
import Spinner from '../components/Spinner';
import CountryCurrencyMap from './CountryCurrencyMap';
import RateCard from './RateCard';
import CountryConversions from './CurrencyConversions'
import InflationFactor from './InflationFactor';
import AverageWorkHours from './AverageWorkHours';
const ReferenceTables = React.lazy(() => import('./ReferenceTables'));
const Pipelines = React.lazy(() => import('./Pipelines'));

class Management extends Component {
    render() {
        return (    
            <Suspense fallback={<Spinner text="Loading..." classes="m-4" />}>
                <Switch>
                    <Route exact path={Routes.client.schedules} render={() => <Schedules {...this.props} />} />
                    <Route exact path={Routes.client.headcounts} render={() => <Headcounts {...this.props} />} />
                    <Route exact path={Routes.client.actuals} render={() => <Actuals {...this.props} />} />
                    <Route exact path={Routes.client.activities} render={() => <Activities {...this.props} />} />
                    <Route exact path={Routes.client.allocations} render={() => <Allocations {...this.props} />} />
                    <Route exact path={Routes.client.complexities} render={() => <Complexities {...this.props} />} />
                    <Route exact path={Routes.client.organizations} render={() => <Organizations {...this.props} />} />
                    <Route exact path={Routes.client.departments} render={() => <Departments {...this.props} />} />
                    <Route exact path={Routes.client.roles} render={() => <Roles {...this.props} />} />
                    <Route exact path={Routes.client.attributes} render={() => <Attributes {...this.props} />} />
                    <Route exact path={Routes.client.algorithms} render={() => <Algorithms {...this.props} />} />
                    <Route exact path={Routes.client.employees} render={() => <Employees {...this.props} />} />
                    <Route exact path={Routes.client.assignments} render={() => <Assignments {...this.props} />} />
                    <Route exact path={Routes.client.generalSettings} render={() => <GeneralSettings {...this.props} />} />
                    <Route exact path={Routes.client.advancedSettings} render={() => <AdvancedSettings {...this.props} />} />
                    <Route exact path={Routes.client.security} render={() => <Security {...this.props} />} />
                    <Route exact path={Routes.client.referenceTables} render={() => <ReferenceTables {...this.props} />} />                    
                    <Route exact path={Routes.client.pipelines} render={() => <Pipelines {...this.props} />} />
                    <Route exact path={Routes.client.pipelineViewer} render={() => <Pipelines {...this.props} />} />
                    <Route exact path={Routes.client.budgets} render={() =>  <CountryCurrencyMap {...this.props} />} />
                    <Route exact path={Routes.client.countryCurrencyMap} render={() => <CountryCurrencyMap {...this.props} />} />
                    <Route exact path={Routes.client.currencyConversions} render={() => <CountryConversions {...this.props} />} />
                    <Route exact path={Routes.client.rateCard} render={() => <RateCard {...this.props} />} />
                    <Route exact path={Routes.client.inflationFactor} render={() => <InflationFactor {...this.props} />} />
                    <Route exact path={Routes.client.averageWorkHours} render={() => <AverageWorkHours {...this.props} />} />
                </Switch>  
            </Suspense>        
        );
    }
}

export default Management;
