import React from 'react';
import { Label, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';

export default function OptionField(props){        
    let options = props.options.map((opt) => { 
        if (typeof opt === 'object'){
            return opt;
        }else{
            return { label: opt, value: opt };
        }
    });
    let selectedOption = options.find(opt => opt.value === props.value);

    return (
        <FormGroup>      
            <Label title={props.description}>{props.display || props.name}:</Label>
            <Select
                className="single-select"
                classNamePrefix="single-select"
                isSearchable={true}
                options={options}
                value={selectedOption}
                onChange={(selectedItem) => {
                    props.onChange(props.name, selectedItem.value)
                }}
            />
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null } 
        </FormGroup>
    );
}