import React, { Component, Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import Routes from '../app/Routes';
import StaffingEmployee from './StaffingEmployee';
import StaffingWorkItem from './StaffingWorkItem';
import Demand from './Demand';
import BudgetCharts from './BudgetCharts';
import Spinner from '../components/Spinner';

const Scenarios = React.lazy(() => import('./Scenarios'));
const DataExplorer = React.lazy(() => import('./DataExplorer'));

class Visualizations extends Component {
    render() {
        return (    
            <Suspense fallback={<Spinner text="Loading..." classes="m-4" />}>
                <Switch>
                    <Route exact path={Routes.client.staffingEmployee} render={() => <StaffingEmployee {...this.props} />} />
                    <Route exact path={Routes.client.staffingWorkItem} render={() => <StaffingWorkItem {...this.props} />} />
                    <Route exact path={Routes.client.scenarios} render={() => <Scenarios {...this.props} />} />
                    <Route exact path={Routes.client.scenarioViewer} render={() => <Scenarios {...this.props} />} />
                    <Route exact path={Routes.client.demand} render={() => <Demand {...this.props} />} />
                    <Route exact path={Routes.client.budgetCharts} render={() => <BudgetCharts {...this.props} />} />
                    <Route exact path={Routes.client.dataExplorer} render={() => <DataExplorer {...this.props} />} />
                </Switch>  
            </Suspense>        
        );
    }
}

export default Visualizations;
