/* global EM */
/* eslint no-useless-constructor: 0 */
import BudgetsFileBase from './BudgetsFileBase';
import Dates from '../../util/Dates';
import DateGroupValueSet from './DateGroupValueSet';
import _ from 'underscore';
import xml2js from 'xml2js';

function convertToArray(objects) {
    if (!Array.isArray(objects) || objects.length === 0) return [];
    
   
    const keys = Object.keys(objects[0]);
    
    // Create headers, removing underscores
    const headers = keys.map(key => key.startsWith('_') ? key.slice(1) : key);

    // Create values based on the headers
    const values = objects.map(obj => headers.map(header => {
    
        const originalKey = `_${header}`; 
        return obj.hasOwnProperty(header) ? obj[header] : 
               obj.hasOwnProperty(originalKey) ? obj[originalKey] : null;
    }));

    return [headers, ...values];
}

export default class BudgetsFile extends BudgetsFileBase {
    constructor(data, entityName, id) {
        super(data, entityName, id);
        this.ts = (new Date()).getTime();
        this.dataCache = {};
        this.attritionFactorCache = {};
        this.series = null;
        this.seriesTemplate = null;
        this.defaultGrouping = 'Role';
        this.defaultDateGrouping = 'Month';

        this.series = null;
        this.filterIndexes = null;
        this.processedData = null;
        this.processedDataModal =null;
        this.defaultDateRange = null;
        this.data = convertToArray(data);
        this.indexes = BudgetsFile.getIndexes(this.data);

        this.cellFormatter = this.cellFormatter.bind(this);
    }
    static get allowsXml() { return true; }

    static async validate(csvContents) {
        await EM.activities.load();

        let indexes = BudgetsFile.getIndexes(csvContents);

        let errors = [];

        let limitSetting = parseInt(EM.getTenantOption('scheduleLimit'));
        let importLimit = isNaN(limitSetting) ? 35000 : limitSetting;
        if (csvContents.length > (importLimit + 1)) {
            errors.push([(0 + ':' + 0), 'File contains too many rows. Maximum rows is ' + importLimit + '.']);
            return errors;
        }

        let header = csvContents[0];
        if (header.length > 256) {
            errors.push([(0 + ':' + 0), 'File contains too many columns. Maximum columns is 256.']);
            return errors;
        }

        let badCols = ['Role', 'Value', 'MID', 'CID', 'MM'];
        let badCol = header.find(col => {
            return badCols.find(bc => bc === col);
        });
        if (badCol) {
            errors.push([(0 + ':' + 0), 'File appears to be a projections file.']);
            return errors;
        }

        csvContents.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;

            if (!row[indexes.workitem]) {
                errors.push([(rowIndex + ':' + indexes.workitem), 'Work Item is required.']);
            }

            //Check that activity is in the list
            let actFound = EM.activities.lookupId(row[indexes.activity], 'Name');
            if (!actFound) {
                errors.push([(rowIndex + ':' + indexes.activity), 'Activity not found.']);
            }

            //Check begin/end for valid format
            if (!row[indexes.begin] || !Dates.isValidDateStr(row[indexes.begin])) {
                errors.push([(rowIndex + ':' + indexes.begin), 'Invalid date format. Must be in format m/d/yyyy.']);
            }
            if (!row[indexes.end] || !Dates.isValidDateStr(row[indexes.end])) {
                errors.push([(rowIndex + ':' + indexes.end), 'Invalid date format. Must be in format m/d/yyyy.']);
            }
        });

        return errors;
    }

    static preSaveTransform(csvContents) {
        let indexes = BudgetsFile.getIndexes(csvContents);
        csvContents.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;
            row[indexes.begin] = Dates.fromStr(row[indexes.begin]).toISO();
            row[indexes.end] = Dates.fromStr(row[indexes.end]).toISO();
        });

        return csvContents;
    }

    static preExportTransform(csvContents) {
        let indexes = BudgetsFile.getIndexes(csvContents);
        let output = csvContents.map((row, rowIndex) => {
            let copy = row.slice(0);
            if (rowIndex === 0) return copy
            copy[indexes.begin] = Dates.fromISO(row[indexes.begin]).toLocaleString();
            copy[indexes.end] = Dates.fromISO(row[indexes.end]).toLocaleString();
            return copy;
        });

        return output;
    }

    toObjectArray(processingCallback) {
        let results = [];
        this.data.forEach((row, index) => {
            if (index === 0) return;
            var act = EM.activities.findByKey(row[this.indexes.activity]);
            if (!act) return;

            let tmp = {
                Name: row[this.indexes.workitem],
                Activity: act.Name,
                ActivityId: act.ActivityId,
                Begin: row[this.indexes.begin],
                End: row[this.indexes.end]
            };

            Object.keys(this.indexes.attributes).forEach(attrKey => {
                tmp['_' + attrKey] = row[this.indexes.attributes[attrKey]];
            });

            if (processingCallback) {
                tmp = processingCallback(tmp, index);
            }

            if (tmp) results.push(tmp);
        });

        return results;
    }

    static getIndexes(csvContents) {
        let indexes = {
            workitem: -1,
            activity: -1,
            begin: -1,
            end: -1
        };

        let attributes = [];

        //Try to infer indexes from header titles first
        let header = csvContents[0];
        header.forEach((columnTitle, ci) => {
            if (!columnTitle) return;
            if (!columnTitle) return;
            let titleClean = columnTitle.toLowerCase().replace(/\s/g, '');
            if (titleClean === 'workitem') {
                indexes.workitem = ci;
            } else if (titleClean === 'activity') {
                indexes.activity = ci;
            } else if (titleClean === 'begin') {
                indexes.begin = ci;
            } else if (titleClean === 'end') {
                indexes.end = ci;
            } else {
                attributes.push([columnTitle, ci]);
            }
        });

        let defaultIndexes = {
            workitem: 0,
            activity: 1,
            begin: 2,
            end: 3
        }

        Object.keys(indexes).forEach((key) => {
            if (key === 'attributes') return;
            if (indexes[key] === -1) indexes[key] = defaultIndexes[key];
        });

        //Get default from 
        let settings = EM.settings.asKeyed();
        indexes.workitem = parseInt(settings.WorkItemIndex ? settings.WorkItemIndex.Value : indexes.workitem);
        indexes.activity = parseInt(settings.ActivityIndex ? settings.ActivityIndex.Value : indexes.activity);
        indexes.begin = parseInt(settings.BeginIndex ? settings.BeginIndex.Value : indexes.begin);
        indexes.end = parseInt(settings.EndIndex ? settings.EndIndex.Value : indexes.end);

        //Finally, make sure that any attribute values read in are not also "known" columns
        let knownCols = Object.values(indexes);
        attributes = attributes.filter(attr => {
            return knownCols.indexOf(attr[1]) === -1;
        });

        indexes.attributes = _.object(attributes);
        return indexes;
    }

    getFilterValues(trimForDisplay) {
        let filters = [];
        let indexes = this.indexes;
        let hiddenColumns = [];
        let pinnedColumns = [];
        if (trimForDisplay) {
            let hiddenColumnSetting = EM.getSetting('HiddenColumns');
            if (hiddenColumnSetting) {
                hiddenColumnSetting.split(',').map((col) => {
                    return hiddenColumns.push(col.trim());
                })
            }
            let pinnedColumnsSetting = EM.getSetting('PinnedColumns');
            if (pinnedColumnsSetting) {
                pinnedColumns = pinnedColumnsSetting.split(',')
                    .map(col => col)
                    .filter(col => col.toLowerCase().replace(/[^a-zA-Z]/g, '') !== "workitem" && col.toLowerCase().replace(/[^a-zA-Z]/g, '') !== "activity");
            }

        }
        let transposed = _.unzip(this.data);
        transposed.forEach((columnSet, columnIndex) => {
            if (!columnSet[0]) return;
            if (columnIndex === indexes.begin || columnIndex === indexes.end) return;

            if (trimForDisplay) {
                if (hiddenColumns.indexOf(columnSet[0]) > -1) return;
            }

            let filterMeta = {
                name: columnSet[0],
                isCore: false,
                label: columnSet[0],
                values: _.uniq(columnSet.slice(1).sort(), true)
            };
            if (columnSet[0].toLowerCase().replace(/[^a-zA-Z]/g, '') === "workitem") {
                filterMeta.name = 'workitem';
                filterMeta.isCore = true;
            } else if (columnSet[0].toLowerCase().replace(/[^a-zA-Z]/g, '') === "activity") {
                filterMeta.name = 'activity';
                filterMeta.isCore = true;
            }
            filters.push(filterMeta);
        });
        filters = _.sortBy(filters, (filter) => {
            return (filter.isCore ? 'a' : 'z') + (filter.name === 'workitem' ? 'a' : 'z') + filter.name;
        });
        let pinnedObjects = filters.filter(item => pinnedColumns.includes(item.label));
        let workItemObject = filters.find(item => item.name === "workitem");
        let activityObject = filters.find(item => item.name === "activity");
        if (activityObject) {
            activityObject.values = activityObject.values.map(value => {
                let trimmedValue = value.trim();
                return /^\d+$/.test(trimmedValue) ? EM.activities.lookupValue(trimmedValue) : trimmedValue;
            });
        }

        let otherItems = filters.filter((item) => {
            if (item.name.startsWith("_")) {
                item.name = item.name.substring(1)
                item.label = item.label.substring(1)
            }

            return !pinnedColumns.includes(item.name) &&
                item.name !== "workitem" &&
                item.name !== "activity" && item.name != "Role"
                && item.name != "BaseBudget"
                && item.name != "BaseHourlyRate"
                && item.name != "BaseMonthlyRunRate"
                && item.name != "BudgetValue"
                && item.name != "ProjectionValue"
                && item.name != "Complexity"
                && item.name != "IsLocalRate"
                && item.name != "LocalHourlyRate"
                && item.name != "CID"
                && item.name != "InflationFactor"
                && item.name != "FteHours"
                && item.name != "Year"
                && item.name != "Months"
                && item.name != "MM"
                && item.name != "MID"
                && item.name != "warnings"
                && item.name != "errors"
                && item.name != "Country"
        }
        );
        let pinnedColumnsInOrder = pinnedColumns.map((value) => pinnedObjects.find(item => item.name === value)) //sets in the order the pinned columns are selected.
        let combinedArray = [
            workItemObject,
            activityObject,
            ...pinnedColumnsInOrder,
            ...otherItems
        ].filter(Boolean);
        return combinedArray;
    }

    cellFormatter(row, col, value) {
        if (row > 0 && (col === this.indexes.begin || col === this.indexes.end)) {
            return Dates.fromISO(value).toLocaleString();
        }

        return value;
    }

    static async readXml(xml) {
        var parser = new xml2js.Parser({ explicitArray: false, mergeAttrs: true });
        let results = await parser.parseStringPromise(xml);
        if (!results || !results.Project || !results.Project.Tasks) return null;

        let tasks = [];
        Object.keys(results.Project.Tasks).forEach(taskKey => {
            Array.prototype.push.apply(tasks, results.Project.Tasks[taskKey]);
        });

        let attributes = [];
        Object.keys(results.Project.ExtendedAttributes).forEach(exAttr => {
            Array.prototype.push.apply(attributes, results.Project.ExtendedAttributes[exAttr]);
        });

        let header = ['Work Item', 'Activity', 'Begin', 'End'];
        let additionalColumns = [];
        let knownColumns = {};
        attributes.forEach(attr => {
            if (!attr.Alias) return;
            if (attr.Alias === 'Work Item Name' || attr.Alias === 'Activity Name') {
                knownColumns[attr.Alias] = attr.FieldID;
                return;
            }
            header.push(attr.Alias);
            additionalColumns.push(attr);
        });

        let wiNameFldId = knownColumns['Work Item Name'];
        let actNameFldId = knownColumns['Activity Name']

        let wis = {};
        let output = [header];
        tasks.forEach(task => {
            if (task.OutlineLevel === 0) return;
            if (task.OutlineLevel === '1') {
                wis[task.OutlineNumber] = task;
                return;
            }
            let outline = task.OutlineNumber.split('.');
            if (outline.length < 2) return;

            let wi = wis[outline[0]];
            if (!wi) return;

            let extAttrsGroups = task.ExtendedAttribute;
            if (extAttrsGroups && !_.isArray(extAttrsGroups)) extAttrsGroups = [extAttrsGroups];
            let taskExtendedAttrs = extAttrsGroups ? _.indexBy(extAttrsGroups, 'FieldID') : {};

            let wiName = (wiNameFldId && taskExtendedAttrs[wiNameFldId] ? taskExtendedAttrs[wiNameFldId].Value : wi.Name) || wi.Name;
            let actName = (actNameFldId && taskExtendedAttrs[actNameFldId] ? taskExtendedAttrs[actNameFldId].Value : task.Name) || task.Name;

            let start = task.Start || task.ManualStart;
            let startObj = Dates.fromISO(start);

            let end = task.Finish || task.ManualFinish;
            let endObj = Dates.fromISO(end);

            if (startObj.isInvalid || endObj.isInvalid) return;

            let row = [
                wiName,
                actName,
                startObj.toLocaleString(),
                endObj.toLocaleString()
            ];

            additionalColumns.forEach(ext => {
                let id = ext.FieldID;
                if (taskExtendedAttrs[id]) {
                    row.push(taskExtendedAttrs[id].Value);
                } else {
                    row.push(null);
                }
            });

            output.push(row);
        });

        return output;
    }
    preprocessData() {
        this.filterIndexes = this.setFilterIndexes();
        this.filterIndexKeys = Object.keys(this.filterIndexes);
        let seriesTmp = [];
        let rolesNotFound = [];

        this.processedData = this.data.mapFiltered((rowIn, rowIndex) => {
            let row = [...rowIn];
            if (rowIndex === 0) {
                row.push('Role');
                row.push('Department');
                row.push('Organization');
                return row;
            } else {
                let role = EM.roles.byId(row[this.filterIndexes.roleId]);
                let dept = EM.departments.byId(role ? role.DepartmentId : null);
                let org = EM.organizations.lookupValue(dept ? dept.OrganizationId : null);
                if (role && dept && org) {
                    row.push(role ? role.Name : '');
                    row.push(dept ? dept.Name : '');
                    row.push(org || '');
                } else {
                    rolesNotFound.push(row[this.filterIndexes.roleId]);
                    return null;
                }
                row[this.filterIndexes.begin] = typeof row[this.filterIndexes.begin] === 'object' ? Dates.fromMs(row[this.filterIndexes.begin].ts) : Dates.fromISO(row[this.filterIndexes.begin]).startOf('month');
                row[this.filterIndexes.end] = typeof row[this.filterIndexes.end] === 'object' ? Dates.fromMs(row[this.filterIndexes.end].ts) : Dates.fromISO(row[this.filterIndexes.end]).endOf('month');
                let value = parseFloat(row[this.filterIndexes.value]);
                if (isNaN(value)) return null;
                row[this.filterIndexes.value] = value;

                seriesTmp.push(this.getGroupKey('Role', row[this.filterIndexes.role]));
                seriesTmp.push(this.getGroupKey('Department', row[this.filterIndexes.department]));
                seriesTmp.push(this.getGroupKey('Organization', row[this.filterIndexes.organization]));
                return row;
            }
        });
        

        this.series = _.uniq(seriesTmp).sort();

        if (rolesNotFound.length > 0) {
            console.log('Projection roles not found:', rolesNotFound);
        }
    }

    preprocessDataBudget() {
        this.filterIndexes = this.setFilterIndexesBudget();
        this.filterIndexKeys = Object.keys(this.filterIndexes);
        let seriesTmp = [];
        let rolesNotFound = [];

        this.processedData = this.data.map((rowIn, rowIndex) => {
            let row = [...rowIn];
            if (rowIndex === 0) {
                row.push('Role');
                row.push('Department');
                row.push('Organization');
                return row;
            } else {
                let role = EM.roles.byId(row[this.filterIndexes.roleId]);
                let dept = EM.departments.byId(role ? role.DepartmentId : null);
                let org = EM.organizations.lookupValue(dept ? dept.OrganizationId : null);
                if (role) {
                    row.push(role ? role.Name : '');
                    row.push(dept ? dept.Name : '');
                    row.push(org || '');
                } else {
                    rolesNotFound.push(row[this.filterIndexes.roleId]);
                    return null;
                }
                row[this.filterIndexes.begin] = typeof row[this.filterIndexes.begin] === 'object' ? Dates.fromMs(row[this.filterIndexes.begin].ts) : Dates.fromISO(row[this.filterIndexes.begin]);
                row[this.filterIndexes.end] = typeof row[this.filterIndexes.end] === 'object' ? Dates.fromMs(row[this.filterIndexes.end].ts) : Dates.fromISO(row[this.filterIndexes.end]);
                let value = parseFloat(row[this.filterIndexes.Months] <= 1 ? row[this.filterIndexes.BaseBudget] : row[this.filterIndexes.BaseMonthlyRunRate]); 
                if (isNaN(value)) return null;
                row[this.filterIndexes.BaseBudget] = row[this.filterIndexes.BaseBudget];

                seriesTmp.push(this.getGroupKey('Role', row[this.filterIndexes.role]));
                seriesTmp.push(this.getGroupKey('Department', row[this.filterIndexes.department]));
                seriesTmp.push(this.getGroupKey('Organization', row[this.filterIndexes.organization]));
                return row;
            }
        });

        this.processedDataModal = this.data.map((rowIn, rowIndex) => {
            let row = [...rowIn];
            if (rowIndex === 0) {
                row.push('Role');
                row.push('Department');
                row.push('Organization');
                return row;
            } else {
                let role = EM.roles.byId(row[this.filterIndexes.roleId]);
                let dept = EM.departments.byId(role ? role.DepartmentId : null);
                let org = EM.organizations.lookupValue(dept ? dept.OrganizationId : null);
                if (role) {
                    row.push(role ? role.Name : '');
                    row.push(dept ? dept.Name : '');
                    row.push(org || '');
                } else {
                    rolesNotFound.push(row[this.filterIndexes.roleId]);
                    return null;
                }
                row[this.filterIndexes.begin] = typeof row[this.filterIndexes.begin] === 'object' ? Dates.fromMs(row[this.filterIndexes.begin].ts) : Dates.fromISO(row[this.filterIndexes.begin]);
                row[this.filterIndexes.end] = typeof row[this.filterIndexes.end] === 'object' ? Dates.fromMs(row[this.filterIndexes.end].ts) : Dates.fromISO(row[this.filterIndexes.end]);
                // let value = parseFloat(row[this.filterIndexes.Months] <= 1 ? row[this.filterIndexes.BaseBudget] : row[this.filterIndexes.BaseMonthlyRunRate]); 
                // if (isNaN(value)) return null;
                row[this.filterIndexes.BaseBudget] = row[this.filterIndexes.BaseBudget];

                seriesTmp.push(this.getGroupKey('Role', row[this.filterIndexes.role]));
                seriesTmp.push(this.getGroupKey('Department', row[this.filterIndexes.department]));
                seriesTmp.push(this.getGroupKey('Organization', row[this.filterIndexes.organization]));
                return row;
            }
        });

        this.series = _.uniq(seriesTmp).sort();

        if (rolesNotFound.length > 0) {
            console.log('Projection roles not found:', rolesNotFound);
        }
    }

    processDateRanges() {
        if (!this.processedData) this.preprocessData();

        this.processedData.slice(1).forEach((row) => {
            let range = Dates.getArrayOfMonths(row[this.filterIndexes.begin], row[this.filterIndexes.end], true);
            let dateKeys = [];
            range.dates.forEach((month, monthIndex) => {
                dateKeys.push(range.beginIndex + monthIndex);
            });
            row.push(dateKeys);
            row.push(range);
        });
    }

    setFilterIndexes() {
        if (this.data.length === 0) return {};
        let header = this.data[0];

        let output = {
            workitem: 0,
            activity: 1,
            begin: 2,
            end: 3,
            roleId: 4,
            value: 6,
            role: header.length,
            department: header.length + 1,
            organization: header.length + 2,
            dateKeys: header.length + 3,
            range: header.length + 4
        };

        if (header.length > 10) {
            for (let i = 10; i < header.length; i++) {
                let hName = header[i];
                if (hName.indexOf('_') === 0) hName = hName.slice(1);
                output[hName] = i;
            }
        }

        return output;

    }
    setFilterIndexesBudget() {
        if (this.data.length === 0) return {};
        let header = this.data[0];

        let output = {
            workitem: 0,
            activity: 1,
            begin: 2,
            end: 3,
            roleId: 4,
            value: 6,
            role: header.length,
            department: header.length + 1,
            organization: header.length + 2,
            dateKeys: header.length + 3,
            range: header.length + 4
        };

        if (header.length > 10) {
            for (let i = 0; i < header.length; i++) {
                let hName = header[i];
                if (output[hName] === undefined) {
                    if (hName.indexOf('_') === 0) hName = hName.slice(1);
                    output[hName] = i;
                }

            }
        }
        return output;

    }


    getMaxDateRange() {
        if (!this.processedData) this.preprocessData();

        let min = new Date().getTime();
        let max = -2174756400000;

        this.processedData.forEach((row, rowIndex) => {
            if (!row || rowIndex === 0) return;

            let begin = row[this.filterIndexes.begin] ?? null;
            let end = row[this.filterIndexes.end] ?? null;

            if (begin !== null && end !== null) {
                if (begin < min) min = begin;
                if (end < min) min = end;
                if (begin > max) max = begin;
                if (end > max) max = end;
            }
        });

        if (min < Dates.visualizableBegin) min = Dates.visualizableBegin;
        if (max > Dates.visualizableEnd) max = Dates.visualizableEnd;

        return [min, max];
    }

    getDateRange(preferences) {
        if (preferences.begin) {
            return Dates.getMonthRangeFromMonthYearStrs(preferences.begin, preferences.end);
        } else if (this.defaultDateRange) {
            let range = Dates.getArrayOfMonths(this.defaultDateRange[0], this.defaultDateRange[1], true).dates;
            return [range[0], range[range.length - 1]];
        } else {
            return Dates.getMonthRangeFromMonthYearStrs(null, null);
        }
    }

    async getSummaryByDate(preferences, reserve, attrition, pos, ignoreCache) {
        let self = this;
        if (!this.processedData) this.preprocessData();
        let sKey = this.getCacheKey(preferences, reserve, attrition, pos);
        let fromCache = await this.getFromSummaryCache(sKey);

        if (fromCache && !ignoreCache) {
            return fromCache;
        } else {
            let monthGroups = {};
            let grouping = preferences.grouping || this.defaultGrouping;
            let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
            let dateGrouping = preferences.dateGrouping || this.defaultDateGrouping;
            let dateRange = this.getDateRange(preferences);
            let pTotal = 0;

            if (!this.seriesTemplate) {
                this.seriesTemplate = { total: null };
                this.series.forEach((series) => {
                    this.seriesTemplate[series] = null;
                });
            }

            //Projection column order...
            // 0           1           2        3      4       5             6        7      8      9       10...,      
            //'WorkItem', 'Activity', 'Begin', 'End', 'Role', 'Complexity', 'Value', 'CID', 'MID', 'MM', ...Attrs       
            //Specifically using var with for-loops here, for perf. -JS
            for (var j = 1, rowLen = this.processedData.length; j < rowLen; j++) {
                let row = this.processedData[j];
                if (!this.isRowIncluded(row, preferences)) continue;
                let months = Dates.getArrayOfMonths(row[this.filterIndexes.begin], row[this.filterIndexes.end], true).dates;
                let groupKey = self.getGroupKey(grouping, row[groupingColumn]);

                let value = row[this.filterIndexes.value];
                if (reserve) value = value * (1 + reserve);
                if (pos) value = value * this.getPoS(row);
                let oValue = value;

                for (var i = 0, len = months.length; i < len; i++) {
                    let month = months[i];
                    if (month < dateRange[0] || month > dateRange[1]) continue;

                    let dgInfo = self.getDateGroup(dateGrouping, month);
                    let monthKey = dgInfo.key;

                    if (attrition) value = oValue * this.getSimpleAttritionFactor(month, attrition);

                    if (!monthGroups[monthKey]) {
                        monthGroups[monthKey] = Object.assign({}, this.seriesTemplate);
                    }
                    monthGroups[monthKey]['date'] = dgInfo.begin;

                    if (!monthGroups[monthKey][groupKey]) {
                        monthGroups[monthKey][groupKey] = new DateGroupValueSet(dateGrouping);
                    }
                    monthGroups[monthKey][groupKey].push(month, value);

                    if (!monthGroups[monthKey]['total']) {
                        monthGroups[monthKey]['total'] = new DateGroupValueSet(dateGrouping);
                    }
                    monthGroups[monthKey]['total'].push(month, value);
                    pTotal++;
                }
            }

            window.setTimeout(() => {
                this.saveToSummaryCache(sKey, monthGroups);
                EM.log('Processed projections:', pTotal);
            }, 0);

            return monthGroups;
        }
    }

    async getSummaryByDateBudget(preferences, reserve, attrition, pos, ignoreCache, data) {
        if (!this.processedData) this.preprocessDataBudget();

        let sKey = this.getCacheKey(preferences, reserve, attrition, pos);
        let fromCache = await this.getFromSummaryCache(sKey);
        if (fromCache && !ignoreCache) return fromCache;

        let monthGroups = {};
        let dateRange = this.getDateRange(preferences);
        let dateRangeStart = dateRange[0].ts;
        let dateRangeEnd = dateRange[1].ts;
        let self = this ;
        let grouping = preferences.grouping || this.defaultGrouping;
        let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
        let dateGrouping = preferences.dateGrouping || this.defaultDateGrouping;
        let pTotal = 0;
        if (!this.seriesTemplate) {
            this.seriesTemplate = { total: null };
            this.series.forEach(series => {
                this.seriesTemplate[series] = null;
            });
        }
         for (var j = 1, rowLen = this.processedData.length; j < rowLen; j++) {
            let row = this.processedData[j];
            if (row) {
                if (!this.isRowIncluded(row, preferences)) continue;
                // let months = Dates.getArrayOfMonths(row[this.filterIndexes.begin], row[this.filterIndexes.end], true).dates;
                // for (var i = 0, len = months.length; i < len; i++) {
                    // let monthes = months[i];
                    // if (monthes < dateRange[0] || monthes > dateRange[1]) continue;
                    let groupKey = self.getGroupKey(grouping, row[groupingColumn]);
                    // let value = row[this.filterIndexes.Months] <= 1 ? row[this.filterIndexes.BaseBudget] : row[this.filterIndexes.BaseMonthlyRunRate];
                    let value = row[this.filterIndexes.BaseBudget]?row[this.filterIndexes.BaseBudget]:0;
                    let oValue = value;

                    let month = row[this.filterIndexes.workitem];
                    let dgInfo = self.getDateGroupBudget(dateGrouping, month);
                    let monthKey = dgInfo.key;

                    if (attrition) value = oValue;
                    if (!monthGroups[monthKey]) {
                        monthGroups[monthKey] = Object.assign({}, this.seriesTemplate);
                    }
                    monthGroups[monthKey]['date'] = { ts: dgInfo.key };

                    if (!monthGroups[monthKey][groupKey]) {
                        monthGroups[monthKey][groupKey] = new DateGroupValueSet(dateGrouping);
                    }
                    monthGroups[monthKey][groupKey].pushBudget(month, value);

                    if (!monthGroups[monthKey]['total']) {
                        monthGroups[monthKey]['total'] = new DateGroupValueSet(dateGrouping);
                    }
                    monthGroups[monthKey]['total'].pushBudget(month, value);
                    pTotal++;
                // }
            }
        }
        window.setTimeout(() => {
            // this.saveToSummaryCache(sKey, monthGroups);
            EM.log('Processed projections:', pTotal);
        }, 0);

        return monthGroups;
    }


    calculateBudgetValue(row, overlappingDays, overlappingMonths, preferences) {
        let baseBudget = row[this.filterIndexes.BaseBudget];
        let baseMonthlyRunRate = row[this.filterIndexes.BaseMonthlyRunRate];
        if (preferences.inst.begin || preferences.inst.end) {
            // Full Overlap (Complete Months)
            if (overlappingMonths >= 12) {
                return baseMonthlyRunRate * 12;
            }
            // Partial Overlap
            if (overlappingMonths > 0 && overlappingMonths < 12) {
                return baseMonthlyRunRate * overlappingMonths;
            }
            // Partial Month (Already Prorated)
            if (row[this.filterIndexes.Months] === 1) {
                return baseBudget;
            }
            // No Overlap
            return 0;
        }
        else{
            return baseBudget;
        }

    }
    calculateBudgetValueModal(row, overlappingDays, overlappingMonths, preferences) {
        let baseBudget = row[this.filterIndexes.BaseBudget];
        let baseMonthlyRunRate = row[this.filterIndexes.BaseMonthlyRunRate];
        if (preferences.inst.begin || preferences.inst.end) {
                return baseBudget * overlappingMonths;
        }
        else{
            return baseBudget;
        }

    }


    isRowIncluded(row, preferences) {
        let keys = this.filterIndexKeys;
        if (keys.length === 0) return true;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key === 'begin' || key === 'end' || key === 'value') continue;
            let acceptedValues = preferences.get(key);
            if (!acceptedValues) continue;
            let rowValue = row[this.filterIndexes[key]];

            if (key === 'activity') {
                rowValue = EM.activities.lookupValue(rowValue);
            }

            if (acceptedValues.indexOf(rowValue) === -1) {
                return false;
            }
        }
        return true;
    }

    getPoS(row) {
        let pos = 1;
        let posCol = this.filterIndexes.PoS || this.filterIndexes.POS || this.filterIndexes.pos;
        if (posCol) {
            pos = parseFloat(row[posCol] || 1);
        }
        return pos;
    }

    getSimpleAttritionFactor(month, attrition) {
        let key = month.toMillis().toString() + attrition;
        if (!this.attritionFactorCache[key]) {
            let months = Math.ceil(month.diff(Dates.now(), 'months').toObject().months);
            let factor = months > 0 ? 1 - ((months / 12) * attrition) : 1;
            this.attritionFactorCache[key] = factor > 0 ? factor : 0;
        }
        return this.attritionFactorCache[key];
    }

    getDetailByDate(ms, groupingValue, preferences, reserve, attrition, pos) {
        let datatable = [];
        let grouping = preferences.grouping || this.defaultGrouping;
        let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
        let dateGrouping = preferences.dateGrouping || this.defaultDateGrouping;
        let date = Dates.fromMs(ms);
        let dgInfo = this.getDateGroup(dateGrouping, date);
        this.processedData.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;
            if (row[groupingColumn] === groupingValue) {
                let overlapping = Dates.doRangesOverlap(dgInfo.begin, dgInfo.end, row[this.filterIndexes.begin], row[this.filterIndexes.end]);
                let isRowIncluded = this.isRowIncluded(row, preferences);
                if (overlapping && isRowIncluded) {
                    let value = row[this.filterIndexes.value];
                    if (reserve) value = value * (1 + reserve);
                    if (attrition) value = value * this.getSimpleAttritionFactor(date, attrition);
                    if (pos) value = value * this.getPoS(row);
                    datatable.push({
                        id: rowIndex,
                        organization: row[this.filterIndexes.organization],
                        department: row[this.filterIndexes.department],
                        role: row[this.filterIndexes.role],
                        workitem: row[this.filterIndexes.workitem],
                        activity: EM.activities.lookupValue(row[this.filterIndexes.activity]),
                        begin: (row[this.filterIndexes.begin].toLocaleString()).replace(/,.*/, ''),
                        end: (row[this.filterIndexes.end].toLocaleString()).replace(/,.*/, ''),
                        complexity: row[5],
                        value: value,
                        cid: row[7],
                        mid: row[8],
                        mm: row[9]
                    });
                }
            }
        });

        let groups = _.groupBy(datatable, 'role');
        return groups;
    }

    

    getDetailByDateBudgets(ms, groupingValue, preferences,type, reserve, attrition, pos) {
        let datatable = [];
        let groupingRole = preferences?.inst?.grouping || 'Role';
        let grouping = 'Workitem';
        let groupingColumnRole = this.filterIndexes[groupingRole.toLowerCase()];
        let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
        let dateRange = this.getDateRange(preferences);
        let dateRangeStart = dateRange[0].ts;
        let dateRangeEnd = dateRange[1].ts;
        this.processedDataModal.forEach((row, rowIndex) => {
            if (rowIndex === 0 || !row) return;
    
            // if (!beginDate || !endDate) return;
            // if (overlappingDays === 0) return;
            let budget = row[this.filterIndexes.BaseBudget]?row[this.filterIndexes.BaseBudget]:0;

            if (type === 'bar') {
                if (row && row[groupingColumn] === groupingValue && row[groupingColumnRole] === ms) {
                datatable.push({
                    id: rowIndex,
                    organization: row[this.filterIndexes.organization],
                    department: row[this.filterIndexes.department],
                    role: row[this.filterIndexes.role],
                    workitem: row[this.filterIndexes.workitem],
                    activity: EM.activities.lookupValue(row[this.filterIndexes.activity]),
                    begin: (row[this.filterIndexes.begin].toLocaleString()).replace(/,.*/, ''),
                    end: (row[this.filterIndexes.end].toLocaleString()).replace(/,.*/, ''),
                    complexity: row[this.filterIndexes.complexity],
                    budget: budget,
                    BaseHourlyRate: row[this.filterIndexes.BaseHourlyRate],
                    FteHours: row[this.filterIndexes.FteHours],
                    BaseBudget: row[this.filterIndexes.BaseBudget]?row[this.filterIndexes.BaseBudget]:'Error',
                    Year: row[this.filterIndexes.Year],
                    Months: row[this.filterIndexes.Month],
                });
                }
            } else if (type === 'workitem') {
                if (row && row[groupingColumn] === groupingValue) {
                datatable.push({
                    id: rowIndex,
                    organization: row[this.filterIndexes.organization],
                    department: row[this.filterIndexes.department],
                    role: row[this.filterIndexes.role],
                    workitem: row[this.filterIndexes.workitem],
                    activity: EM.activities.lookupValue(row[this.filterIndexes.activity]),
                    begin: (row[this.filterIndexes.begin].toLocaleString()).replace(/,.*/, ''),
                    end: (row[this.filterIndexes.end].toLocaleString()).replace(/,.*/, ''),
                    complexity: row[this.filterIndexes.complexity],
                    budget: budget,
                    BaseHourlyRate: row[this.filterIndexes.BaseHourlyRate],
                    FteHours: row[this.filterIndexes.FteHours],
                    BaseBudget: row[this.filterIndexes.BaseBudget]?row[this.filterIndexes.BaseBudget]:'Error',
                    Year: row[this.filterIndexes.Year],
                    Months: row[this.filterIndexes.Month],
                });
                }
            }
        });
        let groups = _.groupBy(datatable, 'workitem');
        return groups;
    }


    findProjections(workitemName, activityId, roleId) {
        if (!this.processedData) this.preprocessData();
        let output = this.processedData.filter((row, rowIndex) => {
            return (workitemName ? row[this.filterIndexes.workitem] === workitemName : true) &&
                (activityId ? row[this.filterIndexes.activity].toString() === activityId.toString() : true) &&
                (roleId ? row[this.filterIndexes.roleId].toString() === roleId.toString() : true);
        });

        return output;
    }

    groupAndFilterProjections(preferences) {
        if (!this.processedData) this.preprocessData();
        let groupingColumn = this.filterIndexes[preferences.grouping] || -1;

        let output = _.filteredNest(this.processedData.slice(1),
            row => {
                return this.isRowIncluded(row, preferences);
            },
            row => row[groupingColumn],
            row => row[this.filterIndexes.workitem].trim()
        );
        return output;
    }
}