/* global EM */
import React from 'react';
import { Label, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';

export default function PipelineField(props){        
    let options = EM.configurations.getPipelines().map((pipeline) => { 
        return { label: pipeline.Name, value: pipeline.Name };
    });
    let selectedOption = options.find(opt => opt.value === props.value);

    return (
        <FormGroup>      
            <Label title={props.description}>{props.display || props.name}:</Label>
            <Select
                className="single-select"
                classNamePrefix="single-select"
                isSearchable={true}
                options={options}
                value={selectedOption}
                isClearable={props.isClearable || false}
                onChange={(selectedItem) => {
                    props.onChange(props.name, selectedItem?selectedItem.value:null);
                }}
            /> 
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null }
        </FormGroup>
    );
}