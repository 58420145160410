/* global EM */
import React from 'react';
import { useState } from 'react';

const Notifications = ({ notifications}) => {    
    let [userNotifications, setUserNotifications] = useState(notifications);

    const handleNotificationClicked = (notification) => {
        removeNotification(notification);
        if (notification.type === "new_release") {
            window.open("/support?path=/x/release", '_blank');
        }
    }

    const removeNotification = (notification) => {
        EM.removeNotification(notification);

        let idNum = parseInt(notification.id);
        setUserNotifications(userNotifications.filter(x => {
            return x.id !== idNum;
        }));
    }

    return (
        <div className="notifications-container">
            {userNotifications.map((notification) =>
                <div className="notification-item" key={notification.id} onClick={() => handleNotificationClicked(notification)}>{notification.Message}
                    <i className="fas fa-times" title={EM.t('util.notificationDismiss')} onClick={(event) => {
                        event.stopPropagation();
                        removeNotification(notification);
                    }}/>
                </div>
            )}
        </div>
    )
}

export default Notifications;