/* global EM */
import React, { Component, Suspense } from 'react';
import { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import EntityImporter from './EntityImporter';
import { Button } from 'reactstrap';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';


const EntityCreator = React.lazy(() => import('./EntityCreator'));

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

class EntityTableTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            importModalOpen: false,
            createModalOpen: false,
        };
    }

    render() {
        return [
            <div className="table-tools" key="table-tools">
                {!this.props.restrictedOperations ?
                    <div className="btn-group">
                        {!this.props.hideAddRecord ?
                            <button className="btn btn-success btn-sm" title={EM.t('util.table.addRecord')} onClick={() => {
                                this.setState({ createModalOpen: true });
                            }}>
                                <i className="fas fa-plus"></i><span>{EM.t('util.table.addRecord')}</span>
                            </button>
                        : null}
                        {!this.props.hideDelete ?
                            <button className="btn btn-danger btn-sm" title={EM.t('util.table.deleteRecords')} onClick={this.props.onDelete} disabled={!this.props.selectedRowsNumber}>
                                <i className="fas fa-trash-alt"></i><span>{EM.t('util.table.deleteRecords')}{this.props.selectedRowsNumber > 0 ? ` Selected (${this.props.selectedRowsNumber})` : ''}</span>
                            </button>
                        : null}
                    </div>
                    : null}
                {!this.props.entity.isFileListingTable && !this.props.hideImportExport ?
                    <div className="btn-group">
                        {!this.props.restrictedOperations && EM.isDomainFinanceManager() && !this.props.hideImport ?
                            <button className="btn btn-secondary btn-sm" title={EM.t('util.table.importRecords')} onClick={() => {
                                this.setState({ importModalOpen: true });
                            }}>
                                <i className="fas fa-file-import"></i><span>{EM.t('util.table.importRecords')}</span>
                            </button>
                            : null}
                        <ExportCSVButton className="btn btn-secondary btn-sm" title={EM.t('util.table.exportRecords')} {...this.props.csvProps}>
                            <i className="fas fa-download"></i><span>{EM.t('util.table.exportRecords')}{this.props.selectedRowsNumber > 0 ? ` Selected (${this.props.selectedRowsNumber})` : this.props.showingFilters ? ' Filtered' : ' All'}</span>
                        </ExportCSVButton>
                        {this.props.hasOutputPipeline || this.props.customExportFunc ?
                            <UncontrolledButtonDropdown size="sm" color="secondary" className="flush dd-menu">
                                <DropdownToggle>
                                    <i className="fas fa-caret-down"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.props.customExportFunc?
                                        <DropdownItem onClick={this.props.customExportFunc}>
                                            {this.props.customExportLabel || 'Export Custom' }
                                        </DropdownItem>                                    
                                    : 
                                        <DropdownItem onClick={this.props.onPipelineExport}>
                                            {EM.t('util.table.exportViaPipeline')}
                                        </DropdownItem>
                                    }
                                </DropdownMenu>                                
                            </UncontrolledButtonDropdown>
                        : null}
                    </div>
                    : null}
                {this.props.children}
                {!this.props.restrictedOperations && EM.isDomainAdmin() ?
                    <div className="btn-group">
                        {!this.props.hideAudit ?
                            <button className="btn btn-info btn-sm" title={EM.t('util.table.audit')} onClick={this.props.onAudit}>
                                <i className="fas fa-check-double"></i><span>{EM.t('util.table.audit')}</span>
                            </button>
                            : null}
                        {EM.debugMode && !this.props.hideClearAll ?
                            <button className="btn btn-warning btn-sm" title={EM.t('util.table.clearRecords')} onClick={(event) => this.props.onClear(event, this.props.csvProps)}>
                                <i className="fas fa-skull-crossbones"></i><span>{EM.t('util.table.clearRecords')}</span>
                            </button>
                            : null}
                    </div>
                    : null}
                {this.props.shouldShowFilters ?
                    <div className="btn-group">
                        <Button size="sm" color="light" title={EM.t('util.table.toggleFilters')} className={"btn-filters " + (this.props.showingFilters ? 'active' : '')} onClick={(event) => {
                            event.target.blur();
                            window.setTimeout(this.props.onToggleFilters, 0);
                        }}><i className={"fas fa-filter"}></i></Button>
                        {this.props.showingFilters ?
                            <Button size="sm" color="light" title={EM.t('util.table.clearFilters')} className={"btn-filters-clear active"} onClick={(event) => {
                                event.target.blur();
                                window.setTimeout(this.props.onClearFilters, 0);
                            }}><i className="fas fa-eraser"></i><span>Clear Filters</span></Button>
                            : null}
                    </div>
                    : null}
                {
                    !this.props.hideSearch ? <SearchBar id="FldSearch" className="form-control-sm" {...this.props.searchProps} placeholder={EM.t('util.table.searchPlaceholder')} /> : null
                }
                {
                    !this.props.hideSearch ? <ClearSearchButton {...this.props.searchProps} className="btn-search-clear" text="&nbsp;" /> : null
                }
                {this.props.shouldShowColumnToggle ?
                    <div className="btn-group">
                        <Button size="sm" color="light" className={"btn-columns " + (this.props.showingColumnToggle ? 'active' : '')} onClick={(event) => {
                            event.target.blur();
                            this.props.onToggleColumnToggle();
                        }}><i className="fas fa-columns"></i></Button>
                    </div>
                    : null}
            </div>,
            <EntityImporter onBeforeImport={this.props.onBeforeImport} columns={this.props.columns} entity={this.props.entity} isOpen={this.state.importModalOpen} key="importModal"
                onClose={() => {
                    this.setState({ importModalOpen: false });
                }}
            />,
            <Suspense fallback="Loading..." key="createModalSus" >
                <EntityCreator onCreate={this.props.onCreate} columns={this.props.columns} entity={this.props.entity} isOpen={this.state.createModalOpen} key="createModal"
                    onClose={() => {
                        this.setState({ createModalOpen: false });
                    }}
                />
            </Suspense>
        ];
    }
}

export default EntityTableTools;
