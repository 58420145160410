import Entity from './Entity';
import _ from 'underscore';

export default class EmployeesEntity extends Entity{
    getActive(){
        let state = this.get();
        if (!state)return [];
        let active = [];        
        state.forEach((row) => {
            if (row.Active) active.push(row);
        });
        return active;
    }

    lookupValue(id, fld){
        let found = this.asIndexed()[id];
        if (fld){
            return found ? found[fld || 'Name'] : '';
        }else{
            return found ? found['FirstName'] + ' ' + found['LastName'] : '';
        }
    }

    asValueList(labelField){
        let state = this.get();
        if (!state)return [];
        if (!this.valueList){
            this.valueList = {};
            state.forEach((row) => {
                this.valueList[row['FirstName'] + ' ' + row['LastName']] = row[this.idField];
            });
        }
        return this.valueList;
    }

    asOptionList(labelField, sortByLbl){
        let state = this.get();
        if (!state)return [];
        if (!this.optionList){
            this.optionList = state.mapFiltered((row) => {
                let lbl = row['FirstName'] + ' ' + row['LastName'];
                if (labelField && typeof labelField === 'function'){
                    lbl = labelField(row);
                }
                if (!lbl)return null;
                return { value: row[this.idField], label: lbl, LastName :row['LastName'] };
            });
            if (sortByLbl){
                this.optionList = _.sortBy(this.optionList, (item) => {
                    return item.label;
                });
            }  
        }      
        return this.optionList;
    }    
}