/* ---------------------------------
This class is a partial copy of the Expression class found in 
RM.Functions/lib. It is included here to reduce the size of the 
dependency tree for the main part of the application.

-JS
 --------------------------------- */

export default class ExpressionLocal {
    constructor(expressionStr) {
        this.rawExpression = expressionStr;
        this.parsedExpression = null;
        if (expressionStr) this.setExpression(expressionStr);
    }

    static getDefaultExpression() {
        return [{ expression: 'Projection' }]
    }

    static getDefaultExpressionStr() {
        return JSON.stringify(ExpressionLocal.getDefaultExpression());
    }

    static getExpressionStrFromStr(str){
        return JSON.stringify([{ expression: str }]);
    }

    setExpression(expressionStr) {
        let parsedExpression = null;
        try {
            parsedExpression = JSON.parse(expressionStr);
        } catch (e) { }

        if (!parsedExpression || !Array.isArray(parsedExpression)) {
            parsedExpression = ExpressionLocal.getDefaultExpression();
        }

        let cleanExpression = [];
        parsedExpression.forEach((clause, index) => {
            if (!clause) return;
            clause._key = new Date().getTime() + index;
            cleanExpression.push(clause);
        });

        this.parsedExpression = cleanExpression;
    }

    renderExpressionStr(prefix, delimiter) {
        let output = [];
        if (this.parsedExpression.length === 1) {
            let expressionBit = this.parsedExpression[0].expression;
            output.push(expressionBit);
        } else {
            this.parsedExpression.forEach((clause, index) => {
                if (!clause) return;
                if (!clause.expression) return;
                let expressionBit = clause.expression.indexOf(' ') > - 1 ? '(' + clause.expression + ')' : clause.expression;
                let operatorBit = (clause.operator ? clause.operator + ' ' : (index > 0 ? '* ' : ''));
                output.push(operatorBit + expressionBit.replace('\n', ''));
            });
        }
        return (prefix || '') + output.join(delimiter || ' ');
    }
}