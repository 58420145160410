import * as types from '../actionTypes';

export default function domain(state = null, action) {
    switch (action.type) {
        case types.SET_DOMAIN:
            return action.data;
        case types.CLEAR_DOMAIN:
            return null;
        default:
            return state;
    }
}