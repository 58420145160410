export default {
    objToQs: (obj) => {
        return Object.keys(obj).map(function(key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
        }).join('&');
    },

    qsToObj: () => {
        var search = window.location.search.substring(1);
        if (!search)return {};
        return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
    }
}