import * as types from '../actionTypes';

export default function projections(state = {}, action) {
    switch (action.type) {
        case types.LOAD_PROJECTIONS:
            return Object.assign({}, state, { [ action.key ] : action.data })
        case types.CLEAR_PROJECTIONS:
            return {}          
        default:
            return state;
    }
}