/* global EM */
import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import Dates from '../util/Dates';

export default class CannedDateRangeSelector extends Component {
    constructor(props){
        super(props);
        
        this.customDrs = [];
    }

    componentDidMount(){
        this.customDrs = [...(this.props.custom ? this.props.custom : [])];
        let keyPrefix = 'DateRange:';
        EM.settings.get().forEach(setting => {
            let key = setting.Key;
            if (key.indexOf(keyPrefix) !== 0)return;
            let label = key.replace(keyPrefix, '').trim();
            let value = [null, null];
            try{
                let years = JSON.parse(setting.Value);
                if (years[0])value[0] = Dates.now().plus({ years: years[0] });
                if (years[1])value[1] = Dates.now().plus({ years: years[1] });
            }catch(e){
                EM.log('Error parsing date range:', setting.Value);
            }
            this.customDrs.push({ label: label, setter: () => {
                this.onSelect(value[0], value[1]);
            }})
        });
    }

    onSelect(begin, end){
        this.props.onSelect(begin, end);
    }

    render() {
        return (
            <UncontrolledButtonDropdown color="secondary" className="utility-menu">
                <DropdownToggle>
                    <i className="far fa-calendar-alt" color="light" style={{ margin: '0' }}></i>&nbsp;<i className="fas fa-caret-down"></i>
                </DropdownToggle>
                <DropdownMenu right positionFixed={true}>
                    <DropdownItem onClick={event => {
                        this.onSelect(null, null);
                    }}>
                        {EM.t('util.tools.defaultRange')}
                    </DropdownItem>  
                    <DropdownItem onClick={event => {
                        this.onSelect(Dates.now(), Dates.now().plus({ years: 3 }));
                    }}>
                        {EM.t('util.tools.threeYearRange')}
                    </DropdownItem>                                                                      
                    <DropdownItem onClick={event => {
                        this.onSelect(Dates.now(), Dates.now().plus({ years: 5 }));
                    }}>
                        {EM.t('util.tools.fiveYearRange')}
                    </DropdownItem>   
                    <DropdownItem onClick={event => {
                        this.onSelect(Dates.now().plus({ years: -1 }), Dates.now().plus({ years: 2 }));
                    }}>
                        {EM.t('util.tools.threeYearWindow')}
                    </DropdownItem>      
                    <DropdownItem onClick={event => {
                        this.onSelect(Dates.now().plus({ years: -2 }), Dates.now().plus({ years: 3 }));
                    }}>
                        {EM.t('util.tools.fiveYearWindow')}
                    </DropdownItem>                                                                      
                    {this.customDrs ? 
                        <>
                            {this.customDrs.map((custom, customIndex) => {
                                return (
                                    <DropdownItem key={"custom" + customIndex} onClick={custom.setter}>{custom.label}</DropdownItem>   
                                )
                            })}
                        </>
                    : null}                                                                                                                           
                </DropdownMenu>
            </UncontrolledButtonDropdown> 
        );
    }
};