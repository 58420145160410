/* global EM */
import React, { useState } from 'react';
import PanelModal from './PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input, CustomInput } from 'reactstrap';
import Select from 'react-select';
import PipelineField from './JsonEditor/PipelineField';

let entityOptions = null;
function getEntityOptions(){
    return [
        { label: 'Activities', value: EM.activities.name, entity: EM.activities },
        { label: 'Actuals & Baselines', value: EM.actuals.name, entity: EM.actuals }, 
        { label: 'Allocations', value: EM.allocations.name, entity: EM.allocations },
        { label: 'Assignments', value: EM.assignments.name, entity: EM.assignments }, 
        { label: 'Attributes', value: EM.attributes.name, entity: EM.attributes },
        { label: 'Complexities', value: EM.complexities.name, entity: EM.complexities },
        { label: 'Configurations (Reports and Pipelines)', value: EM.configurations.name, entity: EM.configurations }, 
        { label: 'Custom Algorithms', value: EM.algorithms.name, entity: EM.algorithms },
        { label: 'Departments', value: EM.departments.name, entity: EM.departments },
        { label: 'Employees', value: EM.employees.name, entity: EM.employees },
        { label: 'Headcounts', value: EM.headcounts.name, entity: EM.headcounts }, 
        { label: 'Organizations', value: EM.organizations.name, entity: EM.organizations },
        { label: 'Roles', value: EM.roles.name, entity: EM.roles },
        { label: 'Projections', value: EM.projections.name, entity: EM.projections },
        { label: 'Schedules', value: EM.schedules.name, entity: EM.schedules }, 
        { label: 'Scenarios', value: EM.scenarios.name, entity: EM.scenarios },
        { label: 'RateCard', value: EM.rateCards.name, entity: EM.rateCards},
        { label:'AverageWorkingHours', value: EM.averageWorkingHours.name, entity: EM.averageWorkingHours},
        { label:'CurrencyConversions', value:EM.countryCurrencies.name, entity: EM.countryCurrencies},
        { label:'InflationFactors', value:EM.countryCurrencies.name, entity: EM.inflationFactors},
        { label:'Budgets', value:EM.budgets.name, entity: EM.budgets},
    ];
}

function updateSetting(key, newValue){
    let settingObj = EM.settings.findByKey(key);
    let value = null;
    if (settingObj)value = settingObj.Value;

    if (newValue){
        if (newValue !== value){
            if (settingObj){
                let newObj = Object.assign({}, settingObj, { Value: newValue });
                EM.settings.update(settingObj, newObj);
            }else{
                EM.settings.create({
                    Key: key,
                    Value: newValue
                });
            } 
        }                   
    }else{
        if (settingObj){
            EM.settings.delete([settingObj.SettingId]);
        }
    }
}

export default function EntityConfigPanel(props){
    let [ entitySelection, setEntitySelection ] = useState(null);

    if (!entityOptions)entityOptions = getEntityOptions();
    
    let modelName = entitySelection?entitySelection.entity.modelName:'';
    let pipelineInputKey = 'Pipelines:' + modelName + ':Input';
    let pipelineOutputKey = 'Pipelines:' + modelName + ':Output';
    let overrideKey = 'OverrideParent:' + modelName;
    let limitKey = 'Limit:' + modelName;
    let webhookKey = 'Webhook:' + modelName;

    return (
        <PanelModal isOpen={props.isOpen} className={'panel'} toggle={(event) => { props.close(); }}>
            <ModalHeader toggle={props.onClose}>Entity Configuration</ModalHeader>
            <ModalBody>   
                <div className="form-group">
                    <Select
                        className="single-select"
                        classNamePrefix="single-select"
                        isSearchable={true}
                        isMulti={false}
                        isClearable={true}
                        value={entitySelection}
                        options={entityOptions}
                        placeholder={EM.t('util.filtering.placeholder')}
                        onChange={(selection) => {
                            //This is a hack!
                            setEntitySelection(null);
                            if (selection){
                                window.setTimeout(() => {
                                    setEntitySelection(selection);
                                }, 0);
                            }                         
                        }}
                    />
                </div>
                {entitySelection ?
                    <>
                        <h5 className="mt-4">Pipelines</h5>
                        <PipelineField value={EM.getSetting(pipelineInputKey)} isClearable={true} name={pipelineInputKey} display="Input" description="Input" onChange={(name, value) => {
                            updateSetting(pipelineInputKey, value);
                        }}/>
                        <PipelineField value={EM.getSetting(pipelineOutputKey)} isClearable={true} name={pipelineOutputKey} display="Output" description="Output" onChange={(name, value) => {
                            updateSetting(pipelineOutputKey, value);
                        }}/>                      

                        <hr className="mt-4 mb-4"/>
                        <h5>Parent Domains</h5>
                        <FormGroup>      
                            <CustomInput defaultChecked={EM.getSetting(overrideKey) === 'true'} type="switch" id="overrideParentSwitch" name="overrideParentSwitch" label="Override Parent" onChange={(event) => {
                                updateSetting(overrideKey, event.target.checked?'true':null);
                            }}/>           
                        </FormGroup>
                        <FormGroup>      
                            <Label>Limit Parent Contents:</Label>
                            <Input 
                                defaultValue={EM.getSetting(limitKey)||''} 
                                onBlur={event => {
                                    updateSetting(limitKey, event.target.value||null);
                                }} 
                            />
                        </FormGroup>                                               
                        
                        <hr className="mt-4 mb-4"/>
                        <h5>Other</h5>
                        <FormGroup>      
                            <Label>Webhook:</Label>
                            <Input 
                                defaultValue={EM.getSetting(webhookKey)||''} 
                                onBlur={event => {
                                    updateSetting(webhookKey, event.target.value||null);
                                }} 
                            />
                        </FormGroup>   
                    </>
                : null }
            </ModalBody>
            <ModalFooter>
                <Button className="pull-left" onClick={props.onClose}>
                    {EM.t('util.closeButton')}
                </Button>
            </ModalFooter>
        </PanelModal>
    );
};