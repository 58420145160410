/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import Dates from '../../util/Dates';
import _ from 'underscore';

const CalendarPercentagePopover = (props) => {
    let oRange = props.data.range;
    let nRange = Dates.getArrayOfMonths(oRange.dates[0].startOf('year'), oRange.dates[oRange.dates.length - 1].endOf('year'), true);  
    let chunks = _.chunk(nRange.dates, 12);
    let dateIndex = _.indexBy(props.data.months, (item, itemIndex) => {
        return oRange.beginIndex + itemIndex;
    });

    let index = nRange.beginIndex;

    return (
        <Popover container={'.assignments-dialog-modal'} placement="left" isOpen={props.isOpen} target={props.target} toggle={props.toggle}>
            <PopoverBody>
                <div className="popover-calendar">
                    <div className="popover-calendar-header">
                        <label>&nbsp;</label>
                        <span>J</span>
                        <span>F</span>
                        <span>M</span>
                        <span>A</span>
                        <span>M</span>
                        <span>J</span>
                        <span>J</span>
                        <span>A</span>
                        <span>S</span>
                        <span>O</span>
                        <span>N</span>
                        <span>D</span>
                    </div>
                    {chunks.map((yearChunk, ycI) => { 
                        let first = yearChunk[0];         
                        return (
                            <div key={'yc' + ycI}>
                                <label>{first.year}</label>
                                {yearChunk.map((monthChunk, mcI) => {
                                    let monthItem = dateIndex[index];
                                    let perc = monthItem ? (monthItem.value * 100).toFixed(0) : null;
                                    index++;
                                    return <span key={'mc' + mcI} className={monthItem?monthItem.cls:''}>{perc?perc:''}</span>
                                })}
                            </div>
                        );
                    })}
                </div>
            </PopoverBody>
        </Popover>
    );
}

export default CalendarPercentagePopover;