/* global EM */

import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';
import { Type } from 'react-bootstrap-table2-editor';

class InflationFactor extends Component {
    constructor(props) {
        super(props);
        EM.inflationFactors.load();
        EM.countryCurrencies.load();
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.inflationFactors;

        if (EM.allLoaded(entity, EM.countryCurrencies)) {
            data = entity.get();


            const inflationFactorCountries = new Set(data.map(item => item.Country));


            let countryCurrencies = EM.countryCurrencies.get();
            let options = countryCurrencies
            .filter(item => !inflationFactorCountries.has(item.Country))
                .map(item => ({
                    value: item.Country,
                    label: item.Country,
                }));

            let currencyCurrenciesLookup = (cell, row) => {
                return row.Country;
            };
            let filterCountryCurrenciesLookup = (cell, row) => {
                return row.Country;
            }

            columns = [
                {
                    dataField: "Country",
                    text: (entity.columns('Country')).split('.').pop(),
                    validators: [Validators.required,Validators.countryExists],
                    formatter: currencyCurrenciesLookup,
                    sortValue: currencyCurrenciesLookup,
                    filterValue: filterCountryCurrenciesLookup,
                    editor: {
                        type: Type.SELECT,
                        options: options,
                        //getOptions - Filters out the existing Countries from the table expect the current row(Country) value.
                        getOptions: (setOptions, params) => {
                          const filteredOptions = countryCurrencies
                          .filter(item => item.Country === params.row.Country? true: !inflationFactorCountries.has(item.Country))
                          .map(item => ({value: item.Country,label: item.Country,}));
                          return filteredOptions
                        }
                    },
                    filterOptions: {
                        text: true,
                    },
                },
                {
                    dataField: "InflationFactor",
                    text: "Inflation Factor",
                    validators: [Validators.required],
                    formatter: (cell, row) => {
                        return (row.InflationFactor * 100).toFixed(3) + " %";
                    },

                    filterOptions: {
                        text: true,
                    },
                },
            ];
        }
        return (
            <div key="contents" className="page container-fluid">
                <BudgetsTabs {...this.props} />
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container tabs" 
                hideDelete={!EM.isDomainFinanceManager()} 
                hideAddRecord={!EM.isDomainFinanceManager()}/>
            </div>
        );
    }
}

export default InflationFactor;
