import React, { useState, useRef  } from 'react';

export default function InputLabel(props){  
    let { text, altText, onEdited, disabled, className, type, characterLimit } = props;
    const inputRef = useRef();
    let [ editing, setEditing ] = useState(false);

    function focusItem(){
        inputRef.current.focus();
    }    

    if (editing){
        return (type === 'textarea' ? 
                <textarea ref={inputRef} rows="2" maxLength={characterLimit} className={"form-control " + className} onClick={event => {
                    event.stopPropagation();
                }} onBlur={event => {
                    onEdited(event);
                    setEditing(false);
                }} defaultValue={text} />
            :
                <input ref={inputRef} maxLength={characterLimit} className={"form-control " + className} onClick={event => {
                    event.stopPropagation();
                }} onBlur={event => {
                    onEdited(event);
                    setEditing(false);
                }} defaultValue={text} />
        );
    }else{
        let displayText = text ? text.trim() : altText;
        return (
            <span className={className} onClick={event => {
                if (disabled)return;
                event.stopPropagation();
                setEditing(true);
                window.setTimeout(focusItem, 0);
            }} title={text}>{displayText||altText}</span>
        );
    }    
}