/* global EM */
export function calculateBudgets(domainId, entity, projectionId, isSilent) {
  let entityName = entity.name;
  return (dispatch, getState, api) => {
      return api.calculateBudgets(domainId, projectionId, isSilent).then(response => {
          let pid = response.BudgetId;
          let fileObj = new entity.fileType(response.Budgets, entityName, pid);
          fileObj.setMeta({ Count: response.Count, ProcessingTime: response.ProcessingTime });
          return dispatch({ type: 'LOAD_FILE', key: entity.makeFileName(pid), data: fileObj });
      }).catch(error => {
          EM.setError(error);
          return dispatch({ type: 'FILE_ERROR', key: entity.makeFileName(-1) });
      });
  };
}