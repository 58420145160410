import React, { Component } from 'react';
import Dates from '../../util/Dates';
import _ from 'underscore';

export default class StaffingTimeline extends Component {
    render(){
        let {range} = this.props;
        let yearGroups = _.groupBy(range.dates, 'year');
        let yearKeys = Object.keys(yearGroups);
        return (
            <div className={ "timeline "}>
                <div className="timeline-header">&nbsp;</div>
                {yearKeys.map((yearKey, yearIndex) => {
                    return (
                        <div key={yearIndex} className="timeline-year">
                            <span>{yearKey}</span>
                            {yearGroups[yearKey].map((month, monIndex) => {               
                                return (
                                    <div key={monIndex} className="timeline-month">
                                        {Dates.toMonthStrShort(month)}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
};