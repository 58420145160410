/* global EM */
import * as types from '../actionTypes';

export function loadDomainEntityFile(domainId, entity, id) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.loadDomainEntityFile(domainId, entityName, id).then(data => {                    
            return dispatch({ type: 'LOAD_FILE', key: entity.makeFileName(id), data: new entity.fileType(data, entityName, id) });
        }).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'FILE_ERROR', key: entity.makeFileName(id) });
        });
    };
}

export function updateDomainEntityFileBackground(domainId, entity, id, data) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.updateDomainEntityFile(domainId, entityName, id, data).then(data => {                    
            return dispatch({ type: 'UPDATE_FILE', key: entity.makeFileName(id) });
        }).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'FILE_ERROR', key: entity.makeFileName(id) });
        });
    };
}

export function invalidateFile(domainId, entity, id) {
    return (dispatch, getState, api) => {
        return dispatch({ type: 'FILE_ERROR', key: entity.makeFileName(id) });
    };
}

export function clearFiles() {
    return { type: types.CLEAR_FILES };
}
