/* global EM */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Routes from '../../app/Routes';
import Dates from '../../util/Dates';
import Spinner from '../Spinner';

export default class WorkItemsEndingWidget extends Component{
    constructor(props){
        super(props);    
                
        let domain = EM.getActiveDomain();       
        this.staffingWiUrl = Routes.compose(Routes.client.staffingWorkItem, { DomainName: domain.Name }) + '#';        

        this.state = {
            workItems: null
        };        
        
        this.count = 15;
    }
    
    componentDidMount(){
        let self = this;
        self._isMounted = true;

        EM.schedules.loadDefaultItem().then(async (defaultSchedule) => {
            if (!defaultSchedule)return;
            await EM.activities.load();
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (!fileContents)return;

                let wiCache = {};
                fileContents.toObjectArray(item => {                    
                    let endObj = Dates.fromISO(item.End);
                    let diff = endObj.diff(Dates.now(), 'months').toObject();                
                    if (Math.abs(diff.months) > 1.5)return null;
                    if (wiCache[item.Name]){
                        if (wiCache[item.Name] < endObj){
                            wiCache[item.Name] = endObj;    
                        }
                    }else{
                        wiCache[item.Name] = endObj;
                    }
                });
                
                let workItems = Object.entries(wiCache).sort((a, b) => a[1] - b[1]);
                
                if (self._isMounted)this.setState({ workItems });
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        return (
            <div>
                <h4>{EM.t('widgets.workItemsEndingSoon.title')}</h4>
                <div className="card mt-2 widget">
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.workItems ? 
                                <div className="pills">
                                    {this.state.workItems.slice(0, this.count).map((item, itemIndex) => {
                                        let dtStr = Dates.toMonthYearStrShort(item[1]);
                                        return (
                                            <NavLink to={this.staffingWiUrl + item[0].toIdToken()}
                                                key={item[0] + 'itemIndex'}
                                                title={item[0] + ' - ' + dtStr}   
                                            >
                                                <b>{item[0]}</b>
                                                <i>{dtStr}</i>
                                            </NavLink>
                                        )
                                    })}
                                    {this.state.workItems.length > this.count ?
                                        <NavLink key={'over'} className="overflow-message" to={this.staffingWiUrl}>
                                            Plus {this.state.workItems.length - this.count} more...
                                        </NavLink>
                                    : null }
                                    {this.state.workItems.length === 0 ?
                                        <NavLink key={'over'} className="overflow-message" to={this.staffingWiUrl}>
                                            No Work Items found.
                                        </NavLink>                                    
                                    : null}
                                </div>
                            : <Spinner text={EM.t('util.loading')} classes="small"/> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};