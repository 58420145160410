import React, { Component } from 'react';

export default class TemporaryStatusMessage extends Component {
    constructor(props){
        super(props);

        this.state = {
            message: null,
            className: ''
        }
    }

    setMessage(message, className, timeout){
        let self = this;
        self.setState({ message, className }, () => {
            window.setTimeout(() => {
                self.clearMessage();
            }, timeout || 5000);
        });
    }

    clearMessage(){
        let self = this;
        self.setState({ message: null, className: '' });
    }

    render() {
        return (
            <div className={'status-message ' + (this.state.message?'active ':' ') + (this.state.className)} >
                <button type="button" className="close" aria-label="Close" onClick={() => {
                    this.clearMessage();
                }}><span aria-hidden="true">×</span></button>
                <div className="d-inline-block">{this.state.message}</div>
            </div>
        );
    }
}
