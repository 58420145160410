import Dates from '../../util/Dates';

export default class ScenariosFileDataStructure{
    constructor(data, notifyOfChange){
        this.modificationsPropName = 'modifications';
        this.removalPropName = 'removals';
        this.additionsPropName = 'additions';        
        this.onChange = this.onChange.bind(this);
        this.notifyOfChange = notifyOfChange;
        
        this.modifications = new ScenariosFileSection(this.modificationsPropName, data[this.modificationsPropName] || {}, this.onChange);
        this.removals = new ScenariosFileSection(this.removalPropName, data[this.removalPropName] || {}, this.onChange);
        this.additions = new ScenariosFileSection(this.additionsPropName, data[this.additionsPropName] || {}, this.onChange);
    }

    onChange(name, dataset, operation, id, props){
        if (this.notifyOfChange)this.notifyOfChange(name, dataset, operation, id, props);
    }

    serialize(){
        return {
            [this.modificationsPropName]: this.modifications.serialize(),
            [this.removalPropName]: this.removals.serialize(),
            [this.additionsPropName]: this.additions.serialize()
        };
    }
}

class ScenariosFileSection{
    constructor(name, data, onChange){
        this.name = name;
        this.data = this.deserialize(data);
        this.onChange = onChange;
    }

    get(id){
        if (id){
            return this.data[id];
        }else{
            return this.data;
        }
    }

    set(id, props){
        this.data[id] = props;
        this.onChange(this.name, this.data, 'set', id, props);
    }

    merge(id, props){
        let existingObject = this.get(id) || {};
        this.set(id, Object.assign(existingObject, props));
    }

    remove(id){
        delete this.data[id];
        this.onChange(this.data, 'remove', id);
    }

    serialize(){
        let output = {};
        Object.keys(this.data).forEach((key) => {
            output[key] = this._serializeObject(this.data[key]);
        });

        return output;
    }

    _serializeObject(obj){
        return obj;
    }

    deserialize(dataIn){
        let output = {};
        Object.keys(dataIn).forEach((key) => {
            output[key] = this._deserializeObject(dataIn[key]);
        });
        return output;
    }

    _deserializeObject(obj){
        let tmp = {};
        Object.keys(obj).forEach((key) => {
            let value;
            if (key === 'Begin' || key === 'End'){
                value = Dates.fromISO(obj[key]);
            }else{
                value = obj[key];
            }
            tmp[key] = value;
        });
        return tmp;
    }    
}
