/* global EM */
import React, { Component } from 'react';
import logo from '../images/logo.svg';
import MetaInfo from './MetaInfo';
import HelpIndex from "../entities/help/HelpIndex";
import Spinner from './Spinner';

export default class TermsOfService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            termsContent: null
        };

        let meta = HelpIndex.getContentMetaByKey('terms');
        HelpIndex.getContent(null, meta, true).then((details) => {
            this.setState({ termsContent: details.body });
        })
    }

    render() {
        return (
            <div className="landing-page terms">
                <div>
                    <div className="header">
                        <img src={logo} alt="logo" />                        
                    </div>
                    <div className="content clearfix">
                        <h5>Our Terms of  Service Have Changed</h5>
                        <div className="text-muted">
                            Please review the updated Terms and Conditions below, then acknowledge your acceptance. 
                        </div>
                    </div>  
                    <div className="content clearfix overflow">
                        {this.state.termsContent ? 
                            <div dangerouslySetInnerHTML={{ __html: this.state.termsContent }} />
                        : 
                            <Spinner text="Loading..."/>
                        }
                    </div>
                    <div className="content clearfix">
                        <div>
                            <button className="btn btn-secondary float-left" onClick={() => {
                                window.location = 'https://www.intrinsiccs.com';
                            }}>Cancel</button>&nbsp;
                            <button className="btn btn-success float-right" onClick={() => {
                                EM.setAcceptedTerms();
                                window.location.reload();
                            }}>Acknowledge &amp; Accept</button>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <MetaInfo noTerms={true}/>
                </div> 
            </div>
        );
    }
}