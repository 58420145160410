import * as types from '../actionTypes';
import * as errorActions from './error';
import HelpIndex from '../help/HelpIndex'


export function loadTermsSuccess(termsData) {
    return { type: types.LOAD_TERMS, data: termsData };
}

export function loadTermsData() {
    return (dispatch, getState, api) => {

        const termsContentCID = HelpIndex.content.terms.cid;
        const termsContentName = HelpIndex.content.terms.key;
        const language = getState().translation.name ?? 'en';
        const doLoadMetaData = true;

        return api.loadHelp(termsContentCID, termsContentName, language, doLoadMetaData).then(data => {
            const terms = {
                dateUpdated: data.content.version.when
            };

            return dispatch(loadTermsSuccess(terms));
        }).catch(error => {
            console.log(error);
            dispatch(errorActions.globalError({ message: 'Terms meta data could not be fetched.' }));
        });
    };
}