/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';

class CountryCurrencyMap extends Component {
    constructor(props) {
        super(props);
        EM.countryCurrencies.load();
        EM.currencyCodes.load();
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.countryCurrencies;

        
        if (EM.allLoaded(entity,EM.currencyCodes)) {
            data = entity.get();            
            let currencyCodeLookup = (cell,row)=>{
                let countryCurrencyCode = EM.countryCurrencies.lookupValue(row.CountryCurrencyId,'CurrencyCodeId');                
                return EM.currencyCodes.lookupValue(countryCurrencyCode,'Code'); 
            }
            columns = [
                {
                    dataField: 'Country',
                    text: 'Country',
                    isReference: true,
                    validators: [Validators.required],
                    formatter: (cell, row) => {
                      return row.Country
                    },
                    filterOptions: {
                        text: true
                    }
                },
                {
                    dataField: 'CurrencyCode',
                    text: 'Currency Code',
                    isReference: true,
                    validators: [Validators.required],
                    formatter: currencyCodeLookup,
                    filterValue: currencyCodeLookup,
                    sortValue:currencyCodeLookup,
                    filterOptions: {
                        text: true
                    }
                }
            ];
        }

        return (
            <div key="contents" className="page container-fluid">
                <BudgetsTabs {...this.props} />
                <EntityTable
                    entity={entity}
                    data={data}
                    columns={columns}
                    className="table-overflow-container tabs"
                    hideImportExport={true} 
                    hideClearAll={true} 
                    hideDelete={true} 
                    hideAudit={true}
                    hideAddRecord={true}
                />
            </div>
        );
    }
}

export default CountryCurrencyMap;
