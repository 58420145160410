/* global EM */
import File from '../../util/File';
import CSV from '../../util/CSV';
import _ from 'underscore';
import ProjectionsManager from '../ProjectionsManager';

export default {
    /*------------------------------------------------*/
    /* Helpers for Auto-Running Pipelines on Input/Output
    /*------------------------------------------------*/
    hasInputPipeline(entity){
        return !!this.getPipelineName(entity, 'Input');     
    },

    async input(entity, csvContents){
        let pipelineResult = await this.runEntity(entity, 'Input', csvContents);
        if (pipelineResult.error){
            this.reportError(pipelineResult.error);
            throw new Error(pipelineResult.error);
        }else{
            return pipelineResult.result;
        }
    },

    hasOutputPipeline(entity){
        return !!this.getPipelineName(entity, 'Output');     
    },

    async output(entity, csvContents){
        let pipelineResult = await this.runEntity(entity, 'Output', csvContents);
        if (pipelineResult.error){
            this.reportError(pipelineResult.error);
            throw new Error(pipelineResult.error);
        }else{
            return pipelineResult.result;
        }
    }, 
    
    reportError(error){
        window.setTimeout(() => {
            let suffix = error ? '\n\n' + error.message : '';
            alert(EM.t('pipelines.generalError') + suffix);
        }, 100);
    },

    getPipelineName(entity, direction){
        let directionLabel = direction.toLowerCase() === 'input' ? 'Input' : 'Output';
        let pipelineSettingName = 'Pipelines:' + (entity.displayName || entity.modelName) + ':' + directionLabel;

        let pipelineName = EM.getSetting(pipelineSettingName);
        return pipelineName;   
    },

    async runEntity(entity, direction, csvContents){
        let pipelineName = this.getPipelineName(entity, direction);
        if (pipelineName){
            console.log('Running pipeline: ', pipelineName);
            return await this.runConfig(pipelineName, csvContents);        
        }
        return { result: csvContents };
    },

    async runConfig(pipelineName, csvContents){
        let pipelineConfig = await EM.getConfig(pipelineName, 3);
        if (pipelineConfig && pipelineConfig.Value){                
            return await this.runJson(pipelineConfig.Value, csvContents)
        }else{
            console.log('Pipeline not found: ' + pipelineName);
            return { error: 'Pipeline not found: ' + pipelineName};
        }
    },


    /*------------------------------------------------*/
    /* General Helpers for Pipeline Runs
    /*------------------------------------------------*/
    async runJson(json, csvContents, pipelineHostIn, callback){          
        let pipelineHost = pipelineHostIn;
        if (!pipelineHost){
            let PipelineHost = (await import('./PipelineHost')).default;
            pipelineHost = await PipelineHost.getInstance();
        }
        return await pipelineHost.execute(json, csvContents, callback);
    },

    async runSuperJson(json, csvContents, pipelineHostIn, callback){            
        let pipelineHost = pipelineHostIn;
        if (!pipelineHost){
            let PipelineHost = (await import('./PipelineHost')).default;
            pipelineHost = await PipelineHost.getInstance();
        }
        return await pipelineHost.executeSuper(json, csvContents, callback);
    },

    /*------------------------------------------------*/
    /* UI Helpers for Pipeline Runs
    /*------------------------------------------------*/
    async getFileContents(input){    
        let csvContents = null;
        try {
            csvContents = await File.readCSV(input, true);
        }catch(e){
            console.log(e);
        }
        return csvContents;
    },

    async runFileToFile(input, name, json, pipelineHost, callback){
        let csvContents = await this.getFileContents(input);
        if (csvContents === null)return;                        
        let resultObject = await this.runJson(json, csvContents, pipelineHost, callback);
    
        if (resultObject.error){
            this.reportError(resultObject.error);
        }else{
            CSV.save('Results-' + name, resultObject.result);   
        }
    },

    async runPipelineForUi(isSuper, inputSelections, name, json, pipelineHost, callback){    
        let inputs = [];
        try{
            for(let i = 0, j = inputSelections.length; i < j; i++){
                let selection = inputSelections[i];
                if (selection.type === 'file'){
                    let csvContents = await this.getFileContents(selection.input);
                    if (csvContents === null){
                        alert('File empty');
                        return;
                    }else{
                        inputs.push(csvContents);
                    }
                }else if (selection.type === 'entity'){
                    let csvContents = await this.loadEntityAsTable(selection.value);
                    if (!csvContents)return;
                    inputs.push(csvContents);
                }else if (selection.type === 'entityFile'){
                    let csvContents = await this.loadEntityFileAsTable(selection.value);
                    if (!csvContents)return;
                    inputs.push(csvContents);
                }
            };
        }catch(e){
            alert('Error loading input.', e.message);
            console.error(e);
            return;
        }

        let resultObject = null;
        if (!isSuper){
            resultObject = await this.runJson(json, inputs[0], pipelineHost, callback);
        }else{
            resultObject = await this.runSuperJson(json, inputs, pipelineHost, callback);
        }
        if (resultObject.error){
            this.reportError(resultObject.error);
        }else{
            if (!isSuper){
                CSV.save('Results-' + name, resultObject.result); 
            }else{
                resultObject.result.forEach((result, resultIndex) => {
                    CSV.save('Results-' + name + '-' + (resultIndex + 1), result);  
                });
            }
        }
    },

    async loadEntityAsTable(entityName){
        let entity = EM[entityName];
        if (!entity || !entity.load){
            alert('Entity not found: ' + entityName);
            return;
        }
        await entity.load();
        return await this.entityToTable(entity, true);
    },

    async loadEntityFileAsTable(entityName){
        let entity = EM[entityName];
        if (!entity || !entity.loadDefaultItem){
            alert('Entity not found: ' + entityName);
            return;
        }      
        
        if (entityName === 'projections'){
            let defaultSchedule = await EM.schedules.loadDefaultItem();
            if (!defaultSchedule){
                alert('Default item not found: ' + entityName);
                return;
            }

            let pm = new ProjectionsManager();
            let projections = await pm._projectionsLoad(defaultSchedule);
            return projections.data;
        }else{
            let defaultItem = await entity.loadDefaultItem();
            if (!defaultItem){
                alert('Default item not found: ' + entityName);
                return;
            }
            let defaultItemId = defaultItem[entity.idField];
            await entity.loadFile(defaultItemId);        
            let fileContents = entity.getFile(defaultItemId);                    
            return fileContents.data;
        }        
    },

    async entityToTable(entity){
        let dataset = entity.get();
        if (!dataset)return null;
        if (!dataset[0]){
            return null;
        }

        return await this.dataToTable(dataset);
    },

    async dataToTable(dataset){
        let header = Object.keys(dataset[0]);
        header = header.filter(key => !['DomainId', 'CreatedOn', 'CreatedBy', 'ModifiedOn', 'ModifiedBy'].includes(key) )
        let rows = dataset.map(row => {
            return header.map(column => {
                let value = row[column];
                if (_.isArray(value))value = 'N/A';
                if (_.isObject(value))value = 'N/A';
                return value;
            })
        });   
        
        let csvContents = [ header, ...rows];
        return csvContents;
    },    
}