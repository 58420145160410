/* global EM */
import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsTheme from '../Demand/HighcartsTheme';
import ColorManager from '../../util/ColorManager';
import _ from 'underscore';
import DateGroupValueSet from '../../entities/files/DateGroupValueSet';
import MilestonesUtil from '../../util/MilestonesUtil';
import HighchartsMore from 'highcharts/highcharts-more';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import OfflineExporting from 'highcharts/modules/offline-exporting';


Exporting(Highcharts);
ExportData(Highcharts);
OfflineExporting(Highcharts);

NoDataToDisplay(Highcharts);
HighchartsMore(Highcharts);

export default class BudgetGraph extends Component {
    constructor(props) {
        super(props);
        this.hidden = {};
        this.colors = new ColorManager();
        this.onChartItemClicked = this.onChartItemClicked.bind(this);
    }

    componentDidMount() {
        let self = this;
        Highcharts.theme = HighchartsTheme;
        Highcharts.setOptions(HighchartsTheme);
        Highcharts.addEvent(window, 'beforeprint', function () {
            self.prePrintSize = [self.chart.chartWidth, self.chart.chartHeight];
            self.chart.setSize(self.props.printSize?.[0] || 1000, self.props.printSize?.[1] || 740, false);
        });
        Highcharts.addEvent(window, 'afterprint', function () {
            self.chart.setSize(self.prePrintSize[0], self.prePrintSize[1], true);
        });
        Highcharts.wrap(Highcharts.Legend.prototype, 'getAllItems', function (proceed) {
            var allItems = [];
            this.chart.series.forEach(function (series) {
                if (!series) return;
                var seriesOptions = series && series.options;
                let foundItem = series.options.data.find(item => item[1]);
                if (!!foundItem && series.visible) {
                    allItems = allItems.concat(series.legendItems ||
                        (seriesOptions.legendType === 'point' ?
                            series.data :
                            series));
                } else {
                    return;
                }
            });
            return allItems;
        });
        this.chart = Highcharts.chart('BudgetGraph', {
            chart: {
                zoomType: 'x',
                margin: this.props.margin || [30, 30, 50, 150],
                ignoreHiddenSeries: true,
                backgroundColor: 'transparent',
            },
            lang: {
                noData: EM.t('util.nodata-filters')
            },
            noData: {
                useHTML: true
            },
            loading: {
                labelStyle: {
                    top: '2%'
                }
            },
            boost: {
                useGPUTranslations: true
            },
            credits: { enabled: false },
            title: {
                text: ''
            },
            xAxis: {
                labels: {
                    style: {
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer'
                    },
                    enabled: true,
                    useHTML: true,
                    tickInterval: 1,
                    formatter: function () {
                        let label = this.value;
                        if (label.length > 20) {
                            label = label.substring(0, 20) + '...';
                            return `<span class="tooltipBudget x-axis-label" data-title="${this.value}">${label}</span>`;
                        } else {
                            return `<span class="tooltipBudget x-axis-label" data-title="${this.value}">${label}</span>`;
                        }
                    },
                },
            },
            yAxis: {
                tickLength: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: true,
                    allowOverlap: true,
                    formatter: function () {
                        const value = this.total;
                        if (value >= 1e9) {
                            return `$${(value / 1e9).toFixed(1)}B`;
                        } else if (value >= 1e6) {
                            return `$${(value / 1e6).toFixed(1)}M`;
                        } else if (value >= 1e3) {
                            return `$${(value / 1e3).toFixed(1)}k`;
                        }
                        return `$${value?.toFixed(2)}`;
                    },
                    style: {
                        fontWeight: 'bold',
                        color: 'black'
                    },
                    align: 'right',
                    verticalAlign: 'middle',
                    rotation: 1

                },
                labels: {
                    formatter: function () {
                        if (this.value >= 1e9) {
                            return `$${(this.value / 1e9).toFixed(1)}B`;
                        } else if (this.value >= 1e6) {
                            return `$${(this.value / 1e6).toFixed(1)}M`;
                        } else if (this.value >= 1e3) {
                            return `$${(this.value / 1e3).toFixed(1)}k`;
                        }
                        return `$${this.value}`;
                    },
                    style: {
                        fontWeight: 'bold'
                    }
                }
            },
            tooltip: {
                useHTML: true,
                formatter: this.formatTooltip
            },
            plotOptions: {
                bar: {
                    stacking: 'normal',
                    shadow: false,
                    borderWidth: 0,
                    groupPadding: .075,
                    pointPadding: !this.props.secondary && !this.props.tertiary ? -.4 : .1,
                    crisp: false,
                    maxPointWidth: 20,
                    events: {
                        click: this.onChartItemClicked
                    },
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: .5
                        }
                    }
                }
            },
            legend: {
                enabled: false,
                useHTML: true,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                },
                chartOptions: {
                    chart: {
                        width: 1200,
                        height: 800,
                        margin: [null, null, null, null],
                    },
                    legend: {
                        enabled: true
                    }
                }
            },
            series: this.getAllSeries()
        });

        this.seriesIndex = _.indexBy(this.chart.series, (series) => {
            return series.userOptions.stack + ':' + series.name;
        });

        if (this.props.scenarioMode) this.setData(this.props);

        this.chart.hideNoData();
    }
    updatePlotOptions() {
        const newPlotOptions = {
            ...this.chart.options.plotOptions,
            bar: {
                ...this.chart.options.plotOptions.bar,
                pointPadding: !this.props.secondary && !this.props.tertiary ? -.5 : .1,
                stacking: this.props.secondary || this.props.tertiary ? 'normal' : 'none',
                maxPointWidth: this.props.scenarioMode ? 25 : 20,
            },
        };
        this.chart.update({
            plotOptions: newPlotOptions,
        }, false);
        this.chart.redraw();
    }
    getAllSeries() {
        let series = [];
        let totals = [];
        var roles = this.getGroupingSeries(EM.roles, 'Role');
        var depts = this.getGroupingSeries(EM.departments, 'Department');
        var orgs = this.getGroupingSeries(EM.organizations, 'Organization');
        if (this.props.scenarioMode) {
            totals = [{
                type: 'bar',
                name: 'Total',
                stack: 'primary',
                color: '#888',
                seriesGroup: 'total',
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true
            }, {
                type: 'bar',
                name: 'Total',
                stack: 'secondary',
                color: '#6484a5',
                seriesGroup: 'total',
                linkedTo: ':previous',
                zIndex: 100,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true
            }, {
                type: 'bar',
                name: 'Total',
                stack: 'tertiary',
                color: '#aaa',
                seriesGroup: 'total',
                linkedTo: ':previous',
                zIndex: -1,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true
            }];
        }
        series = [...roles, ...depts, ...orgs, ...totals];
        return series;
    }

    getGroupingSeries(dataset, grouping) {
        let self = this;
        let series = [];
        let names = _.pluck(dataset.get(), ("Name")).sort();
        names.forEach((name, index) => {

            series.push({
                type: 'bar',
                name: name,
                stack: 'primary',
                color: this.props.scenarioMode ? '#888' : self.colors.next(),
                seriesGroup: grouping,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true
            });
            series.push({
                type: 'bar',
                name: name,
                stack: 'secondary',
                color: this.props.scenarioMode ? self.colors.next() : self.colors.current(true),
                seriesGroup: grouping,
                linkedTo: ':previous',
                zIndex: this.props.scenarioMode ? 100 : (index + 1) * 2,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true
            });
            series.push({
                type: 'bar',
                name: name,
                stack: 'tertiary',
                color: this.props.scenarioMode ? '#aaa' : self.colors.current(true, true),
                seriesGroup: grouping,
                linkedTo: ':previous',
                zIndex: this.props.scenarioMode ? - 1 : (index + 1) * 2,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true
            });
        });

        return series;
    }

    formatTooltip() {
        try {
            let dso = this.series.userOptions;
            let date = `<b>${this.x}</b>`;
            let total = this.total ? `Total: $${this.total.toFixed(2)}` : '';
            let dataset = dso.datasetName ? `${dso.stack.capitalize()} Dataset:(${dso.datasetName})<br/>` : '';
            let point = `${this.series.name}: <b>$${this.y ? this.y.toFixed(2) : ''}</b>`;
            return `${date}<br/>${point}<br/>${dataset}${total}`;
        } catch (e) {
            console.log(e);
            return 'Error: ' + this.series.name;
        }
    }

    formatLegendLabel() {
        return this.name;
    }

    onChartItemClicked(event) {
        let point = event.point;
        let series = point.series;
        let type = {
            type: 'bar'
        }
        this.props.onPointSelected(series.userOptions, point.options, type);
    }

    resize() {
        this.chart.reflow();
    }

    onLegendItemClicked(seriesClicked, isFocusMode) {
        let newValue = seriesClicked.visible;
        this.chart.series.forEach((series) => {
            if (isFocusMode) {
                series.update({
                    visible: series.name === seriesClicked.name ? true : false,
                    showInLegend: series.userOptions.stack === 'primary' ? (series.name === seriesClicked.name) : false
                }, false);
            } else {
                if (series.name === seriesClicked.name) {
                    series.update({
                        visible: newValue,
                        showInLegend: series.userOptions.stack === 'primary' ? newValue : false
                    }, false);
                }
            }
        });
        this.onChartItemClickedXAxis()
        this.chart.redraw();

        if (this.props.onSeriesSelected) this.props.onSeriesSelected(seriesClicked, isFocusMode);
    }

    onLegendItemsToggled(visible) {
        this.chart.series.forEach((series, si) => {
            series.update({
                visible: visible,
                showInLegend: series.userOptions.stack === 'primary' ? visible : false
            }, false);
        });
        this.onChartItemClickedXAxis()
        this.chart.redraw();
    }

    onLegendItemVisibilitySet(item) {
        this.chart.series.forEach((series) => {
            let isVisible = !item ? false : series.name === item;
            series.update({
                visible: !!isVisible,
                showInLegend: !!isVisible
            }, false);
        });
        this.onChartItemClickedXAxis()
        this.chart.redraw();
    }

    onExport(type) {
        let exOpts = {
            chart: {
                backgroundColor: 'white'
            }
        };
        if (type === 'png') {
            this.chart.exportChartLocal({ type: 'image/png' }, exOpts);
        }
        if (type === 'svg') {
            this.chart.exportChartLocal({ type: 'image/svg+xml' }, exOpts);
        }
        if (type === 'print') {
            this.chart.print(null, exOpts);
        }
    }

    showLoading() {
        this.chart.showLoading();
    }

    hideLoading() {
        this.chart.hideLoading();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.primary !== this.props.primary ||
            prevProps.secondary !== this.props.secondary ||
            prevProps.tertiary !== this.props.tertiary ||
            prevProps.preferences !== this.props.preferences ||
            prevProps.plotLines !== this.props.plotLines ||
            prevProps.selectedDataOption !== this.props.selectedDataOption
        ) {
            this.showLoading();
            let shouldZoomOut = prevProps.preferences.begin !== this.props.preferences.begin || prevProps.preferences.end !== this.props.preferences.end;
            window.setTimeout(() => {
                this.setData(this.props, shouldZoomOut);
                this.updatePlotOptions();
                this.hideLoading();
            }, 10);
        }
    }
    setTotalValueSet(data){
      for (const key in data) {
        const totalSet = data[key]?.total?.set;

        if (totalSet) {
            let totalValue = 0;
            for (const subKey in totalSet) {
                totalValue += totalSet[subKey].reduce((acc, value) => acc + value, 0);
            }
            data[key].totalValueSet = totalValue;
        }
    }
    }

    sortByWorkItemAndAmount(data){
      let sortedData = null;
      if (this.props.selectedDataOption.label === "Budget Amount") {
          this.props.selectedDataOption.isAscending ?
          sortedData = Object.entries(data).sort((a, b) => a[1].totalValueSet - b[1].totalValueSet) //ascending order by amount
              : sortedData = Object.entries(data).sort((a, b) => b[1].totalValueSet - a[1].totalValueSet); //descending order by amount
      }
      if (this.props.selectedDataOption.label === "Work Item") {
          this.props.selectedDataOption.isAscending ?
          sortedData = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0])) //ascending order by workItem
              : sortedData = Object.entries(data).sort((a, b) => b[0].localeCompare(a[0])); //descending order by workitem
      }
      return sortedData
    }
    

    async setData(props, shouldZoomOut) {
        let self = this
        let preferences = props.preferences;
        if (!props.primary) {
            this.chart.series.forEach((series) => {
                series.setData([], false);
            });
            this.chart.redraw();
            return;
        }

        EM.time('Total Graph Time');
        let primaryData = await props.primary.getSummaryBudget(preferences, 'primary');
        this.setTotalValueSet(primaryData);
        let sortedPrimaryData = this.sortByWorkItemAndAmount(primaryData)

        primaryData = Object.fromEntries(sortedPrimaryData);
        let secondaryData = null;
        let tertiaryData = null;

        if (props.secondary) {
            secondaryData = await props.secondary.getSummaryBudget(preferences, 'secondary', this.props.scenarioMode);
            this.setTotalValueSet(secondaryData);
            let sortedSecondaryData = this.sortByWorkItemAndAmount(secondaryData)
            secondaryData = Object.fromEntries(sortedSecondaryData)
        }
        if (props.tertiary) {
            tertiaryData = await props.tertiary.getSummaryBudget(preferences, 'tertiary', this.props.scenarioMode);
            this.setTotalValueSet(tertiaryData);
            let sortedTertiaryData = this.sortByWorkItemAndAmount(tertiaryData)
            tertiaryData = Object.fromEntries(sortedTertiaryData)
        }

        const allIntializeKey = new Set([
            ...Object.keys(primaryData),
            ...Object.keys(secondaryData || {}),
            ...Object.keys(tertiaryData || {}),
        ]);

        var VisibleLegend = {};
        this.chart.userOptions?.series?.forEach((series) => {
            if(series.stack === "primary"){
                const missingLegendkey = `${series.seriesGroup}:${series.name}`;
                VisibleLegend[missingLegendkey] = null
            }
        });
        allIntializeKey?.forEach(key => {
            if (!primaryData.hasOwnProperty(key)) {
                var primaryTotal = new DateGroupValueSet("Month");
                primaryTotal.pushBudget(key, 0);
                primaryData[key] = {
                    ...VisibleLegend,
                    date: { ts: key },
                    totalValueSet: null,
                    total: primaryTotal,
                };
            }
            if (secondaryData && !secondaryData.hasOwnProperty(key)) {
                var secondaryTotal = new DateGroupValueSet("Month");
                secondaryTotal.pushBudget(key, 0)
                secondaryData[key] = {
                    ...VisibleLegend,
                    date: { ts: key },
                    totalValueSet: null,
                    total: secondaryTotal,
                };
            }
            if (tertiaryData && !tertiaryData.hasOwnProperty(key)) {
                var tertiaryTotal = new DateGroupValueSet("Month");
                tertiaryTotal.pushBudget(key, 0)
                tertiaryData[key] = {
                    ...VisibleLegend,
                    date: { ts: key },
                    totalValueSet: null,
                    total: tertiaryTotal,
                };
            }
        });
        let seriesData = this.transformDatasetsForGraphing(primaryData, secondaryData, tertiaryData, preferences);

        var allKeys = Array.from(new Set([
            ...Object.keys(primaryData),
        ]));
        this.chart.xAxis[0].update({
            categories: allKeys,
        });

        await this.chart.xAxis[0].update({
            categories: allKeys,
        });

        if (Object.keys(seriesData).length === 0) {
            this.setState({ nodata: true });
        }

        let chartTypes = {
            primary: preferences.primary.chartType.toLowerCase(),
            secondary: preferences.secondary.chartType.toLowerCase(),
            tertiary: preferences.tertiary.chartType.toLowerCase()
        };

        for (var j = 0, rowLen = this.chart.series.length; j < rowLen; j++) {
            let series = this.chart.series[j];
            let stack = series.userOptions.stack;
            let group = series.userOptions.seriesGroup;
            let seriesKey = stack + ':' + group + ':' + series.name;
            let stackObj = props[stack];
            if (group === 'total') seriesKey = stack + ':total';

            series.update({
                type: chartTypes[stack],
                dateGrouping: preferences.dateGrouping,
                datasetType: stackObj ? stackObj.getTypeName() : null,
                datasetName: stackObj ? stackObj.name : null,
                data: seriesData[seriesKey] || []
            }, false);
        }

        this.onChartItemClickedXAxis();
        if (preferences.series) this.onLegendItemVisibilitySet(preferences.series);
        this.setPlotLines(preferences);
        this.chart.redraw(false);
        if (shouldZoomOut || this.props.scenarioMode) {
            this.chart.zoomOut();
        }

        EM.timeEnd('Total Graph Time');
    }

    onChartItemClickedXAxis() {
        let self = this;
        setTimeout(() => {
            const labelElements = document.querySelectorAll('.x-axis-label'); labelElements.forEach(label => {
                label.addEventListener('click', function () {
                    const clickedLabel = this.getAttribute('data-title');
                    const userOptions = self.chart.series.map((s) => s.userOptions)
                    const labelValue = clickedLabel; let type = {
                        type: 'workitem'
                    }
                    let option = {
                        name: labelValue
                    }
                    self.props.onPointSelected(userOptions[0], option, type);
                });
            });
        }, 600);
    }

    transformDatasetsForGraphing(primaryData, secondaryData, tertiaryData, preferences) {
        let series = {};
        let monthKeyMasterSet;
        if (this.props.scenarioMode) {
            let allKeys = [];
            Array.prototype.push.apply(allKeys, Object.keys(primaryData));
            if (secondaryData) {
                Array.prototype.push.apply(allKeys, Object.keys(secondaryData));
            }
            monthKeyMasterSet = _.uniq(allKeys).sort((a, b) => a - b);
        } else {
            let allKeys = [];
            Array.prototype.push.apply(allKeys, Object.keys(primaryData));
            if (secondaryData) {
                Array.prototype.push.apply(allKeys, Object.keys(secondaryData));
            }
            if (tertiaryData) {
                Array.prototype.push.apply(allKeys, Object.keys(tertiaryData));
            }
            monthKeyMasterSet = _.uniq(allKeys).sort((a, b) => a - b);
        }
        monthKeyMasterSet.forEach((monthKey, mkIndex) => {
            if (monthKey in primaryData) {
                let monthGroup = primaryData[monthKey];

                Object.keys(monthGroup).forEach((roleKey) => {
                    if (roleKey === 'date') return;
                    let data = monthGroup[roleKey];
                    this.addSingleSeries(monthGroup, series, 'primary:', roleKey, data);
                });

            }
        });
        if (secondaryData) {
            monthKeyMasterSet.forEach((monthKey, mkIndex) => {
                if (monthKey in secondaryData) {
                    let monthGroup = secondaryData[monthKey];

                    Object.keys(monthGroup).forEach((roleKey) => {
                        if (roleKey === 'date') return;
                        let data = monthGroup[roleKey];
                        this.addSingleSeries(monthGroup, series, 'secondary:', roleKey, data);
                    });
                }
            });
        }


        if (tertiaryData) {
            monthKeyMasterSet.forEach((monthKey, mkIndex) => {
                if (monthKey in tertiaryData) {
                    let monthGroup = tertiaryData[monthKey];
                    if (!monthGroup) return monthGroup = tertiaryData[monthKey];;

                    Object.keys(monthGroup).forEach((roleKey) => {
                        if (roleKey === 'date') return;
                        let data = monthGroup[roleKey];
                        this.addSingleSeries(monthGroup, series, 'tertiary:', roleKey, data);
                    });
                }
            });
        }

        return series;
    }

    addSingleSeries(monthGroup, series, prefix, roleKey, fallbackData) {
        let key = prefix + roleKey;
        if (!series[key]) series[key] = [];
        let data = monthGroup[roleKey];
        let dt = monthGroup.date.ts ? monthGroup.date.ts : monthGroup.date.ts;
        series[key].push([dt, data ? (data.set ? DateGroupValueSet.from(data).get() : fallbackData) : null]);
    }

    async setPlotLines(preferences) {
        let wi = null;
        let wis = preferences.get('workitem');
        if (wis && wis.length === 1) wi = wis[0];

        window.setTimeout(async () => {
            this.chart.xAxis[0].update({
                plotLines: preferences.plotLines ? await MilestonesUtil.getMilestonesForGraphing(wi) : [],
            }, true);
        }, 0);
    }

    render() {
        return (
            <div className="demand-graph" id="BudgetGraph" />
        );
    }
}