import PreferenceManager from './PreferenceManager';

export default class StaffingWorkItemPreferences{
    constructor(name, inst, page){
        this.name = name;
        this.preferences = new PreferenceManager(page || 'staffing-workitem');
        this.group = this.preferences.asGroup('preferences'); 
        this.inst = inst ? inst : (this.group(name) || {});             
    }

    set(name, value){
        let newOptions = Object.assign({}, this.inst, { [name]: value });
        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete newOptions[name];
            }
        }    
        
        this.inst = newOptions;
        this.group(this.name, newOptions);
    }

    get(name, defaultValue){
        let val = this.inst[name] || null;
        return val || defaultValue;
    }

    clone(){
        return new StaffingWorkItemPreferences(this.name, this.inst);
    }

    //////////Helpers for specific fields
    get begin() {
        return this.get('begin');
    }
    set begin(value) {
        this.set('begin', value);
    }    

    get end() {
        return this.get('end');
    }
    set end(value) {
        this.set('end', value);
    }    
        
    get grouping() {
        return this.get('grouping', null);
    }
    set grouping(value) {
        this.set('grouping', value);
    } 
    
    get onlyActive() {
        return this.get('onlyActive', false);
    }
    set onlyActive(value) {
        this.set('onlyActive', value);
    }  

    get focusRoles() {
        return this.get('focusRoles', null);
    }
    set focusRoles(value) {
        this.set('focusRoles', value);
    } 
}