/* global EM */
import React, { Component } from 'react';
import EntityTable from '../EntityTables/EntityTable';
import NotificationDialog from '../NotificationDialog';

export default class AssignmentApprovalList extends Component {
    constructor(props){
        super(props);

        this.state = {
            notificationsModalOpen: false
        }

        this.tblRef = React.createRef();
        this.requestButtonRef = React.createRef();

        this.makeUpdates = this.makeUpdates.bind(this);
    }

    getMessageDetails(){
        let url = this.props.url;
        let unit = this.props.unit;
        return {
            subject: 'Review Requested on ' + unit,
            message: 
                `<p>${EM.me.Name} has requested your review on the assignments associated with ${unit}.</p>
                <blockquote>####</blockquote>
                <p>Please click the link below to begin your review: </p>
                <a href="${url}">${url}</a>`
        }
    } 

    getAssignmentColumns() {
        const entity = EM.assignments;
        let orgLookup = (cell, row) => {
            let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
            let deptId = EM.roles.lookupValue(roleId, 'DepartmentId');
            let orgId = EM.departments.lookupValue(deptId, 'OrganizationId');                        
            return EM.organizations.lookupValue(orgId);    
        };
        let deptLookup = (cell, row) => {
            let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
            let deptId = EM.roles.lookupValue(roleId, 'DepartmentId');
            return EM.departments.lookupValue(deptId);                        
        };
        let roleLookup = (cell, row) => {
            let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
            return EM.roles.lookupValue(roleId);                        
        };
        let baseCols = [
            {
                dataField: 'organization',
                text: entity.columns('organization'),
                isReference: true,
                formatter: orgLookup,
                sortValue: orgLookup
            },{
                dataField: 'department',
                text: entity.columns('department'),
                isReference: true,
                formatter: deptLookup,
                sortValue: deptLookup
            },{                
                dataField: 'role',
                text: entity.columns('role'),
                isReference: true,
                editable: false,
                formatter: roleLookup,
                sortValue: roleLookup
            },
            {
                dataField: 'EmployeeId',
                text: entity.columns('employee'),
                fromEntity: EM.employees,
                editable: false
            },
            { 
                dataField: 'ActivityName', 
                text: entity.columns('activity'),
                editable: false
            },           
            { 
                dataField: 'BeginDate', 
                text: entity.columns('begin'), 
                asDate: true,     
                width: 125,       
                editable: false                                  
            },
            { 
                dataField: 'EndDate', 
                text: entity.columns('end'),                
                asDate: true,                    
                width: 125,   
                editable: false
            },            
            {
                dataField: 'Value',
                text: entity.columns('value'),
                asPercentage: true,
                csvType: Number,
                width: 100,
                editable: false               
            },
            { 
                dataField: 'Status', 
                width: 100,
                text: entity.columns('status'),
                asAssignmentStatus: true,
            }];

        if (this.props.showWorkItem){
            baseCols.splice(4, 0, {
                dataField: 'WorkItemName', 
                text: entity.columns('workItem'),
                editable: false
            });
        } 

        return baseCols;
    }   

    makeUpdates(targetButton) {
        let selectedRows = this.tblRef.current.selectedRows;
        if (selectedRows.length === 0)return;

        let newAssignments = [];
        selectedRows.forEach(item => {
            if (item.Status !== 'Locked'){
                newAssignments.push(Object.assign({}, item, { Status: "Locked" }));
            }
        });

        if (newAssignments.length === 0)return;
        window.setTimeout(() => {
            EM.assignments.import(newAssignments).then(() => {
                this.props.onBulkUpdate(newAssignments);
            });
        }, 100);
    }

    render() {
        let columns = this.getAssignmentColumns();
        let assignments = this.props.assignments;
        let messageDetails = this.getMessageDetails();
        let reviewMode = false; //TODO: Fix this?
        return (
            <div className={this.props.className || ''}>
                { assignments ? 
                    <div className="clearfix mb-2">
                        { EM.isDomainEditor() && !reviewMode ? 
                            <button className="float-left btn btn-sm btn-secondary" ref={this.requestButtonRef} onClick={(event) => {                                
                                this.setState({ notificationsModalOpen: true });
                            }}>Request Review</button>
                        : null } 

                        {EM.isDomainResourceManager()?                            
                            <button className="float-right btn btn-sm btn-primary" onClick={this.makeUpdates}>Approve Assignments</button>
                        : null }
                    </div>
                : null }    
                { assignments ? 
                    <EntityTable
                        search={false}
                        hideSearch={true}
                        hideClearAll={true}
                        hideImportExport={true}
                        hideAddRecord={true}
                        hideTotals={true}
                        neverShowId={true}
                        entity={EM.assignments}
                        data={assignments}
                        columns={columns}
                        hideToolbar={true}
                        suppressMeta={true}
                        beforeUpdate={(oldItem, newItem) => {
                            this.props.onSingleUpdate(newItem);
                        }}
                        ref={this.tblRef} 
                    />
                : null }
                <NotificationDialog 
                    isOpen={this.state.notificationsModalOpen}
                    title={'Request Review on ' + this.props.unit}
                    subject={messageDetails.subject}
                    messageTemplate={messageDetails.message}
                    onClose={() => this.setState({ notificationsModalOpen: false })}
                    onSend={() => {
                        if (this.requestButtonRef.current)this.requestButtonRef.current.disabled = true;
                        this.setState({ notificationsModalOpen: false });
                    }}
                />
            </div>
        );
    }
};