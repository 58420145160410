import FileTypeBase from './FileTypeBase';
import FileSaver from 'file-saver';

export default class NotesFile extends FileTypeBase {
    static checkFileType(type) {
        let invalidTypes = [
            'application/x-msdownload',
            'application/x-bsh',
            'application/x-sh',
            'application/x-shar',
            'text/x-script.sh',
            "application/x-dosexec",
            "application/x-msdownload",
            "application/exe",
            "application/x-exe",
            "application/dos-exe",
            "vms/exe",
            "application/x-winexe",
            "application/msdos-windows",
            "application/x-msdos-program",
        ]
        if (invalidTypes.indexOf(type) > -1) return false;

        return true;
    }

    download() {
        let blob = this.dataURItoBlob(this.data.contents);
        FileSaver.saveAs(blob, this.data.name);
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }
}