/* eslint eqeqeq: 0 */
/* global EM */
import Dates from './Dates';

export default {
    getHeaderWidth(){
        return 320; //should be multiples of "standardCellWidth" css prop
    },

    getPercentage(assignmentValue, projectionValue, decimals){
        if (assignmentValue === 0 || projectionValue === 0)return 0;
        return ((assignmentValue / projectionValue) * 100).toFixed(decimals || 0);
    },

    getTooltip(assignmentValue, projectionValue, decimals, asHTML){
        let percentage = this.getPercentage(assignmentValue, projectionValue, decimals || 2);
        return assignmentValue.toFixed(decimals || 2) + ' / ' + projectionValue.toFixed(decimals || 2) + ' = ' + percentage + '%';
    },

    getMarkupRatio(assignmentValue, projectionValue, decimals){
        let percentage = this.getPercentage(assignmentValue, projectionValue, decimals || 0);
        return (
            <>
                <span>{assignmentValue.toFixed(decimals || 2) + ' / ' + projectionValue.toFixed(decimals || 2) + ' = '}</span>
                <b>{ percentage + '%'}</b>
            </>
        );
    },    

    getColorClass(value, allocation, requireUnderValue){
        let cls = '';
        let under = parseFloat(EM.getSetting('StaffingUnderAllocated') || 1.0);
        let over = parseFloat(EM.getSetting('StaffingOverAllocated') || 1.0);

        if (value > (over * allocation)){
            cls = 'over';
        }else if (value < (under * allocation) && (requireUnderValue ? (value > 0 || allocation > 0) : true)){
            cls = 'under';
        }else if (value <=0){
            cls = 'none';
        }else{
            cls = 'at';
        }
        return cls;
    },

    processAssignments(assignmentsSource) {
        if (!assignmentsSource)return null;
        let assignments = {};
        assignmentsSource.forEach((assignmentFromSource) => {
            let assignment = this.processSingleAssignment(assignmentFromSource);
            assignments[assignment.AssignmentId] = assignment;
        });

        return assignments;     
    },

    processSingleAssignment(assignmentFromSource) {
        let assignment = Object.assign({}, assignmentFromSource, { WorkItemName: assignmentFromSource.WorkItemName.trim() });
        assignment.BeginDateObj = Dates.fromISO(assignment.BeginDate);
        assignment.BeginDateStr = Dates.toMonthYearStrShort(assignment.BeginDateObj);
        assignment.EndDateObj = Dates.fromISO(assignment.EndDate);
        assignment.EndDateStr = Dates.toMonthYearStrShort(assignment.EndDateObj);

        let range = Dates.getArrayOfMonths(assignment.BeginDateObj, assignment.EndDateObj, true);
        assignment.range = range;
        assignment.dateKeys = [];

        let monthAr = assignment.range.months || assignment.range.dates;
        monthAr.forEach((month, monthIndex) => {
            assignment.dateKeys.push(range.beginIndex + monthIndex);
        });

        return assignment;
    },

    /*Alternate implementation that accepts a pre-filtered employee list. Alternate to reduce potential regressions. -JS*/
    processAssignmentsWithPreFilteredEmployees(assignmentsSource, preFilteredEmployees) {
        if (!assignmentsSource)return null;
        let employeeIds = preFilteredEmployees ? preFilteredEmployees.map(item => item.EmployeeId) : [];
        let assignments = {};
        assignmentsSource.forEach((assignmentFromSource) => {
            if (preFilteredEmployees && employeeIds.indexOf(assignmentFromSource.EmployeeId) === -1)return
            let assignment = this.processSingleAssignment(assignmentFromSource);
            assignments[assignment.AssignmentId] = assignment;
        });

        return assignments;     
    },


    async getEmployeeAvailability(dateRange, roleIdToFilter, preFilteredEmployees){
        const entityList = [EM.assignments, EM.roles];
        if (!preFilteredEmployees){
            entityList.push(EM.employees);
        }
        await EM.loadEntities(entityList);

        let assignments = this.processAssignmentsWithPreFilteredEmployees(EM.assignments.get(), preFilteredEmployees); 
        if (!assignments)return {};

        let assignmentGroups = Object.values(assignments).groupBy('EmployeeId');
        let availability = {};
        let employees = preFilteredEmployees || EM.employees.get();        

        employees.forEach((employee, groupIndex) => {
            if (roleIdToFilter){
                if (employee.RoleId !== roleIdToFilter.toString())return;
            }

            //This is used to support Role limits placed on child domains - JS
            let role = EM.roles.byId(employee.RoleId);
            if (!role)return; 

            let employeeId = employee.EmployeeId;
            let employeeAssignments = assignmentGroups[employeeId];           

            let employeeDateGroups = this.getAssignmentDateGroups(employeeAssignments);

            let total = 0;
            let monthAr = dateRange.months || dateRange.dates;

            let months = monthAr.map((month, monIndex) => {   
                let dateKey = dateRange.beginIndex + monIndex;
                let value = employeeDateGroups[dateKey] || 0;
                let cls = this.getColorClass(value, (employee.TimeAllocation || 1));
                total += value;
                return { value: value, cls, dt: Dates.toMonthYearStrShort(month) };
            });

            let average = total / months.length;
            let cls = this.getColorClass(average, (employee.TimeAllocation || 1));
            availability[employeeId] = { average, cls, months };            
        });

        return availability;
    },

    async getLimitedEmployeeAvailability(dateRange, rowLimit){
        const entityList = [EM.employees, EM.assignments, EM.roles];
        await EM.loadEntities(entityList);

        let availability = {};
        let employees = EM.employees.get();        
        let assignments = EM.assignments.get();
        let rowCount = 0;

        employees.forEach((employee, groupIndex) => {
            if (rowCount >= rowLimit)return;

            //This is used to support Role limits placed on child domains - JS
            let role = EM.roles.byId(employee.RoleId);
            if (!role)return; 

            let employeeId = employee.EmployeeId;
            let rawEmployeeAssignments = assignments.filter(assignment => assignment.EmployeeId === employeeId);
            let processedEmployeeAssignments = this.processAssignments(rawEmployeeAssignments);

            let employeeDateGroups = this.getAssignmentDateGroups(Object.values(processedEmployeeAssignments));

            let total = 0;
            let monthAr = dateRange.months || dateRange.dates;

            let months = monthAr.map((month, monIndex) => {   
                let dateKey = dateRange.beginIndex + monIndex;
                let value = employeeDateGroups[dateKey] || 0;
                let cls = this.getColorClass(value, (employee.TimeAllocation || 1));
                total += value;
                return { value: value, cls, dt: Dates.toMonthYearStrShort(month) };
            });

            let average = total / months.length;
            let cls = this.getColorClass(average, (employee.TimeAllocation || 1));
            availability[employeeId] = { average, cls, months };            
            if (total < 100){
                rowCount++;
            }
        });

        return availability;
    },

    getAssignmentDateGroups(assignments){
        let assignmentDateGroups = {};
        if (assignments){
            assignments.forEach((assignment) => {
                assignment.dateKeys.forEach(dateKey => {
                    if (!assignmentDateGroups[dateKey])assignmentDateGroups[dateKey] = 0;
                    assignmentDateGroups[dateKey] += assignment.Value;
                })
            });
        }
        return assignmentDateGroups;
    },

    async getStaffingPercentage(projections, filterIndexes){
        await EM.loadEntities([EM.employees, EM.assignments]);
        let assignments = this.processAssignments(EM.assignments.get()); 

        let workItemName = projections[0][filterIndexes.workitem].trim();                
        let assignmentsByWorkItem = Object.values(assignments).filter(item => item.WorkItemName === workItemName);
    
        let output = {};
        projections.forEach((projection, pI) => {  
            output[pI] = 0;

            let range = Dates.getArrayOfMonths(projection[filterIndexes.begin], projection[filterIndexes.end], true);             
            let dateKeys = [];        
            range.dates.forEach((month, monthIndex) => {
                dateKeys.push(range.beginIndex + monthIndex);
            });
            
            let activityId = projection[filterIndexes.activity];
            if (!activityId)return;

            let activityName = EM.activities.lookupValue(activityId);
            if (!activityName)return;

            let roleId = projection[filterIndexes.roleId];
            if (!roleId)return;        

            let assignmentsByActivityAndRole = assignmentsByWorkItem.filter(item => {
                let employee = EM.employees.byId(item.EmployeeId);
                if (!employee)return false;

                return item.ActivityName === activityName && employee.RoleId == roleId;
            });
            if (assignmentsByActivityAndRole.length <= 0)return;

            let assignmentDateGroups = this.getAssignmentDateGroups(assignmentsByActivityAndRole);

            let total = 0;            
            let months = dateKeys.map(dateKey => {
                let value = Math.fround(assignmentDateGroups[dateKey] || 0);          
                let cls = this.getColorClass(value, projection[filterIndexes.value]);
                total += value;
                return { value: value, cls }; 
            });

            let average = total / dateKeys.length;
            let cls = this.getColorClass(average, projection[filterIndexes.value]);
            output[pI] = { average, cls, months, range };   
        });  

        return Object.values(output);
    }
}