import * as types from '../actionTypes';

export default function files(state = {}, action) {
    switch (action.type) {
        case types.LOAD_FILE:
            return Object.assign({}, state, { [ action.key ] : action.data })
        case types.CLEAR_FILES:
            return {}          
        case types.FILE_ERROR:
            return Object.assign({}, state, { [ action.key ] : null })
        default:
            return state;
    }
}