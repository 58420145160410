/* global EM */
import React, { Component } from 'react';
import Routes from '../app/Routes';
import { NavLink } from "react-router-dom";
import reportListing from '../entities/reports/reportListing';

export default class ReportListingSection extends Component {
    render() {
        let domain = this.props.domain ? this.props.domain.Name : '';
        let name = this.props.name;
        let reportKeys = Object.keys(reportListing[name]);
        return (
            <div className="report-listing">
                <h5>{this.props.title}</h5>
                <ul>
                    { reportKeys.map((reportKey) => {
                        let reportGroup = reportListing[name][reportKey];
                        let groupKeys = Object.keys(reportGroup);
                        return (
                            <li key={reportKey}>
                                <h6><i className={reportGroup.config.icon} />{EM.t('reports.' + name + '.' + reportKey + '.title')}</h6>
                                <div>
                                    
                                    { groupKeys.map((variantKey) => {                                    
                                        if (reportGroup[variantKey].hidden)return null;
                                        return (
                                            <NavLink key={variantKey} className="variant" to={Routes.compose(Routes.client.demandReport, { DomainName: domain }, { report: reportKey, variant: variantKey })}>
                                                <span>{variantKey === 'config' ? EM.t('reports.' + name + '.' + reportKey + '.title') : EM.t('reports.' + name + '.' + reportKey + '.' + variantKey)}</span>
                                            </NavLink>
                                        );
                                    })}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
};