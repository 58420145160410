/* global EM */
import reportListing from './reportListing';
import Routes from '../../app/Routes';

export default class ReportConfigurtion{
    constructor(){
        this.key = null;

        this.name = null;
        this.icon = 'fas fa-file-alt';
        this.baseConfig = null;
        this.options = null;

        this.source = null;
        this.storedConfiguration = null;

        this.defaultReportOptions = {
            showChart: true
        };
    }

    asJSON(){
        let tmp = {
            base: this.baseConfig.inst,
            options: this.options,
            key: this.key,
            name: this.name
        };
        return JSON.stringify(tmp);
    }

    static fromQueryString(preferenceType, possibleTypes){
        let qs = Routes.parseQuery();
        let config = new ReportConfigurtion();

        if (qs.report){
            //load report based on QS variable
            config.key = qs.report;
            config.name = EM.t(`reports.demand.${config.key}.title`);
            let variantKey = qs.variant;
            let reportConfigBase = reportListing.demand[config.key].config.base;
            let reportConfig = Object.assign({}, reportConfigBase);
            if (variantKey){
                let variantConfig = reportListing.demand[config.key][variantKey];
                reportConfig = Object.assign(reportConfig, variantConfig);
            }
            config.baseConfig = new preferenceType(config.key, reportConfig);
            config.options = Object.assign({}, config.defaultReportOptions, reportListing.demand[config.key].config.options);
            config.source = 'default';
            config.icon = reportListing.demand[config.key].config.icon;
        }else if (qs.custom){
            //load report based on a saved preference set (directly from visualization)
            config.key = qs.custom;
            config.name = qs.custom === 'default' ? EM.t('demand.default') : qs.custom;
            let originalPrefs = new preferenceType(qs.custom).inst;
            let reportConfig = Object.assign({}, originalPrefs, { focusMode: true });
            config.baseConfig = new preferenceType(config.key, reportConfig);
            config.options = Object.assign({}, config.defaultReportOptions, EM.crossPage);
            config.source = 'custom';
        }else if (qs.configuration){
            config = ReportConfigurtion.fromConfiguration(qs.configuration, preferenceType);           
        }

        return config;
    }

    static fromConfiguration(id, preferenceType){
        let config = new ReportConfigurtion();

        let storedConfig = EM.configurations.byId(id);
        if (!storedConfig){
            throw new Error('Configuration not found', id);
        }
        config.key = storedConfig.ConfigurationId;
        config.name = storedConfig.Name;            

        let tmp = JSON.parse(storedConfig.Value);
        config.baseConfig = new preferenceType(storedConfig.key, tmp.base);
        config.options = tmp.options;
        config.source = 'configuration';
        config.storedConfiguration = storedConfig;
        
        if (storedConfig.Styling){
            let styleObj = JSON.parse(storedConfig.Styling);
            if (styleObj && styleObj.icon)config.icon = styleObj.icon;
        }

        return config;
    } 
}