/* global EM */
import React, { Component } from 'react';

export default class CopyableValue extends Component {
    copyTextToClipboard() {
        let textArea = this.refs.textarea;
        textArea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div className={'copyable-value ' + this.props.extraClasses}  onClick={() => {
                if (this.props.hideButton)this.copyTextToClipboard();
            }}>
                <div>{this.props.children}</div>
                {!this.props.hideButton ? 
                    <button className="btn btn-link" title={EM.t('util.copy')} onClick={() => {
                        this.copyTextToClipboard();
                    }}>
                        <i className="fa fa-copy" />
                    </button>
                : null }
                <textarea ref="textarea" value={this.props.value || this.props.children} readOnly/>
            </div>
        );
    };
}
