/* global EM */
import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PageTitle from '../components/PageTitle';
import DemandToolsPanel from '../components/Demand/DemandToolsPanel';
import DemandGraphLegend from '../components/Demand/DemandGraphLegend';
import DemandGraph from '../components/Demand/DemandGraph';
import DemandModal from '../components/Demand/DemandModal';
import DemandReportOptionsDialog from '../components/Demand/DemandReportOptionsDialog';
import DemandPreferences from '../entities/preferences/DemandPreferences';
import ProjectionsManager from '../entities/ProjectionsManager';
import Routes from '../app/Routes';

class Demand extends Component {
    constructor(props){
        super(props);
        this._isMounted = false;
        this.defaultPrefs = new DemandPreferences('default');
        this.areToolsOpenPref = this.defaultPrefs.preferences.asFlag('tools-panel', 'open');        
        this.PM = new ProjectionsManager(this);        

        this.state = {
            preferences: this.defaultPrefs,
            areToolsOpen: this.areToolsOpenPref(),
            filterValues: null,
            primary: null,
            secondary: null,
            tertiary: null,
            selectedPoint: false,
            reportModalOpen: false
        }

        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onPrefSetChanged = this.onPrefSetChanged.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onPointSelected = this.onPointSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onReportModalClose = this.onReportModalClose.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
        this.getPrimaryDatasetDateRange = this.getPrimaryDatasetDateRange.bind(this);
        this.demandGraphRef = React.createRef();
    }

    componentDidMount(){
        this._isMounted = true;

        let self = this;      
        EM.loadEntities([EM.roles, EM.organizations, EM.departments, EM.activities, EM.schedules, EM.headcounts, EM.actuals]).then(() => {
            self.onLoadInitial();
        });
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    getPrimaryDatasetDateRange(){
        if (this.state.primary){
            return this.state.primary.getMaxDateRange();
        }
        return null;
    }

    onLoadInitial(){
        let self = this;
        //Load the the default schedule file and its newest set of successful projections
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {            
            if (defaultSchedule){
                this.defaultSchedule = defaultSchedule;
                try{
                    this.PM.loadDatasetsFromPrefs(self.state.preferences, this.defaultSchedule.ScheduleId).then(datasets => {
                        this.setState(datasets);
                    });
                }catch(err){
                    EM.warn(err);
                }

                EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                    let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                    if (fileContents){
                        let fvs = fileContents.getFilterValues(true);                        
                        this.setState({ filterValues: fvs });
                    }
                });       
            }else{
                this.setState({ nodata: true });
            }  
        });
    }    

    onFilterToggle(){
        this.areToolsOpenPref(!this.state.areToolsOpen);
        this.setState({ areToolsOpen: !this.state.areToolsOpen });
        EM.triggerWindowResize();
    }

    onGenerateReport(options){
        let currentPreferencesName = this.state.preferences.name;
        let reportAddress = Routes.compose(Routes.client.demandReport, { DomainName: this.props.domain.Name }, { custom: currentPreferencesName });
        EM.crossPage = options;
        this.props.history.push(reportAddress);
    }

    onPrefSetChanged(newPreferences){
        let dg = this.demandGraphRef.current;
        if (dg)dg.showLoading();
        window.setTimeout(() => {        
            try{
                this.PM.loadDatasetsFromPrefs(newPreferences, this.defaultSchedule.ScheduleId).then(datasets => {
                    this.setState(datasets);
                });
                this.setState({ preferences: newPreferences });
            }catch(err){
                EM.warn(err);
            }        
        }, 10); //This is explicitly set to 10ms since highcharts loading indicator takes a few to show
    }

    onExport(type){
        let dg = this.demandGraphRef.current;
        if (dg)dg.onExport(type);
    }

    onModalClose(){
        this.setState({ selectedPoint: null });
    }

    onReportModalClose(){
        this.setState({ reportModalOpen: false });
    }

    onPointSelected(series, point){
        this.setState({ selectedPoint: { series, point }});
    }

    onLegendItemClicked(seriesClicked, isFocusMode){
        this.demandGraphRef.current.onLegendItemClicked(seriesClicked, isFocusMode);
    }

    onLegendItemsToggled(visible){
        this.demandGraphRef.current.onLegendItemsToggled(visible);
    }

    render() {        
        let readyToRender = EM.allLoaded(EM.activities, EM.roles, EM.organizations, EM.departments, EM.schedules, EM.headcounts, EM.actuals);
        if (!readyToRender)return null;        
        return (
            <div key="contents" className={"page page-visualization container-fluid " + (this.state.areToolsOpen?'tools-mode':'')}>                              
                <PageTitle title={EM.t('demand.title')} bar={true}>
                    {readyToRender?
                        <button className="btn btn-sm btn-success" onClick={() => {
                            this.setState({ reportModalOpen: true });
                        }}>
                            {EM.t('demand.generateReport')}
                        </button>
                    : null }
                    {readyToRender?                         
                        <UncontrolledButtonDropdown size="sm">
                            <DropdownToggle caret>
                                <i className="far fa-image"></i>{EM.t('demand.export')}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => this.onExport('png')}>{EM.t('demand.png')}</DropdownItem>
                                <DropdownItem onClick={() => this.onExport('svg')}>{EM.t('demand.svg')}</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.onExport('print')}>{EM.t('demand.print')}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>                    
                    : null }
                    <button className="btn btn-default btn-tools" onClick={this.onFilterToggle}>
                        <i className="fas fa-bars"></i>
                    </button>
                </PageTitle>
                {this.state.nodata ?
                    <div className="text-danger font-weight-bold font-sm m-4">{EM.t('util.nodata')}</div>
                :
                    <div className="demand-container">
                        <DemandGraphLegend 
                            preferences={this.state.preferences} 
                            onLegendItemClicked={this.onLegendItemClicked.bind(this)}
                            onLegendItemsToggled={this.onLegendItemsToggled.bind(this)}
                        />                
                        <DemandGraph 
                            ref={this.demandGraphRef} 
                            preferences={this.state.preferences} 
                            primary={this.state.primary} 
                            secondary={this.state.secondary}
                            tertiary={this.state.tertiary}
                            onPointSelected={this.onPointSelected}
                        />                    
                    </div>
                }
                <DemandToolsPanel 
                    onFilterClose={this.onFilterToggle} 
                    filterValues={this.state.filterValues} 
                    onChange={this.onPrefSetChanged}
                    getAvailableDataSets={this.PM.getAvailableDataSets()}
                    getPrimaryDatasetDateRange={this.getPrimaryDatasetDateRange}
                />
                <DemandModal 
                    preferences={this.state.preferences} 
                    primary={this.state.primary} 
                    secondary={this.state.secondary}
                    tertiary={this.state.tertiary}
                    data={this.state.selectedPoint}
                    onClose={this.onModalClose}
                />
                <DemandReportOptionsDialog 
                    onClose={this.onReportModalClose}
                    isOpen={this.state.reportModalOpen}
                    onGenerateReport={this.onGenerateReport}                 
                />
            </div> 
        );
    }
}

export default Demand;
