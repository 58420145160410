/* global EM */
import React, { Component } from 'react';
import PanelModal from '../PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AssignmentApprovalList from './AssignmentApprovalList';

export default class AssignmentGroupPanel extends Component {
    render(){
        return (
            <PanelModal isOpen={this.props.isOpen} className={'panel panel-full workitem-details'} key="workitem-details" toggle={(event) => { this.props.close(); }}>
                <ModalHeader toggle={this.props.onClose}>{this.props.title}</ModalHeader>
                <ModalBody>                   
                    { this.props.assignments ? 
                        <AssignmentApprovalList
                            assignments={this.props.assignments}
                            onBulkUpdate={this.props.onBulkUpdate}
                            onSingleUpdate={this.props.onSingleUpdate}
                            unit={this.props.title}
                            url={this.props.url}
                            showWorkItem={true}
                        />             
                    : 
                        <div className="alert alert-secondary mt-2">
                            No assignments found.
                        </div>
                    }      
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.props.onClose}>
                        {EM.t('util.closeButton')}
                    </Button>
                </ModalFooter>
            </PanelModal>
        );
    }
};