/* eslint no-useless-escape: 0 */
/* global EM */
import _ from 'underscore';

export const Validators = {
    required: (value) => {
        if (_.isEmpty(value)) {
            return "Value is required."
        }
        if (!value.toString().trim()) {
            return "Value is required."
        }
    },
    requiredNumber: (value) => {
        if (!value || _.isUndefined(value) || _.isNull(value)) {
            return "Value is required."
        }
    },
    number: (value) => {
        if (!value) return;
        var vparsed = parseFloat(value);
        if (_.isNaN(vparsed) || !_.isNumber(vparsed)) {
            return "Value is not a valid number."
        }
    },
    numberOnly: (value) => {
        if (!value) return;
        var vparsed = parseFloat(value);
        if (_.isNaN(vparsed) || !_.isNumber(vparsed)) {
            return "Value is not a valid number."
        }
        var re = /[^\d|\-|\.]/;
        if (re.test(value)) {
            return "Value is not a valid number."
        }
    },
    positiveNumber: (value) => {
        if (!value || _.isUndefined(value) || _.isNull(value) || value < 0) {
            return "A positive numeric value is required."
        }
    },
    positiveIntegerAsPercentage: (value, context, mode) => {
        if (!value || _.isUndefined(value) || _.isNull(value)) {
            return "A positive integer value is required."
        }

        let numericValue = Number(value);
        if (isNaN(numericValue)) {
            return "A positive integer value is required."
        }

        if (mode !== 'import') {
            numericValue = Math.floor(numericValue * 100);
        }

        if (numericValue <= 0 || !Number.isInteger(numericValue)) {
            return "A positive integer value is required."
        }
    },
 
    yearValidator: (value) => {
        if (!value || _.isUndefined(value) || _.isNull(value)) {
            return "A valid 4-digit year is required.";
        }
    
        const numericValue = Number(value);
        if (isNaN(numericValue)) {
            return "Year should be a number."; 
        }
    
        if (!Number.isInteger(numericValue)) {
            return "Year must be an integer.";
        }
    
        if (numericValue < 0) {
            return "Year should not be negative.";
        }
    
        if (!/^\d{4}$/.test(value.toString())) {
            return "Year must be a 4-digit number.";
        }
    },
    

    percentage: (value) => {
        if (!value) return;
        var vparsed = parseFloat(value);
        if (_.isNaN(vparsed) || !_.isNumber(vparsed)) {
            return "Value is not a valid percentage."
        }
    },
    email: function (value) {
        if (!value) return;
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(value)) {
            return "Value is not a valid email address."
        }
    },
    noColon: function (value) {
        if (value.indexOf(':') > -1) {
            return 'Value may not contain colons (:).'
        }
    },
    noWhitespace: function (value) {
        if (value.match(/\s/)) {
            return 'Value may not contain whitespace.'
        }
    },
    alphaOnly: function (value) {
        if (value.match(/[\W\d]/g)) {
            return 'Value may only contain alphabetic characters.';
        }
    },
    domainName: function (value, tenant) {
        if (value.toLowerCase().indexOf(tenant.Name + '_') !== 0) {
            return 'Domain name must begin with tenant name, followed by an underscore.'
        }
    },
    length50: function (value) {
        if (value.toString().length > 50) {
            return 'Value may not exceed 50 characters.'
        }
    },
    length100: function (value) {
        if (value.toString().length > 100) {
            return 'Value may not exceed 100 characters.'
        }
    },
    countryExists: (value) => {
        if (_.isEmpty(value)) {
            return "Country is required.";
        }

        // Check if the country exists in the EM.countryCurrencies map
        const countryExists = EM.countryCurrencies.get().some(item => item.Country === value);

        if (!countryExists) {
            return `Country "${value}" does not exist in the system.`;
        }
    },

    tenantName: function (value) {
        if (value.toString().length < 3) {
            return 'Value may not be less than 3 characters.'
        }
        if (value.toString().length > 63) {
            return 'Value may not exceed 63 characters.'
        }

        let regex = /[^a-z0-9-]/gm;
        if (regex.exec(value) !== null) {
            return 'Value may only contain lowercase letters, numbers, or hyphens.'
        }

        if (value[0] === '-' || value[value.length - 1] === '-') {
            return 'Value may not begin or end with a hyphen.'
        }
    }
}

export function Validate(value, validators, context, mode) {
    var firstError = null;
    if (validators) {
        validators.some((valFunc) => {
            return firstError = valFunc(value, context, mode);
        });
    }

    return firstError || null;
}