/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, InputGroup, InputGroupAddon, CustomInput  } from 'reactstrap';
import PanelModal from '../PanelModal';
import WorkItemSelector from '../WorkItemSelectorWithMeta';
import MonthPicker from '../MonthPicker';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Dates from '../../util/Dates';
import StaffingUtil from '../../util/StaffingUtil';
import ProjectionsManager from '../../entities/ProjectionsManager';
import CalendarPercentagePopover from './CalendarPercentagePopover';

export default class AddEmployeeAssignmentDialog2 extends Component {
    constructor(props) {
        super(props);

        this.PM = new ProjectionsManager();

        this.state = {
            activityList: [],
            labelList: [],
            task: null,
            hasValidated: false,
            isValid: false,
            matchingProjections: null,
            popover: null,
            assignmentsToCreate: [{
                id: -1,
                activity: null,
                beginDate: null,
                endDate: null,
                projectedPercentage: null,
                percentage: null,
                label: null,
                _validation: []
            }],            
        }

        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.findMatches = this.findMatches.bind(this);

        this.hasAlertedAboutTruncation = false;
    }

    componentDidMount(){
        EM.activities.load().then(() => {
            let activities = EM.activities.get();
            let activityList = activities.map(act => {
                return { label: act.Name, value: act.ActivityId };
            }).sort((act1, act2) => { return act1.Order > act2.Order });

            activityList.push({ label: 'N/A', value: '' });

            let labelList = []; 
            let labelSetting = EM.getSetting('AdditionalLabels');  
            if (labelSetting){
                labelSetting.split(',').map((label) => {
                    return labelList.push({ value: label.trim(), label: label.trim() });
                });
            }            

            EM.schedules.loadDefaultItem().then((defaultSchedule) => {
                this.PM.loadDefaultProjections().then(projectionsFile => {    
                    this.setState({ activityList, labelList, projectionsFile });
                });
            }).catch(() => {
                this.setState({ activityList, labelList });
            });            
        });
    }

    resetState(){
        this.setState({
            task: null,
            hasValidated: false,
            isValid: false,
            matchingProjections: null,
            popover: null,
            assignmentsToCreate: [{
                id: -1,
                activity: null,
                beginDate: null,
                endDate: null,
                projectedPercentage: null,
                percentage: null,
                label: null,
                _validation: []
            }]
        });
    }

    onClose(){
        this.resetState();
        this.props.onClose();
    }

    onSave(){
        if (!this.validate())return;

        let task = this.state.task;
        let newAssignments = [];
        this.state.assignmentsToCreate.forEach((assignment) => {
            if (!assignment.beginDate || !assignment.endDate || !assignment.percentage)return;
            let newAssignment = {
                DomainId: this.props.employee.DomainId,
                EmployeeId: this.props.employee.EmployeeId,
                WorkItemName: task.value.trim(),
                ActivityName: assignment.activity ? assignment.activity.label.trim() : null,
                BeginDate: assignment.beginDate.toISO(),
                EndDate: assignment.endDate.toISO(),
                Label: assignment.label ? assignment.label.value : null,
                ProjectedValue: assignment.projectedPercentage ? assignment.projectedPercentage / 100 : null,
                Value: assignment.percentage / 100
            }

            newAssignments.push(newAssignment);
        });
        if (newAssignments.length === 0)return;
        this.props.onAddAssignment(newAssignments);
        this.onClose();
    }

    validate(){
        let isValid = true;
        let newAssignments = [];
        this.state.assignmentsToCreate.forEach(assignmentIn => {
            let assignment = Object.assign({}, assignmentIn);
            if (!assignment._validation)assignment._validation = {};

            if (assignment.id === -1 && !assignment.beginDate && !assignment.endDate && !assignment.percentage && !assignment.activity && !assignment.label){
                assignment._validation = {};
                newAssignments.push(assignment);
                return;
            }

            if (!assignment.beginDate){
                isValid = false;
                assignment._validation.beginDate = 'is-invalid';
            }else{
                assignment._validation.beginDate = null;
            }
            if (!assignment.endDate){
                isValid = false;
                assignment._validation.endDate = 'is-invalid';
            }else{
                assignment._validation.endDate = null;
            }
            if (!assignment.percentage){
                isValid = false;
                assignment._validation.percentage = 'is-invalid';
            }else{
                assignment._validation.percentage = null;
            }

            newAssignments.push(assignment);
        });

        this.setState({ hasValidated: true, isValid, assignmentsToCreate: newAssignments });
        return isValid;
    }

    async findMatches(){
        let task = this.state.task;
        let activity = this.state.activity;
        if (!task){ //} || !activity){
            this.setState({ matchingProjections: null });
            return;
        }

        let pf = this.state.projectionsFile;
        if (!pf)return;
        if (pf.data.length === 0)return;
        let matches = pf.findProjections(task.value, activity ? activity.value : null, this.props.employee.RoleId);
        if (matches && matches.length > 0){
            let staffingPercentages = await StaffingUtil.getStaffingPercentage(matches, pf.filterIndexes);
            this.setState({ matchingProjections: matches, staffingPercentages });
        }else{
            this.setState({ matchingProjections: null, staffingPercentages: null });
        }
    }

    addAssignment(assignment){
        let newAssignments = [ ...this.state.assignmentsToCreate.slice(0, -1), assignment, ...this.state.assignmentsToCreate.slice(-1)];
        this.setState({ assignmentsToCreate: newAssignments });
    }

    removeAssignment(assignmentId){
        let newAssignments = [];
        this.state.assignmentsToCreate.forEach(assignment => {
            if (assignment.id !== assignmentId){
                newAssignments.push(assignment);
            }
        });
        this.setState({ assignmentsToCreate: newAssignments });
    }

    getEditRow(assignment, index){
        let updateAssignment = (propObj) => {
            let newAssignment = Object.assign({}, assignment, propObj);
            let newAssignments = this.state.assignmentsToCreate;
            newAssignments[index] = newAssignment;
            this.setState({ assignmentsToCreate: newAssignments }, () => {
                if (this.state.hasValidated)this.validate();
            });
        }

        let validation = assignment._validation || {};
        return (
            <tr key={'assignment' + index}>
                <td>
                    <Select
                        className="single-select"
                        classNamePrefix="single-select"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={false}
                        options={this.state.activityList}
                        placeholder={EM.t('util.filtering.placeholderDefault')}
                        value={assignment.activity}
                        onChange={(selected) => {
                            updateAssignment({ activity: selected });                                
                        }}  
                    />
                </td>                                       
                <td className={validation.beginDate}>
                    <MonthPicker
                        placeholder={EM.t('staffing.tools.from')}
                        date={assignment.beginDate}
                        onChange={(date) => {
                            updateAssignment({ beginDate: date });                                    
                        }}
                    />
                </td>
                <td className={validation.endDate}>
                    <MonthPicker
                        placeholder={EM.t('staffing.tools.to')}
                        date={assignment.endDate}
                        onChange={(date) => {
                            updateAssignment({ endDate: date });
                        }}
                    />                            
                </td> 
                <td className={validation.percentage}>
                    <InputGroup>
                        <Input value={assignment.percentage?assignment.percentage:''} onChange={(event) => {
                            let val = parseInt(event.target.value);                            
                            if (event.target.value === ''){
                                val = null;
                            }                        
                            if (isNaN(val)){
                                val = null;
                            }
                            updateAssignment({ percentage: val });
                        }} />
                        <InputGroupAddon addonType="append">%</InputGroupAddon>                            
                    </InputGroup>                        
                </td>  
                <td>
                    <Creatable
                        className="single-select"
                        classNamePrefix="single-select"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={false}
                        options={this.state.labelList}
                        placeholder={EM.t('util.filtering.placeholderDefault')}
                        formatCreateLabel={(input) => { return EM.t('util.select-create', null, [input]) }}
                        value={assignment.label}
                        onChange={(selected) => {
                            updateAssignment({ label: selected });
                        }}  
                    />
                </td> 
            </tr>
        )
    }

    render() {
        let indexes = this.state.projectionsFile ? this.state.projectionsFile.filterIndexes : {};
        return (
            <PanelModal fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'panel assignments-dialog-modal ' + this.props.className} key="assignments-dialog-modal">
                <ModalHeader toggle={this.onClose}>
                    {EM.t('staffing.addAssignmentTo')}: {this.props.employee.FirstName}&nbsp;{this.props.employee.LastName}&nbsp;-&nbsp;{this.props.employee.Role}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>{EM.t('staffing.addDialog.workitem')}:</Label>
                        <WorkItemSelector 
                            value={this.state.task}
                            onChange={(selected) => {
                                if (!selected){
                                    this.resetState();
                                }else{
                                    this.setState({ task: selected, activity: null, matchingProjections: null }, () => {
                                        this.findMatches();
                                        if (this.state.hasValidated)this.validate();
                                    });   
                                }                             
                            }}  
                        />
                    </FormGroup>
                    {this.state.task ? 
                        <>
                            <FormGroup>
                                <Label>{EM.t('staffing.addDialog.matches')}:</Label>
                                <div className="overflow-container">
                                    {this.state.matchingProjections ?
                                        <table className="table table-striped table-bordered table-sm table-fixed">
                                            <thead>
                                                <tr>
                                                    <th className="width-150">{EM.t('staffing.addDialog.activity')}</th>
                                                    <th className="width-75">{EM.t('staffing.addDialog.begin')}</th>
                                                    <th className="width-75">{EM.t('staffing.addDialog.end')}</th>
                                                    <th className="width-75">{EM.t('staffing.addDialog.percentage')}</th>
                                                    <th className="width-75 column-reference">{EM.t('staffing.addDialog.staffed')}</th>
                                                    <th className="width-175 column-functions">{EM.t('staffing.addDialog.selected')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.matchingProjections.map((proj, projIndex) => {
                                                    let actObj = this.state.activityList.find(act => act.value === proj[indexes.activity].toString());
                                                    if (!actObj){
                                                        actObj = this.state.activityList.find(act => act.label === proj[indexes.activity].toString());
                                                    }
                                                    let newBegin = proj[indexes.begin].startOf('month');
                                                    let newEnd = proj[indexes.end].startOf('month');
                                                    let now = Dates.now();
                                                    let isCurrent = now >= newBegin && now <= newEnd;
                                                    let projValue = proj[indexes.value];
                                                    let projPercentage = projValue * 100; 
                                                    let staffedObject = (this.state.staffingPercentages ? this.state.staffingPercentages[projIndex] : null);                                           
                                                    if (!staffedObject)staffedObject = { average: 0, cls: 'under' };
                                                    let staffedPercentage = (staffedObject.average || 0) * 100 ;  

                                                    return (
                                                        <tr key={'proj' + projIndex} className={isCurrent?'selected-row':''}>
                                                            <td className="width-150" title={actObj ? actObj.label : 'Other'}>{actObj ? actObj.label : 'Other'}</td>
                                                            <td className="width-75">{Dates.toMonthYearStrShort(newBegin)}</td>
                                                            <td className="width-75">{Dates.toMonthYearStrShort(newEnd)}</td>
                                                            <td className="width-75">{projPercentage.toFixed(0)}%</td>
                                                            <td className="width-75 column-reference">
                                                                <span id={"AlreadyStaffed" + projIndex} className={staffedObject.cls} onClick={() => {
                                                                    this.setState({ popover: staffedObject })
                                                                }}>{staffedPercentage ? staffedPercentage.toFixed(0) : 0}%</span>
                                                                {this.state.popover === staffedObject ?
                                                                    <CalendarPercentagePopover target={"AlreadyStaffed" + projIndex} data={this.state.popover} isOpen={true} toggle={() => {
                                                                        this.setState({ popover: null })
                                                                    }} />
                                                                : null }
                                                            </td>
                                                            <td className="width-175 column-functions">
                                                                <div className="pl-1">
                                                                    <CustomInput type="switch" id={"cc" + projIndex} label="" onChange={(event) => {
                                                                        if (event.target.checked){
                                                                            let newAssignment = { id: projIndex, activity: actObj, beginDate: newBegin, endDate: newEnd, percentage: Math.round(projPercentage), projectedPercentage: projPercentage, label: null };
                                                                            this.addAssignment(newAssignment);
                                                                        }else{
                                                                            this.removeAssignment(projIndex);
                                                                        }
                                                                    }}/>   
                                                                </div>                                            
                                                            </td>
                                                        </tr> 
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    : 
                                        <div className="bg-light p-2">{EM.t('staffing.addDialog.noMatches')}</div>
                                    }
                                </div>
                            </FormGroup>                                                 
                            <h6 className="mt-4">Assignments to Add:</h6>
                            <table className="table table-striped table-sm table-bordered table-fixed table-overflow-allowed">
                                <thead>
                                    <tr>
                                        <th className="width-150">{EM.t('staffing.addDialog.activity')}</th>
                                        <th className="width-75">{EM.t('staffing.addDialog.begin')}</th>
                                        <th className="width-75">{EM.t('staffing.addDialog.end')}</th>
                                        <th className="width-75">{EM.t('staffing.addDialog.percentage')}</th>
                                        <th className="width-250">{EM.t('staffing.addDialog.label')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.assignmentsToCreate.map(( assignment, aI) => {
                                        return this.getEditRow(assignment, aI);
                                    })}                            
                                </tbody>                        
                            </table>
                            <div className="alert alert-secondary font-sm mt-4">
                                <i className="fas fa-exclamation-circle">&nbsp;</i>{EM.t('staffing.addDialog.noteDates')}
                            </div>
                        </>
                    : null }
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                    <Button color="success" onClick={this.onSave}>{EM.t('util.save')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
};