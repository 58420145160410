/* global EM */
import React, { Component } from "react";
import Select from "react-select";
import "../../style/_resource-requests-option-dialog.scss";

import {
    Button,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
const statuses = [
    { value: 1, label: "Submitted" },
    { value: 2, label: "In Progress" },
    { value: 3, label: "Approved" },
    { value: 4, label: "Denied" },
    { value: 5, label: "Partial Approval" },
];
export default class ResourceRequestEditOptionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workItem: this.props.workItem,
            activity: this.props.activity,
            role: this.props.role,
            status: this.props.statusID,
            requestType: this.props.requestType,
            message: this.props.message,
            recipientId:this.props.recipientId,
            recipientMessage:this.props.recipientMessage,
        };

        this.onClose = this.onClose.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.handleChildDataUpate = this.handleChildDataUpate.bind(this);
    }

    componentDidMount() {
        let status = statuses.filter(x=> x.value == this.props.statusID);
        let userEntities = [];
        if (!EM.isStrictlyDomainSubscriber()) {
            userEntities = EM.users.get().filter((user) => user.UserId == this.props.recipientId);
        }
        let recipientList = [
            {
                value: "null",
                label: null,
            },
        ];
        userEntities?.forEach((element)=>{
            recipientList.push({
                value :element.UserId,
                label: element.Name
            })
        });
        let recipientId = recipientList.find(x=>x.value == this.props.recipientId)
        
        this.setState({
            status:status,
            recipientId:recipientId ? recipientId : this.props.recipientId
        })
    }
    onClose() {
        this.setState({
            workItem: "",
            activity: "",
            role: "",
            requestType: "",
            message: "",
        });
        this.props.onClose();
    }
    handleChildDataUpate(newItem){
        this.props.onAction(this.props.column,newItem,this.props.oldItem)
    }
    onSubmitHandler(e) {
        e.preventDefault();
            const postData = {
                ResourceRequestId: this.props.resourceRequestId,
                TicketNumber: this.props.ticketNumber,
                WorkItem: this.props.workItem,
                ActivityId: this.props.activityId,
                RoleId: this.props.roleId,
                RequestTypeId: this.props.RequestTypeName =='Add Resource'?1:2,
                RequesterId: this.props.requesterId,
                RecipientId: this.state.recipientId?.value == 'null' ? null :this.state.recipientId?.value,
                RequesterNote: this.props.requestMessage,
                RecipientNote: this.state.recipientMessage,
                StatusId: this.state.status.value,                
                RequesterAcknowledged: (this.state.status.value?this.state.status.value:this.state.status[0].value !== this.props.statusID )|| (this.state.recipientMessage !== this.props.recipientMessage) ?  null : this.props.requesterAcknowledged
            };            
            if(this.state.status.value || this.state.recipientId?.value != this.props.recipientId || this.state.recipientMessage !== this.props.recipientMessage){
                   EM.resourceRequests.update(this.props.oldItem,postData).then((item) => {
                    const updatedRequest = {
                        ...this.props.oldItem,
                        StatusId: this.state.status.value?this.state.status.value:this.props.statusID,
                        RecipientId: this.state.recipientId?.value == 'null' ? null :this.state.recipientId?.value,
                        RecipientNote: this.state.recipientMessage?this.state.recipientMessage:this.props.recipientMessage,
                      };
                      this.props.onUpdate(updatedRequest);
                    this.onClose();
                }).catch((error) => {
                    console.error("Error posting data:", error);
                    this.setState({ error: error.message });
                    this.onClose();
                });  
            }else{                
             this.onClose();
            }
    }
    render() {
        return (
            <Modal
                ref={(el) => (this.componentRef = el)}
                fade={false}
                isOpen={this.props.isOpen}
                toggle={this.onClose}
                className={"demand-report-options " + this.props.className}
                key="demand-report-options"
            >
                <ModalHeader toggle={this.onClose}>
                    {EM.t("demand.resourceRequestsModal.resourceRequestID") + ": " +this.props.ticketNumber}
                </ModalHeader>
                <ModalBody>
                <FormGroup>
                        <label>Status:</label>
                        {EM.isStrictlyDomainSubscriber() ?
                         <Input
                            type="text"
                            placeholder="Status"
                            disabled
                            value={this.state.status && this.state.status[0]?.label?this.state.status[0]?.label:''}
                        />:
                        <Select
                        className="multi-select"
                        isSearchable={true}
                        isMulti={false}
                        value={this.state.status}
                        options={statuses}
                        onChange={(selection) => {
                            this.setState({ status: "" });
                            if (selection) {
                                window.setTimeout(() => {
                                    this.setState({ status: selection });
                                }, 0);
                            }
                        }}
                        placeholder="Select WorkItem"
                    />}
                        
                        {this.state.errorWorkItem ? (
                            <p className="text-color-muted">{this.state.errorWorkItem}</p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <label>WorkItem:</label>
                        <Input
                            type="text"
                            placeholder="WorkItem"
                            disabled
                            value={this.props.workItem?this.props.workItem:''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Activity:</label>
                        <Input
                            type="text"
                            placeholder="Activity"
                            disabled
                            value={this.props.activity?this.props.activity:''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Role:</label>
                        <Input
                            type="text"
                            placeholder="Role"
                            disabled
                            value={this.props.role?this.props.role:''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Request Types:</label>
                        <Input
                            type="text"
                            placeholder="Requester Name"
                            disabled
                            value={this.props.RequestTypeName?this.props.RequestTypeName:''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Request Message:</label>
                        <Input
                            type="textarea"
                            placeholder="Request Message"
                            disabled
                            value={this.props.requestMessage?this.props.requestMessage:''}
                        />
                    </FormGroup>
                    {!EM.isStrictlyDomainSubscriber() && (
                    <FormGroup>
                        <label>Assignee:</label>
                        {EM.isStrictlyDomainSubscriber() ?
                         <Input
                            type="text"
                            placeholder="Recipient"
                            disabled
                            value={this.state.recipientId && this.state.recipientId?.label == null ? '': this.state.recipientId && this.state.recipientId?.label? this.state.recipientId?.label:''}
                        />:
                        <Select
                            className="multi-select"
                            isSearchable={true}
                            isMulti={false}
                            value={this.state.recipientId}
                            options={this.props.recipientList}
                            onChange={(selection) => {
                                this.setState({ recipientId: "" });
                                if (selection) {
                                    window.setTimeout(() => {
                                        this.setState({ recipientId: selection });
                                    }, 0);
                                }
                            }}
                            placeholder="Select Assignee"
                        />}
                    </FormGroup>
                    )}
                    <FormGroup>
                        <label>Response:</label>
                        {EM.isStrictlyDomainSubscriber() ?
                         <Input
                            type="textarea"
                            placeholder="Recipient Message"
                            disabled
                            value={this.state.recipientMessage?this.state.recipientMessage:''}
                        />:
                        <Input
                            type="textarea"
                            placeholder="Recipient Message"
                            value={this.state.recipientMessage? this.state.recipientMessage:""}
                            maxLength={250}
                            onChange={(e) => {
                                this.setState({ recipientMessage: "" });
                                this.setState({ recipientMessage: e.target.value });
                            }}
                        />}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>
                        {EM.t("util.closeButton")}
                    </Button>
                    {EM.isStrictlyDomainSubscriber()?null:
                    <Button color="success" onClick={this.onSubmitHandler}> 
                    {EM.t("demand.resourceRequestsModal.submit")}
                </Button>}
                </ModalFooter>
            </Modal>
        );
    }
}
