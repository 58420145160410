import Entity from './Entity';
import * as entityActions from './actions/entities';

export default class AdminEntity extends Entity{
    async load(force, tenantId){
        let state = this.store.getState();
        if (!state[this.name] || force){            
            await this.dispatch(entityActions.loadAdminEntity(tenantId || state.account.Tenant.TenantId, this));
        }        
    }

    async create(newItem, entityId, tenantId) {
        let state = this.store.getState();
        let created = await this.dispatch(entityActions.createAdminEntity(tenantId || state.account.Tenant.TenantId, this, newItem, entityId));
        this.resetCachedLists();
        return created;
    }

    async delete(ids, tenantId) {
        let state = this.store.getState();
        await this.dispatch(entityActions.deleteAdminEntity(tenantId || state.account.Tenant.TenantId, this, ids));
        this.resetCachedLists();
    }

    async update(oldItem, newItem, tenantId) {
        let state = this.store.getState();
        let updated = await this.dispatch(entityActions.updateAdminEntity(tenantId || state.account.Tenant.TenantId, this, newItem, oldItem));
        this.resetCachedLists();
        return updated;
    }   
    
    async import(items, isSilent, tenantId){
        let state = this.store.getState();  
        this.dispatch(entityActions.importAdminEntity(tenantId || state.account.Tenant.TenantId, this, items, isSilent));
        this.resetCachedLists();
    }    

    async audit(itemId){
        return null;
    }  
}