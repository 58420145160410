/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from 'react';
import PageTitle from  './PageTitle';

export default class LoginBlockNaive extends Component {
    render() {
        return (
            <div className="p-4">
                <div className="login-block alert alert-primary clearfix">                              
                    <PageTitle title="Please Login" className="mt-3"/>
                    <div>
                        <p>Please click the button below, and you will be taken to the login page.</p>
                        <button onClick={() => window.location.reload()} className="btn btn-primary">Log In</button>                     
                        <p className="text-muted mt-4">
                            If you're seeing this screen repeatedly, please verify that you have "Allowed Popups" for this site in your browser's settings.
                        </p>
                        <p className="text-muted mt-2">
                            If you experience issues logging in, <a href="#" onClick={() => this.props.authenticator.logout()}>please click here to log out</a> of existing sessions before proceeding. 
                            If the issue persists, please <a href="https://intrinsiccs.atlassian.net/servicedesk/customer/portal/2">submit a support ticket.</a> 
                        </p>                 
                    </div>                
                </div>
            </div>
        );
    }
}