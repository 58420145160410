/* global EM */
/* eslint no-useless-constructor: 0 */
import ProjectionsFileBase from './ProjectionsFileBase';
import Dates from '../../util/Dates';
import DateGroupValueSet from './DateGroupValueSet';
import _ from 'underscore';

export default class ActualsFile extends ProjectionsFileBase {
    constructor(data, entityName, id) {
        super(data, entityName, id);

        this.ts = (new Date()).getTime();
        this.dataCache = {};
        this.attritionFactorCache = {};
        this.series = null;
        this.seriesTemplate = null;
        this.defaultGrouping = 'Role';
        this.defaultDateGrouping = 'Month';

        this.series = null;
        this.filterIndexes = null;
        this.processedData = null;
        this.defaultDateRange = null;
    }

    static async validate(csvContents) {    
        await EM.roles.load();
        await EM.activities.load();

        let indexes = ActualsFile.getIndexes(csvContents);
    
        let errors = [];

        if (csvContents.length > 100000){
            errors.push([(0 + ':' + 0), 'File contains too many rows. Maximum rows is 100,000.']);
            return errors;
        }
        
        let header = csvContents[0];
        if (header.length > 256){
            errors.push([(0 + ':' + 0), 'File contains too many columns. Maximum columns is 256.']);
        }

        csvContents.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                if (rowIndex === 0){                
                    //validate header
                }else{
                    if (cellIndex === indexes.role){
                        let roleFound = EM.roles.lookupId(row[indexes.role], 'Name');
                        if (!roleFound){
                            errors.push([(rowIndex + ':' + cellIndex), 'Role not found.']);
                        }
                    }  
                    if (cellIndex === indexes.activity){
                        let activityFound = EM.activities.lookupId(row[indexes.activity], 'Name');
                        if (!activityFound){
                            errors.push([(rowIndex + ':' + cellIndex), 'Activity not found.']);
                        }
                    }  
                    if (cellIndex === indexes.begin || cellIndex === indexes.end){
                        if (!Dates.isValidDateStr(row[cellIndex])){
                            errors.push([(rowIndex + ':' + cellIndex), 'Invalid date format. Must be in format m/d/yyyy.']);
                        }                    
                    }
                    if (cellIndex === indexes.value){
                        if (!parseFloat(row[cellIndex])){
                            errors.push([(rowIndex + ':' + cellIndex), 'Invalid numeric format. Must be a number greater than zero.']);
                        }                    
                    }                                          
                }
            });                       
        });

        return errors;
    }         

    preprocessData() {
        this.filterIndexes = this.setFilterIndexes();
        this.filterIndexKeys = Object.keys(this.filterIndexes);
        let seriesTmp = [];
        let rolesNotFound = [];

        this.processedData = this.data.mapFiltered((rowIn, rowIndex) => {
            let row = [...rowIn];
            if (rowIndex === 0) {
                row.push('RoleId');
                row.push('Department');
                row.push('Organization');
                return row;
            } else {
                let role = EM.roles.findByKey(row[this.filterIndexes.role]);
                let dept = EM.departments.byId(role ? role.DepartmentId : null);
                let org = EM.organizations.lookupValue(dept ? dept.OrganizationId : null);
                if (role && dept && org) {
                    row.push(role ? role.RoleId : '');
                    row.push(dept ? dept.Name : '');
                    row.push(org || '');
                } else {
                    rolesNotFound.push(row[this.filterIndexes.role]);
                    return null;
                }                    
                            
                //Pre-parse values for easier processing later
                let tmpAct = EM.activities.findByKey(row[this.filterIndexes.activity]);
                row[this.filterIndexes.activity] = tmpAct ? tmpAct.ActivityId : row[this.filterIndexes.activity];
                row[this.filterIndexes.begin] = Dates.fromStr(row[this.filterIndexes.begin]).startOf('month');	
                row[this.filterIndexes.end] = Dates.fromStr(row[this.filterIndexes.end]).endOf('month');	
                row[this.filterIndexes.value] = parseFloat(row[this.filterIndexes.value]);

                seriesTmp.push(this.getGroupKey('Role', row[this.filterIndexes.role]));
                seriesTmp.push(this.getGroupKey('Department', row[this.filterIndexes.department]));
                seriesTmp.push(this.getGroupKey('Organization', row[this.filterIndexes.organization]));

                return row;                
            }
        });

        this.series = _.uniq(seriesTmp).sort();

        if (rolesNotFound.length > 0){
            console.log('Projection roles not found:', rolesNotFound);
        }
    }

    processDateRanges(){
        if (!this.processedData)this.preprocessData();

        this.processedData.slice(1).forEach((row) => {
            let range = Dates.getArrayOfMonths(row[this.filterIndexes.begin], row[this.filterIndexes.end], true);            
            let dateKeys = [];        
            range.dates.forEach((month, monthIndex) => {
                dateKeys.push(range.beginIndex + monthIndex);
            });
            row.push(dateKeys);
            row.push(range);
        });
    }    

    setFilterIndexes() {
        if (this.data.length === 0)return {};
        let header = this.data[0];

        let output = {
            workitem: 0,
            activity: 1,
            begin: 2,
            end: 3,
            role: 4,
            value: 6,
            roleId: header.length,
            department: header.length + 1,
            organization: header.length + 2,
            dateKeys: header.length + 3,
            range: header.length + 4
        };

        if (header.length > 10) {
            for (let i = 10; i < header.length; i++) {
                let hName = header[i];
                if (hName.indexOf('_') === 0) hName = hName.slice(1);
                output[hName] = i;
            }
        }

        return output;
    }

    static getIndexes(csvContents){
        let temp = new ActualsFile(csvContents, 'actuals', 'tmp');
        return temp.setFilterIndexes();
    }       

    getMaxDateRange(){
        if (!this.processedData)this.preprocessData();
        let min = new Date().getTime();
        let max = -2174756400000;
        this.processedData.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;
            let begin = row[this.filterIndexes.begin];
            let end = row[this.filterIndexes.end];
            if (begin < min)min = begin;
            if (end < min)min = end;
            if (begin > max)max = begin;
            if (end > max)max = end;
        });

        if (min < Dates.visualizableBegin)min = Dates.visualizableBegin;
        if (max > Dates.visualizableEnd)max = Dates.visualizableEnd;
        
        return [ min, max ];
    }    

    getDateRange(preferences){
        if (preferences.begin){
            return Dates.getMonthRangeFromMonthYearStrs(preferences.begin, preferences.end);
        }else if (this.defaultDateRange){
            let range = Dates.getArrayOfMonths(this.defaultDateRange[0], this.defaultDateRange[1], true).dates;
            return [ range[0], range[range.length - 1] ];
        }else{
            return Dates.getMonthRangeFromMonthYearStrs(null, null);
        }
    }

    async getSummaryByDate(preferences, reserve, attrition, pos, ignoreCache) {        
        let self = this;
        if (!this.processedData)this.preprocessData();

        let sKey = this.getCacheKey(preferences, reserve, attrition, pos);
        let fromCache = await this.getFromSummaryCache(sKey);

        if (fromCache && !ignoreCache) {
            //EM.log('Using pre-processed actuals.');
            return fromCache;
        } else {
            //EM.log('Processing actuals.');
            let monthGroups = {};
            let grouping = preferences.grouping || this.defaultGrouping;
            let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
            let dateGrouping = preferences.dateGrouping|| this.defaultDateGrouping;
            let dateRange = this.getDateRange(preferences);
            let pTotal = 0;

            if (!this.seriesTemplate) {
                this.seriesTemplate = { total: null };
                this.series.forEach((series) => {
                    this.seriesTemplate[series] = null;
                });
            }

            //Projection column order...
            // 0           1           2        3      4       5             6        7      8      9       10...,      
            //'WorkItem', 'Activity', 'Begin', 'End', 'Role', 'Complexity', 'Value', 'CID', 'MID', 'MM', ...Attrs       
            //Specifically using var with for-loops here, for perf. -JS
            for (var j = 1, rowLen = this.processedData.length; j < rowLen; j++) {
                let row = this.processedData[j];
                if (!this.isRowIncluded(row, preferences)) continue;
                let months = Dates.getArrayOfMonths(row[this.filterIndexes.begin], row[this.filterIndexes.end], true).dates;
                let groupKey = self.getGroupKey(grouping, row[groupingColumn]);

                let value = row[this.filterIndexes.value];
                if (reserve) value = value * (1 + reserve);
                if (pos)value = value * this.getPoS(row);
                let oValue = value;
                
                for (var i = 0, len = months.length; i < len; i++) {
                    let month = months[i];               
                    if (month < dateRange[0] || month > dateRange[1])continue;

                    let dgInfo = self.getDateGroup(dateGrouping, month);
                    let monthKey = dgInfo.key;
                    
                    if (attrition) value = oValue * this.getSimpleAttritionFactor(month, attrition);                    

                    if (!monthGroups[monthKey]) {
                        monthGroups[monthKey] = Object.assign({}, this.seriesTemplate);
                    }
                    monthGroups[monthKey]['date'] = dgInfo.begin;
                                        
                    if (!monthGroups[monthKey][groupKey]){
                        monthGroups[monthKey][groupKey] = new DateGroupValueSet(dateGrouping);
                    }                    
                    monthGroups[monthKey][groupKey].push(month, value);

                    if (!monthGroups[monthKey]['total']){
                        monthGroups[monthKey]['total'] = new DateGroupValueSet(dateGrouping);
                    }                    
                    monthGroups[monthKey]['total'].push(month, value);
                    pTotal++;
                }
            }

            window.setTimeout(() => {
                this.saveToSummaryCache(sKey, monthGroups);
                EM.log('Processed projections:', pTotal);
            }, 0);            
            return monthGroups;
        }
    }

    isRowIncluded(row, preferences) {
        let keys = this.filterIndexKeys;
        if (keys.length === 0) return true;

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key === 'begin' || key === 'end')continue;        
            let acceptedValues = preferences.get(key);
            if (!acceptedValues) continue;
            let rowValue = row[this.filterIndexes[key]];

            if (key === 'activity') {
                rowValue = EM.activities.lookupValue(rowValue);
            }

            if (acceptedValues.indexOf(rowValue) === -1) {
                return false;
            }            
        }

        return true;
    }  
    
    getPoS(row){
        let pos = 1;
        let posCol = this.filterIndexes.PoS || this.filterIndexes.POS || this.filterIndexes.pos;
        if (posCol){
            pos = parseFloat(row[posCol] || 1);
        }
        return pos;
    }

    getSimpleAttritionFactor(month, attrition){
        let key = month.toMillis().toString() + attrition;
        if (!this.attritionFactorCache[key]){
            let months = Math.ceil(month.diff(Dates.now(), 'months').toObject().months);            
            let factor = months > 0 ? 1 - ((months/12) * attrition) : 1;   
            this.attritionFactorCache[key] = factor > 0 ? factor : 0;
        }
        return this.attritionFactorCache[key];
    }

    findProjections(workitemName, activityId, roleId){
        if (!this.processedData)this.preprocessData();
        let output = this.processedData.filter((row, rowIndex) => {
            return (workitemName ? row[this.filterIndexes.workitem] === workitemName : true) && 
                (activityId ? row[this.filterIndexes.activity].toString() === activityId.toString() : true ) && 
                (roleId ? row[this.filterIndexes.roleId].toString() === roleId.toString() : true );
        });

        return output;
    }

    groupAndFilterProjections(preferences){
        if (!this.processedData)this.preprocessData();
        let groupingColumn = this.filterIndexes[preferences.grouping] || -1;

        let output = _.filteredNest(this.processedData.slice(1), 
            row => {
                return this.isRowIncluded(row, preferences);
            },
            row => row[groupingColumn], 
            row => row[this.filterIndexes.workitem].trim()
        );
        return output; 
    }

    getDetailByDate(ms, groupingValue, preferences, reserve, attrition){
        let datatable = [];
    
        let grouping = preferences.grouping || this.defaultGrouping;
        let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
        let dateGrouping = preferences.dateGrouping|| this.defaultDateGrouping;
        let date = Dates.fromMs(ms);        
        let dgInfo = this.getDateGroup(dateGrouping, date);                    

        this.processedData.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;                                             
            if (row[groupingColumn] === groupingValue){                    
                let overlapping = Dates.doRangesOverlap(dgInfo.begin, dgInfo.end, row[this.filterIndexes.begin], row[this.filterIndexes.end]);                
                let isRowIncluded = this.isRowIncluded(row, preferences);
                if (overlapping && isRowIncluded){                             
                    let value = row[this.filterIndexes.value];
                    if (reserve) value = value * (1 + reserve);
                    if (attrition) value = value * this.getSimpleAttritionFactor(date, attrition);

                    datatable.push({
                        id: rowIndex,
                        organization: row[this.filterIndexes.organization],
                        department: row[this.filterIndexes.department],
                        role: row[this.filterIndexes.role],
                        workitem: row[this.filterIndexes.workitem],
                        activity: EM.activities.lookupValue(row[this.filterIndexes.activity]),
                        begin: row[this.filterIndexes.begin].toLocaleString(),
                        end: row[this.filterIndexes.end].toLocaleString(),
                        complexity: row[5],
                        value: value,
                        cid: row[7],
                        mid: row[8],
                        mm: row[9]
                    });
                }                
            }
        });
                                    
        let groups = _.groupBy(datatable, 'role');
        return groups;
    }
}