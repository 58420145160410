/* global EM */
import ProjectionsWorker from 'worker-loader?filename=projections.worker.js!../../workers/projections.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import * as Comlink from 'comlink';

export function regenerateProjections(domainId, entity, scheduleId, isSilent) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.regenerateProjections(domainId, scheduleId, isSilent).then(response => {   
            let pid = response.ProjectionId;
            let fileObj = new entity.fileType(response.Projections, entityName, pid);
            fileObj.setMeta({ Count: response.Count, ProcessingTime: response.ProcessingTime });
            return dispatch({ type: 'LOAD_FILE', key: entity.makeFileName(pid), data: fileObj });
        }).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'FILE_ERROR', key: entity.makeFileName(-1) });
        });
    };
}

async function _loadDomainData(schedule){
    let output;
    await EM.loadEntities([EM.activities, EM.attributes, EM.complexityLookup, EM.complexities, EM.roles, EM.departments, EM.organizations, EM.allocations, EM.algorithms, EM.settings]).then(async () => {
        output = {
            activities: EM.activities.get(),
            attributes: EM.attributes.get(),
            roles: EM.roles.get(),
            departments: EM.departments.get(),
            organizations: EM.organizations.get(),
            allocations: EM.allocations.get().filter(item => item.Active),
            complexityLookup: EM.complexityLookup.get(),
            complexities: EM.complexities.get().filter(item => item.Active),
            multipliers: EM.algorithms.get().filter(item => item.Active),
            settings: EM.settings.get()
        };

        if (typeof schedule === 'object'){
            output.schedule = schedule;
        }else{
            await EM.schedules.loadFile(schedule).then(() => {
                let fileContents = EM.schedules.getFile(schedule);
                if (fileContents)output.schedule = fileContents.toObjectArray();
            });   
        }     
    }); 

    return output;
}

export function regenerateProjectionsLocally(domainId, entity, schedule, runDiagnostic){
    let entityName = entity.name;
    return async (dispatch, getState, api) => {
        let isCustomSchedule = typeof schedule === 'object';
        let scheduleId = isCustomSchedule ? 'CUSTOM' : schedule;
        return _loadDomainData(schedule).then(domainData => {
            let generateProjectionsWorker = Comlink.wrap(new ProjectionsWorker());
            return generateProjectionsWorker(domainData, runDiagnostic).then(response => {
                let pid = 'LOCAL-' + scheduleId;
                let fileObj = new entity.fileType(response.Projections, entityName, pid);
                fileObj.setMeta({ Count: response.Count, ProcessingTime: response.ProcessingTime });
                dispatch({ type: 'LOAD_FILE', key: entity.makeFileName(pid), data: fileObj });
                return fileObj;
            }).catch((e) => {
                console.log(e);
            });
        });
    };
}