/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import Dates from '../../util/Dates';
import _ from 'underscore';
import StaffingUtil from '../../util/StaffingUtil';
import StaffingEmployeeSubDetails from './StaffingEmployeeSubDetails';

export default class StaffingEmployeeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAssignment: null,
            tasks: [],
            selectedWorkItemIndex: null,
            selectedWorkItemName: null,
            combinedAssignments: this.props.combinedAssignments || []
        };

        this.monitorKeyPress = this.monitorKeyPress.bind(this);

        let textEndSetting = EM.getSetting('FavorTextEndInLabels') || 'false';
        this.showRtlLabels = (textEndSetting === 'true');
    }
    initializeWorkItemStates() {
        const { combinedAssignments } = this.state;
        const workItemStates = {};

        if (combinedAssignments) {
            combinedAssignments.forEach((assignment) => {
                if (assignment.WorkItemName) {
                    workItemStates[assignment.WorkItemName] = true;
                }
            });
        }
        this.setState({ workItemStates });
    }
    componentDidMount() {
        window.addEventListener("keydown", this.monitorKeyPress);
        this.initializeWorkItemStates();
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule) return;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents) {
                    let fileContentsObjects = fileContents.toObjectArray();
                    let wiGroups = fileContentsObjects.groupBy('Name');
                    let wiKeys = Object.keys(wiGroups).sort();
                    let addMeta = EM.getSetting('AdditionalMetadataFields');
                    if (addMeta) {
                        addMeta = addMeta.split(',');
                    }
                    let tasks = wiKeys.map(key => {
                        let group = wiGroups[key];
                        let lbl = key;

                        if (addMeta && Array.isArray(addMeta)) {
                            let meta = {};
                            group.forEach(item => {
                                addMeta.forEach(attr => {
                                    let value = item['_' + attr];
                                    if (value) {
                                        if (!meta[attr]) meta[attr] = {};
                                        meta[attr][value] = true;
                                    }
                                });
                            });
                            let metaStr = [];
                            Object.keys(meta).forEach(key => {
                                metaStr.push(key + ': ' + Object.keys(meta[key]).join(', '));
                            });
                            if (metaStr.length > 0) {
                                lbl = lbl + " " + metaStr.join(' | ');
                            }
                        }
                        return { label: lbl, value: key };
                    });

                    let addTaskList = EM.getSetting('AdditionalTasks');
                    if (addTaskList) {
                        addTaskList.split(',').map((task) => {
                            return tasks.push({ value: task.trim(), label: task.trim() });
                        });
                    }

                    this.setState({ tasks });
                }
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.monitorKeyPress)
    }

    monitorKeyPress(event) {
        if (event.isComposing || event.keyCode === 229) {
            return;
        }
        if (event.keyCode === 27) {
            this.setState({ selectedAssignment: null });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.combinedAssignments !== this.props.combinedAssignments) {
            this.setState({ combinedAssignments: this.props.combinedAssignments }, () => {
                this.initializeWorkItemStates();
            });
        }
    }

    handleWorkItemClickDefaultSettingOn = (workItemName) => {
        this.setState((prevState) => ({
            workItemStates: {
                ...prevState.workItemStates,
                [workItemName]: !prevState.workItemStates[workItemName],
            },
        }));
    };
    handleWorkItemClickDefaultSettingOff = (index, WorkItemName) => {
        this.setState((prevState) => ({
            selectedWorkItemIndex: prevState.selectedWorkItemIndex === index ? null : index,
            selectedWorkItemName: prevState.selectedWorkItemName === WorkItemName ? null : WorkItemName,
        }));
    };

    handleDataFromChild = (assignment) => {
        this.setState({ selectedAssignment: assignment })
    };
    render() {
        let { employee, range, assignments, maxWidth, onUpdate, onAdd, onDelete, onToggleLock, onDuplicate, onEdit } = this.props;
        const { combinedAssignments } = this.state;
        let filteredAssignments = combinedAssignments ? combinedAssignments.filter(assignment => {
            return Dates.doMonthRangesOverlap(assignment.range, range);
        }) : [];

        let assignmentGroups = _.groupBy(filteredAssignments, (assignment) => {
            let activity = EM.activities.findByKey(assignment.ActivityName);
            return assignment.WorkItemName + '!^$&' + (activity ? (activity.Order ? parseInt(activity.Order) : 1000) : 1000) + '!^$&';
        });

        let assignmentGroupKeys = Object.keys(assignmentGroups).sort((a, b) => {
            var aBits = a.split('!^$&');
            var bBits = b.split('!^$&');

            var o1 = aBits[0].toLowerCase();
            var o2 = bBits[0].toLowerCase();

            var p1 = parseInt(aBits[1]);
            var p2 = parseInt(bBits[1]);

            if (o1 < o2) return -1;
            if (o1 > o2) return 1;
            if (p1 < p2) return -1;
            if (p1 > p2) return 1;
            return 0;
        });

        let pHeight = this.props.rowHeight;
        let marginBuffer = 0;
        let cellWidth = this.props.cellWidth;
        let isWorkItemDefaultEnabled = EM.getSetting("StaffingEmployee:WorkItems:IsDefaultEnabled")?.toLowerCase();
        return (
            <div className="item-detail" onClick={(event) => {
                event.stopPropagation();
                this.setState({ selectedAssignment: null });
            }}>
                {this.props.isActive ? assignmentGroupKeys.map((assignmentGroupKey, index) => {
                    let assignmentsInGroup = _.sortBy(assignmentGroups[assignmentGroupKey], (item) => {
                        return item.range.beginIndex;
                    });
                    const task = this.state.tasks.find(task => task.value === assignmentsInGroup[0].WorkItemName);
                    const workItemName = assignmentsInGroup[0]?.WorkItemName;
                    if (!this.state.workItemStates || !workItemName || !(workItemName in this.state.workItemStates)) {
                        return null;
                    }

                    const isOpen = this.state.workItemStates[workItemName];
                    return (
                        <>
                            {isWorkItemDefaultEnabled === "false" || !isWorkItemDefaultEnabled ?
                                <>
                                    <div key={assignmentGroupKey} className={"detail-row"} onClick={(event)=>{
                                         event.stopPropagation();
                                         this.setState({ selectedAssignment: null })
                                    }}>
                                        <div
                                            className={"detail-header detail-header-icon " + (isOpen ? 'detail-header-icon-active' : '')}
                                            style={{ height: (assignmentsInGroup.length * pHeight) - 1 }}
                                            onClick={() => this.handleWorkItemClickDefaultSettingOn(workItemName)}
                                        >
                                            <b
                                                title={task ? task.label : assignmentsInGroup[0].WorkItemName}
                                                className={this.showRtlLabels ? 'rtl' : ''}
                                            >
                                                {assignmentsInGroup[0].WorkItemName}
                                            </b>
                                            <br />
                                        </div>

                                        <div className="detail-grid" onClick={() => this.handleWorkItemClickDefaultSettingOn(workItemName)}>
                                            {
                                                range.dates.map((month, monIndex) => {
                                                    let dateKey = range.beginIndex + monIndex;
                                                    let value = 0;
                                                    assignments?.filter((assignt) => assignt.WorkItemName === assignmentsInGroup[0].WorkItemName)
                                                        .forEach((cv) => {
                                                            if (cv.dateKeys.includes(dateKey)) {
                                                                value += cv.Value
                                                            } else {
                                                                value += 0
                                                            }
                                                        })
                                                    let cls = StaffingUtil.getColorClass(value, employee.TimeAllocation || 1);
                                                    if (value >= 0) {
                                                        let output = (
                                                            <div className={value ? "item-element " + cls : "item-element "} key={monIndex} style={{ marginLeft: marginBuffer * cellWidth }}>
                                                                {value ? `${(value * 100).toFixed()}%` : ''}
                                                            </div>
                                                        );
                                                        marginBuffer = 0;
                                                        return output;
                                                    } else {
                                                        marginBuffer++;
                                                        return null;
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div key={assignmentGroupKey + 'on'} className={"detail-row"} >

                                        {isOpen && (
                                            <StaffingEmployeeSubDetails
                                                key={combinedAssignments.length}
                                                employee={employee}
                                                range={range}
                                                cellWidth={cellWidth}
                                                rowHeight={this.props.rowHeight}
                                                assignments={assignments}
                                                combinedAssignments={this.props.uniqueAssignments}
                                                maxWidth={maxWidth}
                                                isActiveSub={isOpen}
                                                selectedWorkItemName={workItemName}
                                                onUpdate={this.props.onUpdate}
                                                selectedAssignment={this.state.selectedAssignment}
                                                sendDataToParent={this.handleDataFromChild}
                                                onAdd={onAdd}
                                                onDelete={onDelete}
                                                onDuplicate={onDuplicate}
                                                onToggleLock={onToggleLock}
                                                onEdit={onEdit}
                                            />
                                        )}
                                    </div>
                                </>
                                :
                                <>
                                    <div key={assignmentGroupKey} className={"detail-row"} onClick={() => this.handleWorkItemClickDefaultSettingOff(index, assignmentsInGroup[0].WorkItemName)} >
                                        <div
                                            className={"detail-header detail-header-icon " + (this.state.selectedWorkItemIndex == index ? 'detail-header-icon-active' : '')}
                                            style={{ height: (assignmentsInGroup.length * pHeight) - 1 }}

                                        >
                                            <b
                                                title={task ? task.label : assignmentsInGroup[0].WorkItemName}
                                                className={this.showRtlLabels ? 'rtl' : ''}
                                            >
                                                {assignmentsInGroup[0].WorkItemName}
                                            </b>
                                            <br />
                                        </div>

                                        <div className="detail-grid">
                                            {
                                                range.dates.map((month, monIndex) => {
                                                    let dateKey = range.beginIndex + monIndex;
                                                    let value = 0;
                                                    assignments?.filter((assignt) => assignt.WorkItemName === assignmentsInGroup[0].WorkItemName)
                                                        .forEach((cv) => {
                                                            if (cv.dateKeys.includes(dateKey)) {
                                                                value += cv.Value
                                                            } else {
                                                                value += 0
                                                            }
                                                        })
                                                    let cls = StaffingUtil.getColorClass(value, employee.TimeAllocation || 1);
                                                    if (value >= 0) {
                                                        let output = (
                                                            <div className={value ? "item-element " + cls : "item-element "} key={monIndex} style={{ marginLeft: marginBuffer * cellWidth }}>
                                                                {value ? `${(value * 100).toFixed()}%` : ''}
                                                            </div>
                                                        );
                                                        marginBuffer = 0;
                                                        return output;
                                                    } else {
                                                        marginBuffer++;
                                                        return null;
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div key={assignmentGroupKey + 'on'} className={"detail-row"} >
                                        {this.state.selectedWorkItemIndex == index && (
                                            <>
                                                <StaffingEmployeeSubDetails
                                                    employee={employee}
                                                    range={range}
                                                    cellWidth={cellWidth}
                                                    rowHeight={this.props.rowHeight}
                                                    assignments={assignments}
                                                    combinedAssignments={this.props.uniqueAssignments}
                                                    maxWidth={maxWidth}
                                                    isActiveSub={this.state.selectedWorkItemIndex == index}
                                                    selectedWorkItemName={this.state.selectedWorkItemName}
                                                    onUpdate={this.props.onUpdate}
                                                    selectedAssignment={this.state.selectedAssignment}
                                                    sendDataToParent={this.handleDataFromChild}
                                                    onAdd={this.props.onAdd}
                                                    onDelete={onDelete}
                                                    onDuplicate={onDuplicate}
                                                    onToggleLock={onToggleLock}
                                                    onEdit={onEdit}
                                                /></>
                                        )}
                                    </div>
                                </>
                            }
                        </>

                    );
                }) : null}
                {this.props.isActive ?
                    <div className={"detail-row detail-util detail-util-display"}>
                        <div className={"detail-header"} title={employee.FirstName + ' ' + employee.LastName}>
                            {employee.Contractor ? '* ' : ''}{employee.FirstName} {employee.LastName} <br />
                            <span>{EM.t('staffing.workTimePercentage')}: {((employee.TimeAllocation || 1) * 100).toFixed(0)}%</span>
                        </div>
                        <div className={"detail-controls"}>
                            {EM.isDomainEditor() ?
                                <button className="btn btn-success btn-sm" onClick={() => {
                                    onAdd(employee);
                                }}>{EM.t('staffing.addAssignment')}</button>
                                : null}
                            {EM.isDomainEditor() ?
                                <button className="btn btn-primary btn-sm" disabled={(!this.state.selectedAssignment)} onClick={() => {
                                    onDuplicate(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.duplicateAssignment')}</button>
                                : null}
                            {EM.isDomainEditor() ?
                                <button className="btn btn-danger btn-sm" disabled={(!this.state.selectedAssignment || this.state.selectedAssignment.Status === 'Locked')} onClick={() => {
                                    onDelete(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.deleteAssignment')}</button>
                                : null}
                            {EM.isDomainResourceManager() ?
                                <span className="divider"></span>
                                : null}
                            {EM.isDomainResourceManager() ?
                                <button className="btn btn-warning btn-sm" disabled={(!this.state.selectedAssignment)} onClick={() => {
                                    onToggleLock(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.toggleLocked')}</button>
                                : null}
                            {EM.isDomainEditor() ?
                                <button className="btn btn-info btn-sm" disabled={(!this.state.selectedAssignment || this.state.selectedAssignment.Status === 'Locked')} onClick={() => {
                                    onEdit(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.edit')}</button>
                                : null}
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}