/* global EM */
import React, { useState }  from 'react';
import PanelModal from '../PanelModal';
import HelpContent from '../Help/HelpContent';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const ExpressionEditList = React.lazy(() => import('.//ExpressionEditList'));
const ExpressionVariablesListing = React.lazy(() => import('.//ExpressionVariablesListing'));
const ExpressionMetaInfo = React.lazy(() => import('.//ExpressionMetaInfo'));
const ExpressionFunctionsListing = React.lazy(() => import('.//ExpressionFunctionsListing'));

export default function ExpressionDialog(props){
    const [ accordionOpen, setAccordionOpen ] = useState(null);
    const [ modifiedExpression, setModifiedExpression ] = useState(null);

        
    function onAccordion(accordion){
        setAccordionOpen(accordionOpen === accordion ? null : accordion);
    }

    const isValid = modifiedExpression ? modifiedExpression.isValid : false;

    return (
        <PanelModal fade={false} isOpen={props.isOpen} toggle={props.onClose} className={'panel expression-modal'} key="import-modal">
            <ModalHeader toggle={props.onClose}>{EM.t('algorithms.expressions.editor')} (ID: {props.algoObject?props.algoObject.MultiplierId:''})</ModalHeader>
            <ModalBody>
                <div className="row expression-modal-content">
                    <div className="col-9">
                        <ExpressionEditList expression={props.algoObject ? props.algoObject.Expression : null} onUpdate={(newExpression) => {
                            setModifiedExpression(newExpression);
                        }} />
                        <ExpressionMetaInfo data={props.algoObject}/>
                    </div>
                    <div className="col-3">
                        <div className="accordion">
                            <div className={accordionOpen === 'variables' ? 'active' : ''}>
                                <h5 onClick={() => { onAccordion('variables') }}>{EM.t('algorithms.expressions.availableVariables')}</h5>
                                <div>
                                    <ExpressionVariablesListing />
                                </div>
                            </div>
                            <div className={accordionOpen === 'functions' ? 'active' : ''}>
                                <h5 onClick={() => { onAccordion('functions') }}>{EM.t('algorithms.expressions.functions')}</h5>
                                <div>                                            
                                    <ExpressionFunctionsListing />
                                </div>
                            </div>
                            <div className={'function-ref ' + (accordionOpen === 'reference' ? 'active' : '')}>
                                <h5 onClick={() => { onAccordion('reference') }}>{EM.t('algorithms.expressions.references')}</h5>
                                <HelpContent path="/x/x/function-ref" hideTitle={true}/>
                            </div>                                    
                        </div>                                 
                    </div>                                                    
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.onClose}>{EM.t('util.cancel')}</Button>
                <Button color="primary" disabled={!isValid} onClick={() => props.onSave(modifiedExpression.expressionText)}>{EM.t('util.save')}</Button>
            </ModalFooter>
        </PanelModal>
    );
}