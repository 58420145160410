import React, { Component } from 'react';

export default class MetaInfo extends Component {
    render() {
        return (
            <>
                <div className={"mt-2 mb-1 meta-info " + this.props.className}>
                    <div>
                        &copy;{new Date().getFullYear()} <a href="https://www.intrinsiccs.com" target="_blank" rel="noopener noreferrer">Intrinsic Clinical Systems</a>
                    </div>
                    <div>
                        <a href="https://www.intrinsiccs.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                    <div>
                        <span className="badge badge-secondary badge-pill" title={process.env.REACT_APP_BUILD}>{process.env.REACT_APP_VERSION}</span>
                    </div>                
                </div>
                {this.props.noTerms ? 
                    null 
                    : 
                    <div className={"mt-1 mb-1 meta-info " + this.props.className}>
                        <div>
                            Use of this system is subject to <a target="_blank" rel="noopener noreferrer" href="https://intrinsiccs.atlassian.net/servicedesk/customer/portal/2/article/2583625729">Terms and Conditions</a>.
                        </div>
                    </div>
                }
            </>
        );
    }
}
