import * as types from '../actionTypes';
import _ from 'underscore';

export default function domainState(state = {}, action) {
    switch (action.type) {
        case types.MARK_DOMAIN_DIRTY:
            return Object.assign({}, state, { [action.domainId]: true })    
        case types.MARK_DOMAIN_CLEAN:
            return _.omit(state, action.domainId);
        default:
            return state;
    }
}