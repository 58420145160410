/* global EM */
import React, { Component } from 'react';
import HelpContent from '../components/Help/HelpContent';
import HelpListing from '../components/Help/HelpListing';
import PageTitle from  '../components/PageTitle';
import MetaInfo from  '../components/MetaInfo';
import Routes from '../app/Routes';

class Help extends Component {    
    render() {
        let path = Routes.parseQuery(this.props.location.search.slice(1)).path;
        return (
            <div key="contents" className="page container-fluid help-page limit-width">
                <div className="row no-gutters p-0">
                    <div className="col-6">
                        <PageTitle title={EM.t('help.title')} />
                    </div>
                    <div className="col-6 no-print">
                        <div className="btn-group float-right">
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                this.props.history.push(Routes.client.manual);
                            }}>
                                <i className="fas fa-eye"></i> {EM.t('help.viewAll')}
                            </button> 
                            <button className="btn btn-warning btn-sm" onClick={() => {
                                window.print();
                            }}>
                                <i className="fas fa-print"></i> {EM.t('help.print')}
                            </button>                             
                        </div>                        
                    </div>
                </div>
                <div className="row no-gutters p-0">
                    <div className="col-3 no-print">
                        <HelpListing />
                    </div>
                    <div className="col-9 print-full">
                        <div className="p-2 pl-3">
                            <HelpContent path={path} onHelpClick={() => {}} />
                            <MetaInfo />
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default Help;
