import PreferenceManager from './PreferenceManager';

export default class StaffingEmployeePreferences{
    constructor(name, inst){
        this.name = name;
        this.preferences = new PreferenceManager('staffing-employee');
        this.group = this.preferences.asGroup('preferences'); 
        this.inst = inst ? inst : (this.group(name) || {});             
    }

    set(name, value){
        let newOptions = Object.assign({}, this.inst, { [name]: value });
        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete newOptions[name];
            }
        }    
        
        this.inst = newOptions;
        this.group(this.name, newOptions);
    }

    get(name, defaultValue){
        let val = this.inst[name] || null;
        return val || defaultValue;
    }

    clone(){
        return new StaffingEmployeePreferences(this.name, this.inst);
    }

    //////////Helpers for specific fields
    get begin() {
        return this.get('begin');
    }
    set begin(value) {
        this.set('begin', value);
    }    

    get end() {
        return this.get('end');
    }
    set end(value) {
        this.set('end', value);
    }    
        
    get grouping() {
        return this.get('grouping', null);
    }
    set grouping(value) {
        this.set('grouping', value);
    }  
    
    get showInactive(){
        return this.get('showInactive', false);
    }  
    set showInactive(value) {
        this.set('showInactive', value);
    }  

    get employeeSet() {
        return this.get('employeeSet', 'Active');
    }
    set employeeSet(value) {
        this.set('employeeSet', value);
    } 
}