/* global EM */
import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Routes from '../app/Routes';

import AdminDomains from './AdminDomains';
import AdminUsers from './AdminUsers';
import AdminTenants from './AdminTenants';

class Admin extends Component {
    constructor(props){
        super(props);
        EM.clearActiveDomain();    
    }

    render() {    
        return (
            <Switch>                
                <Route path={Routes.admin.domains}  render={() => <AdminDomains {...this.props} />} />
                <Route path={Routes.admin.users}  render={() => <AdminUsers {...this.props} />} />
                <Route path={Routes.admin.tenants}  render={() => <AdminTenants {...this.props} />} />
            </Switch>  
        );
    }
}

export default Admin;
