/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

export default class Assignments extends Component {
    constructor(props){
        super(props);

        EM.employees.load();
        EM.assignments.load();
        EM.activities.load();
        EM.roles.load();
        EM.departments.load();
        EM.organizations.load();
       // EM.resourceRequests.load();
    }

    render() {    
        let data = null;
        let columns = null;
        const entity = EM.assignments;        
        
        if (EM.allLoaded(entity, EM.employees, EM.activities, EM.roles, EM.departments, EM.organizations)){                 
            let orgLookup = (cell, row) => {
                let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
                let deptId = EM.roles.lookupValue(roleId, 'DepartmentId');
                let orgId = EM.departments.lookupValue(deptId, 'OrganizationId');                        
                return EM.organizations.lookupValue(orgId);    
            };
            let deptLookup = (cell, row) => {
                let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
                let deptId = EM.roles.lookupValue(roleId, 'DepartmentId');
                return EM.departments.lookupValue(deptId);                        
            };
            let roleLookup = (cell, row) => {
                let roleId = EM.employees.lookupValue(row.EmployeeId, 'RoleId');
                return EM.roles.lookupValue(roleId);                        
            };

            data = entity.get();
            columns = [  
            {
                dataField: 'AssignmentId',
                text: entity.columns('id'),           
                editable: false,
                creatable: false,
                headerClasses: 'column-id',
                classes: 'column-id',
                filterOptions: {
                    number: true
                } 
            },                
            {
                dataField: 'organization',
                text: entity.columns('organization'),
                isReference: true,
                width: 150,
                formatter: orgLookup,
                sortValue: orgLookup,
                filterValue: orgLookup,
                filterOptions: {
                    dynamicEntity: EM.organizations
                } 
            },{
                dataField: 'department',
                text: entity.columns('department'),
                isReference: true,
                width: 150,
                formatter: deptLookup,
                sortValue: deptLookup,
                filterValue: deptLookup,
                filterOptions: {
                    dynamicEntity: EM.departments
                }
            },{                
                dataField: 'role',
                text: entity.columns('role'),
                isReference: true,
                editable: false,
                width: 150,
                formatter: roleLookup,
                sortValue: roleLookup,
                filterValue: roleLookup,
                filterOptions: {
                    dynamicEntity: EM.roles
                }
            },              
            { 
                dataField: 'EmployeeId', 
                text: entity.columns('employee'), 
                fromEntity: EM.employees,        
                validators: [ Validators.required ],
                width: 150
            },
            { 
                dataField: 'WorkItemName', 
                text: entity.columns('task'),
                asWorkItem: true,        
                validators: [ Validators.required ],
                width: 200,
                filterOptions: {
                    text: true
                }
            },
            { 
                dataField: 'ActivityName', 
                text: entity.columns('activity'),
                width: 200,
                filterOptions: {
                    text: true
                }
            },
            { 
                dataField: 'BeginDate', 
                text: entity.columns('begin'), 
                asDate: true,     
                width: 100,                
                validators: [ Validators.required ]                     
            },
            { 
                dataField: 'EndDate', 
                text: entity.columns('end'),                
                asDate: true,                    
                width: 100,   
                validators: [ Validators.required ]
            },
            { 
                dataField: 'Value', 
                text: entity.columns('value'), 
                asPercentage: true,
                csvType: Number,
                width: 60,        
                validators: [ Validators.positiveIntegerAsPercentage ],
                filterOptions: {
                    number: true
                }
            },           
            { 
                dataField: 'Label', 
                text: entity.columns('label'),
                width: 150,
                filterOptions: {
                    text: true
                }
            },
            { 
                dataField: 'Status', 
                text: entity.columns('status'),
                asAssignmentStatus: true,
                width: 100
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container" />
            </div>
        );
    }
}
