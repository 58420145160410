/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import PanelModal from '../PanelModal';
import _ from 'underscore';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import StaffingWorkItemEmployeeListing from './StaffingWorkItemEmployeeListing';
import StaffingUtil from '../../util/StaffingUtil';

export default class StaffingWorkItemManageTasksDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAssignment: null,
            detailsDialogVisible: false,
            roleEmployeesVisible: [],
            roles: [],
            employeeGroups: [],
            manageEmployeeModalTitle: '',
            projectionGroupsKey: ''
        };

        this.onClose = this.onClose.bind(this);
    }

    onClose(){
        this.setState({ roleEmployeesVisible: [] });
        this.props.onClose();
    }

    render() {
        let self = this;
        let filterIndexes = this.props.filterIndexes;
        let projectionGroups = this.props.projectionGroups;

        let activityProjections = projectionGroups[this.props.projectionGroupsKey];
        if (!activityProjections)return null;

        let exampleProjection = activityProjections[0];
        if (!exampleProjection)return null;
        let beginIndex = exampleProjection[filterIndexes.range].beginIndex;
        let endIndex = exampleProjection[filterIndexes.range].endIndex;

        let activityMatchAssignments = _.filter(this.props.assignments, function (assignment) {
            let activity = (assignment.ActivityName  || '').trim();
            return activity === self.props.projectionGroupsKey.trim();
        });

        let exactMatchAssignments = _.filter(activityMatchAssignments, function (assignment) {
            return assignment.range.beginIndex === beginIndex && assignment.range.endIndex === endIndex;
        });

        let leftOverAssignments = [...exactMatchAssignments];

        return (
            <PanelModal isOpen={this.props.visible} className={'panel workitem-assignments-dialog'} key="assignments-dialog-modal" toggle={(event) => { this.props.close(); }}>
                <ModalHeader toggle={this.onClose}>{this.props.title}</ModalHeader>
                <ModalBody>
                    {_.map(this.props.roles, (role) => {
                        let employees = this.props.employeeGroups[role.roleId];
                        let employeeIds = _.pluck(employees, "EmployeeId");

                        let allMatchingDateRange = true;

                        let roleActivityAssignments = _.filter(activityMatchAssignments, function (assignment) {
                            return _.contains(employeeIds, assignment.EmployeeId);
                        });

                        let roleAssignments = _.filter(exactMatchAssignments, function (assignment) {
                            return _.contains(employeeIds, assignment.EmployeeId);
                        });                        

                        leftOverAssignments = _.difference(leftOverAssignments, roleAssignments);

                        let roleProjections = _.filter(activityProjections, function (projection) {
                            return projection[filterIndexes.roleId] === role.roleId;
                        });

                        if (!roleProjections || roleProjections.length === 0)return null;

                        let projectionsPercentage = Math.fround(roleProjections.reduce(function (memo, projection) { return memo + projection[filterIndexes.value]; }, 0));                                        
                        let assignmentPercentage = Math.fround(roleAssignments.reduce(function (memo, assignment) { 
                            if (assignment.range.beginIndex !== beginIndex || assignment.range.endIndex !== endIndex){
                                allMatchingDateRange = false;
                            }
                            return memo + assignment.Value; 
                        }, 0));

                        let roleEmployeesVisible = this.state.roleEmployeesVisible && _.contains(this.state.roleEmployeesVisible, role.roleId);
                        let itemElementClass = roleEmployeesVisible ? "expanded" : "collapsed";

                        let colorClass = StaffingUtil.getColorClass(assignmentPercentage, projectionsPercentage, true);
                        if (roleActivityAssignments.length === 0){
                            colorClass = 'other';
                        }

                        return (
                            <div key={"Role" + role.roleId}>
                                <div onClick={(event) => {
                                    let roleEmployeesVisible = [...this.state.roleEmployeesVisible]
                                    if (roleEmployeesVisible && _.contains(roleEmployeesVisible, role.roleId)) {
                                        roleEmployeesVisible = _.filter(roleEmployeesVisible, function (roleId) { return roleId != role.roleId });
                                    } else {
                                        roleEmployeesVisible = [roleEmployeesVisible, role.roleId];
                                    }
                                    let projection = _.filter(activityProjections, function (projection) {
                                        return projection[filterIndexes.roleId] === role.roleId;
                                    });
                                    this.setState({ roleEmployeesVisible: roleEmployeesVisible, projection: projection.length > 0 ? projection[0] : [] });
                                }} className={"clearfix section-header " + colorClass + " " + itemElementClass}>
                                    <span className={"title roleName"}>
                                        {role.name}
                                        <i>({roleAssignments.length} {EM.t('staffing.addDialog.matching')}, {roleActivityAssignments.length - roleAssignments.length} {EM.t('staffing.addDialog.notMatching')})</i>
                                    </span>
                                    <span className={"ratio float-right"} title={StaffingUtil.getTooltip(assignmentPercentage, projectionsPercentage)}>                                                        
                                        {!allMatchingDateRange ? 
                                            <i className="fas fa-exclamation-circle">&nbsp;</i>
                                        : null }
                                        {StaffingUtil.getMarkupRatio(assignmentPercentage, projectionsPercentage)}
                                    </span>
                                </div>
                                {roleEmployeesVisible ?
                                    <StaffingWorkItemEmployeeListing
                                        role={role}
                                        onAddAssignment={this.props.onAddAssignment}
                                        projection={roleProjections[0]}
                                        filterIndexes={this.props.filterIndexes}
                                        employees={employees}
                                        assignments={this.props.assignments}
                                        relevantAssignments={roleAssignments}
                                        onAfterDeleteAssignment={this.props.onAfterDeleteAssignment}
                                        onAfterUpdateAssignment={this.props.onAfterUpdateAssignment}
                                        show={roleEmployeesVisible}
                                        allMatchingDateRange={allMatchingDateRange}
                                    />
                                : null}
                            </div>                                
                        )
                    })}
                    <div>
                        {EM.debugMode && leftOverAssignments.length > 0 ?
                            <div className="clearfix section-header other">
                                <span className={"roleName"}>{EM.t('staffing.otherAssignmentsForActivity')}:</span>
                                <ul>
                                    {leftOverAssignments.map(assignment => {
                                        return <li key={'Other' + assignment.AssignmentId}>
                                            {assignment.AssignmentId}: {EM.employees.lookupValue(assignment.EmployeeId)} - {(assignment.Value * 100).toFixed(0)}%
                                        </li>
                                    })}
                                </ul>
                            </div>
                        : null }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.onClose}>
                        {EM.t('util.closeButton')}
                    </Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}