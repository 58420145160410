import React, { Component } from 'react';
import Routes from '../app/Routes';
import tinycolor from 'tinycolor2';

export default class DomainCard extends Component {
    constructor(props){
        super(props);

        this.dColor = tinycolor(this.props.domain.color).setAlpha(.90).toRgbString();
    }

    render() {
        let domain = this.props.domain;        
        return (
            <div className={"domain-card"} key={domain.DomainId}>
                { domain.DisplayName ? 
                    <div className="domain-card-body" style={{ backgroundColor: this.dColor }} onClick={() => {
                        this.props.history.push(Routes.compose(Routes.client.dashboard, { DomainName: domain.Name}));
                    }}>
                        <h5 className="domain-card-title">{domain.DisplayName}</h5>
                    </div>
                : null }
                {this.props.children ? 
                    <div className={"domain-deck " + (domain.DisplayName?'':'no-parent')}>
                        {this.props.children}    
                    </div> 
                : null }
            </div> 
        );
    }
};