import React from 'react';
import { Label, FormGroup, Input, InputGroup, InputGroupAddon, Button, FormText } from 'reactstrap';

export default function ColorField(props){        
    return (
        <FormGroup>      
            <Label title={props.description}>{props.display || props.name}:</Label>
            <InputGroup>
                <Input
                    type="color"
                    value={props.value} 
                    onChange={event => props.onChange(props.name, event.target.value)} 
                />
                <InputGroupAddon addonType="prepend"><Button onClick={event => props.onChange(props.name, null)}>Reset</Button></InputGroupAddon>
            </InputGroup>
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null }             
        </FormGroup>
    );
}