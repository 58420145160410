/* global EM */
import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PageTitle from '../components/PageTitle';
import DemandGraphLegend from '../components/Demand/DemandGraphLegend';
import BudgetGraph from '../components/Budget/BudgetGraph';
import DemandReportOptionsDialog from '../components/Demand/DemandReportOptionsDialog';
import BudgetPreferences from '../entities/preferences/BudgetPreferences';
import BudgetProjectionsManager from '../entities/BudgetProjectionManager';
import Routes from '../app/Routes';
import BudgetToolsPanel from '../components/Budget/BudgetToolsPanel';
import BudgetModal from '../components/Budget/BudgetModal';

const options = [
    { value: "Budget Amount", label: "Budget Amount", isAscending: false },
    { value: "Work Item", label: "Work Item", isAscending: false }
];

class BudgetCharts extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.defaultPrefs = new BudgetPreferences('default');
        this.areToolsOpenPref = this.defaultPrefs.preferences.asFlag('tools-panel', 'open');
        this.PM = new BudgetProjectionsManager(this);
        this.indexes = null

        this.state = {
            preferences: this.defaultPrefs,
            areToolsOpen: this.areToolsOpenPref(),
            filterValues: null,
            primary: null,
            secondary: null,
            tertiary: null,
            selectedPoint: false,
            reportModalOpen: false,
            additionalDataOptions: options,
            selectedOption: options[0]
        }
        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onPrefSetChanged = this.onPrefSetChanged.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onPointSelected = this.onPointSelected.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.onReportModalClose = this.onReportModalClose.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
        this.getPrimaryDatasetDateRange = this.getPrimaryDatasetDateRange.bind(this);
        this.budgetGraphRef = React.createRef();
        this.handleUpdateSorting = this.handleUpdateSorting.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.resetSortDataOptions = this.resetSortDataOptions.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        let self = this;
        EM.loadEntities([EM.organizations, EM.departments, EM.roles, EM.schedules, EM.budgets, EM.activities, EM.headcounts]).then(() => {
            self.onLoadInitial();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPrimaryDatasetDateRange() {
        if (this.state.primary) {
            return this.state.primary.getMaxDateRange();
        }
        return null;
    }

    onLoadInitial() {
        let self = this;
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (defaultSchedule) {
                this.defaultSchedule = defaultSchedule;
                let BudgetId;
                this.defaultSchedule.Projections?.forEach(projection => {
                    if (projection.Budgets && projection.Budgets.length > 0) {
                        BudgetId = projection.Budgets?.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))[0].BudgetId;
                    }
                });
                console.log(BudgetId)
                try {
                    this.PM.loadDatasetsFromPrefs(self.state.preferences, BudgetId).then(datasets => {
                        this.setState(datasets);
                    });
                } catch (err) {
                    EM.warn(err);
                }
                if(BudgetId){
                    EM.budgets.loadFile(BudgetId).then(() => {
                            let fileContents = EM.budgets.getFile(BudgetId);
                            if (fileContents) {
                                let fvs = fileContents.getFilterValues(true);
                                this.setState({ filterValues: fvs });
                            }
                            else{
                            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                                if (fileContents){
                                    let fvs = fileContents.getFilterValues(true);                        
                                    this.setState({ filterValues: fvs });
                                }
                            });
                        }
                    })
                } else{
                    EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                        let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                        if (fileContents){
                            let fvs = fileContents.getFilterValues(true);                        
                            this.setState({ filterValues: fvs });
                        }
                    });
                }
                
            } else {
                this.setState({ nodata: true });
            }
        });
    }

    onFilterToggle() {
        this.areToolsOpenPref(!this.state.areToolsOpen);
        this.setState({ areToolsOpen: !this.state.areToolsOpen });
        EM.triggerWindowResize();
    }
    onGenerateReport(options) {
        let currentPreferencesName = this.state.preferences.name;
        let reportAddress = Routes.compose(Routes.client.demandReport, { DomainName: this.props.domain.Name }, { custom: currentPreferencesName });
        EM.crossPage = options;
        this.props.history.push(reportAddress);
    }

    onPrefSetChanged(newPreferences) {
        let dg = this.budgetGraphRef.current;
        if (dg) dg.showLoading();

        window.setTimeout(() => {
            try {
                let BudgetId;
                this.defaultSchedule.Projections?.forEach(projection => {
                    if (projection.Budgets && projection.Budgets.length > 0) {
                        BudgetId = projection.Budgets?.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))[0].BudgetId;
                    }
                });
                this.PM.loadDatasetsFromPrefs(newPreferences,BudgetId).then(datasets => {
                    this.setState(datasets);
                });
                this.setState({ preferences: newPreferences });
            } catch (err) {
                EM.warn(err);
            }
        }, 10); //This is explicitly set to 10ms since highcharts loading indicator takes a few to show
    }

    handleSelectionChange = (selectedValue) => {
        const selected = this.state.additionalDataOptions.find((option) => option.value === selectedValue.value)
        this.setState({ selectedOption: selected });
    };

    resetSortDataOptions = () => {
      this.setState({selectedOption: options[0]})
      this.handleUpdateSorting(false)
    }

    handleUpdateSorting = (isTrue) => {
        this.setState((prevState) => ({
            selectedOption: {
                ...prevState.selectedOption,
                isAscending: isTrue
            },
            additionalDataOptions: prevState.additionalDataOptions.map((option) => ({
                ...option,
                isAscending: isTrue
            }))
        }));
    };

    onExport(type) {
        let dg = this.budgetGraphRef.current;
        if (dg) dg.onExport(type);
    }

    onModalClose() {
        this.setState({ selectedPoint: null });
    }

    onReportModalClose() {
        this.setState({ reportModalOpen: false });
    }

    onPointSelected(series, point, type) {
        this.setState({ selectedPoint: { series, point, type } });
    }

    onLegendItemClicked(seriesClicked, isFocusMode) {
        this.budgetGraphRef.current.onLegendItemClicked(seriesClicked, isFocusMode);
    }

    onLegendItemsToggled(visible) {
        this.budgetGraphRef.current.onLegendItemsToggled(visible);
    }

    render() {
        let readyToRender = EM.allLoaded(EM.organizations, EM.departments, EM.roles, EM.schedules, EM.budgets, EM.activities, EM.headcounts);
        if (!readyToRender) return null;
        return (
            <div key="contents" className={"page page-visualization container-fluid " + (this.state.areToolsOpen ? 'tools-mode' : '')}>
                <PageTitle title={EM.t('header.budget')} bar={true}>

                    {readyToRender ?
                        <UncontrolledButtonDropdown size="sm">
                            <DropdownToggle caret>
                                <i className="far fa-image"></i>{EM.t('demand.export')}
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => this.onExport('png')}>{EM.t('demand.png')}</DropdownItem>
                                <DropdownItem onClick={() => this.onExport('svg')}>{EM.t('demand.svg')}</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.onExport('print')}>{EM.t('demand.print')}</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        : null}
                    <button className="btn btn-default btn-tools" onClick={this.onFilterToggle}>
                        <i className="fas fa-bars"></i>
                    </button>
                </PageTitle>
                {this.state.nodata ?
                    <div className="text-danger font-weight-bold font-sm m-4">{EM.t('util.nodata')}</div>
                    :
                    <div className="demand-container">
                        <DemandGraphLegend
                            preferences={this.state.preferences}
                            onLegendItemClicked={this.onLegendItemClicked.bind(this)}
                            onLegendItemsToggled={this.onLegendItemsToggled.bind(this)}
                        />
                        <BudgetGraph
                            ref={this.budgetGraphRef}
                            preferences={this.state.preferences}
                            primary={this.state.primary}
                            secondary={this.state.secondary}
                            tertiary={this.state.tertiary}
                            onPointSelected={this.onPointSelected}
                            selectedDataOption={this.state.selectedOption}
                        />
                    </div>
                }
                <BudgetToolsPanel
                    onFilterClose={this.onFilterToggle}
                    filterValues={this.state.filterValues}
                    onChange={this.onPrefSetChanged}
                    getAvailableDataSets={this.PM.getAvailableDataSets()}
                    getPrimaryDatasetDateRange={this.getPrimaryDatasetDateRange}
                    handleUpdateSorting={this.handleUpdateSorting}
                    handleSelectionChange={this.handleSelectionChange}
                    resetSortDataOptions={this.resetSortDataOptions}
                    additionalDataOptions={this.state.additionalDataOptions}
                    selectedOption={this.state.selectedOption}
                />
                <BudgetModal
                    preferences={this.state.preferences}
                    primary={this.state.primary}
                    secondary={this.state.secondary}
                    tertiary={this.state.tertiary}
                    data={this.state.selectedPoint}
                    onClose={this.onModalClose}
                />
                <DemandReportOptionsDialog
                    onClose={this.onReportModalClose}
                    isOpen={this.state.reportModalOpen}
                    onGenerateReport={this.onGenerateReport}
                />
            </div>
        );
    }
}

export default BudgetCharts;
