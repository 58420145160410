/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import PanelModal from '../PanelModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { CSVExport } from 'react-bootstrap-table2-toolkit';
import ColumnMapper from '../ColumnMapper';

const { ExportCSVButton } = CSVExport;

export default class BudgetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: {},
        };
        this.showFilterModal = false;
        this.onClose = this.onClose.bind(this);

        this.reserve = null;
        this.groupedData = null;

        this.columns = ColumnMapper([{
            dataField: 'workitem',
            text: EM.t('budget.modal.workitem'),
            sort: true,
            width: 120,
            formatter: (cell) => {
                return <span title={cell}>{cell}</span>
            }
        }, {
            dataField: 'activity',
            text: EM.t('budget.modal.activity'),
            sort: true,
            width: 120,
            formatter: (cell) => {
                return <span title={cell}>{cell}</span>
            }
        }, {
            dataField: 'role',
            text: EM.t('budget.modal.role'),
            sort: true,
            width: 200,
            formatter: (cell) => {
                return <span title={cell}>{cell}</span>
            }
        }, {
            dataField: 'begin',
            text: EM.t('budget.modal.begin'),
            sort: true,
            width: 90
        }, {
            dataField: 'end',
            text: EM.t('budget.modal.end'),
            sort: true,
            width: 90
        }, {
            dataField: 'FteHours',
            text: EM.t('budget.modal.ftehours'),
            sort: true,
            width: 85
        }, {
            dataField: 'BaseHourlyRate',
            text: EM.t('budget.modal.baseHourlyRate'),
            sort: true,
            width: 120
        }, {
            dataField: 'BaseBudget',
            text: EM.t('budget.modal.baseBudget'),
            sort: true,
            width: 100,
            formatter: (cell) => {
                return isNaN(cell) ? cell:('$' + cell?.toFixed(2));
            }
        }]);


        this.hcColumns = [{
            dataField: 'value',
            text: EM.t('budget.modal.value'),
            sort: true,
            formatter: (cell) => {
                return cell?.toFixed(2);
            }
        }];
    }

    onClose() {
        this.props.onClose();
        this.setState({ open: {} });
    }

    toggle(key) {
        let newOpt = this.state.open[key] === true ? false : true;
        let newOpenState = Object.assign({}, this.state);
        newOpenState.open[key] = newOpt;
        this.setState(newOpenState);
    }

    render() {
        let data = this.props.data;
        if (!data) return null;

        let stack = data.series.stack;
        let dataset = this.props[stack];
        this.groupedData = dataset.getDetailByDateBudgets(data.series.name, data.point.name, this.props.preferences, data.type.type,stack);
        let workitem = data.point.name;
        let keys = this.groupedData ? Object.keys(this.groupedData).sort() : [];
        let dgMonthly = this.props.preferences.dateGrouping === 'Month' ? true : false;

        return (
            <PanelModal ref={el => (this.componentRef = el)} fade={false} isOpen={data ? true : false} toggle={this.onClose} className={'budget-modal panel ' + this.props.className} key="budget-modal">
                <ModalHeader toggle={this.onClose}>
                    {workitem}
                </ModalHeader>
                <ModalBody>
                    {keys.map((key, keyIndex) => {
                        let roleData = this.groupedData[key];
                        let total = roleData.reduce((memo, item) => {
                            return memo + (Number(item.BaseBudget) || 0);
                        }, 0);
                        return (
                            <div className="collapse-section" key={key}>
                                <h6 className="alert alert-secondary" onClick={() => this.toggle(key)} style={data?.type?.type === 'workitem' ? { height: '42px' } : {}}>
                                    {data?.type?.type === 'bar' ? data.series.name : null}
                                    {total ?
                                    <div className='float-right gap-4'><span>{!dgMonthly ? '*' : ''}{EM.t('budget.modal.total')}: ${total.toFixed(2)}</span></div>: null}
                                </h6>
                                <Collapse isOpen={this.state.open[key] || keys.length === 1}>
                                    <ToolkitProvider
                                        keyField={'id'}
                                        data={roleData}
                                        columns={dataset.entityName === 'headcounts' ? this.hcColumns : this.columns}
                                        exportCSV={{
                                            fileName: key + '.csv'
                                        }}
                                        search={{
                                            searchFormatted: true
                                        }}
                                    >{props => (
                                        <div>
                                            <div className="collapse-section-toolbar clearfix">
                                                <ExportCSVButton className="float-right btn btn-secondary btn-sm" title={EM.t('util.table.exportRecords')} {...props.csvProps}>
                                                    <i className="fas fa-download"></i>{EM.t('util.table.exportRecords')}
                                                </ExportCSVButton>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                classes="table table-bordered table-striped table-xs"
                                            />
                                        </div>
                                    )}
                                    </ToolkitProvider>
                                </Collapse>
                            </div>
                        )
                    })}
                    {!dgMonthly ?
                        <div className="alert alert-secondary font-sm mt-4">
                            <i className="fas fa-exclamation-circle">&nbsp;</i>{EM.t('budget.modal.avgWarning')}
                        </div>
                        : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}