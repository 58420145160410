/* global EM */
import React, { Component } from 'react';
import SettingsTabs from '../components/SettingsTabs';
import SettingsValueInput from '../components/SettingsValueInput';
import SettingsListInput from '../components/SettingsListInput';
import SettingsDropdownInput from '../components/SettingsDropdownInput';


class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        EM.currencyCodes.load()
    }
    render() {
        if (!EM.settings.isLoaded() || !EM.currencyCodes.isLoaded()) return null;
        let showBaseCurrencySetting = EM.hasFeature("budgets") && EM.isDomainFinanceManager();
        let entity = EM.currencyCodes;
        let currencyCodeOptions = null;
        if (showBaseCurrencySetting && EM.allLoaded(entity)) {              
            const currencyCodes = entity.get();
            currencyCodeOptions = currencyCodes.map((item) => {
              return { value: item.Code, label: item.Code }
            })
        }
        return (
            <div key="contents" className="page container-fluid">
                <SettingsTabs {...this.props} />
                <div className="card-columns card-columns-2 mt-4 cards-flush">
                    <div className="card h-auto">
                        <div className="card-body">
                            <h5>{EM.t('settings.generalSettings.demandSection')}</h5>
                            <div className="bg-light p-3">
                                <SettingsValueInput setting="Reserve" enforceNumeric={true} />
                                <SettingsValueInput setting="Attrition" enforceNumeric={true} />
                                <SettingsDropdownInput setting="ProrateProjections" isMulti={false} options={[
                                    { label: 'No', value: 'false' },
                                    { label: 'Yes', value: 'true' },
                                ]} />
                                <SettingsListInput setting="HiddenColumns" />
                                <SettingsListInput setting="PinnedColumns" />
                            </div>
                        </div>
                    </div>
                    <div className="card mb-5">
                        <div className="card-body">
                            <h5>{EM.t('settings.generalSettings.dashboardSection')}</h5>
                            <div className="bg-light p-3">
                                <SettingsDropdownInput setting="DashboardWidgets" isMulti={true} options={[
                                    { label: 'Employee Availability', value: 'EmployeeAvailabilityWidget' },
                                    { label: 'Work Items Ending Soon', value: 'WorkItemsEndingWidget' },
                                    { label: 'Work Items Without Assignments', value: 'WorkItemsNoAssignmentsWidget' },
                                ]} />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5>{EM.t('settings.generalSettings.staffingSection')}</h5>
                            <div className="bg-light p-3">
                                <SettingsListInput setting="AdditionalTasks" characterLimit={100} />
                                <SettingsListInput setting="AdditionalLabels" characterLimit={100} />
                                <SettingsDropdownInput setting="HiddenEmployeeFilters" isMulti={true} options={[
                                    { value: 'JobTitle', label: EM.t('employees.columns.jobTitle') },
                                    { value: 'Role', label: EM.t('employees.columns.role') },
                                    { value: 'Department', label: EM.t('employees.columns.department') },
                                    { value: 'Organization', label: EM.t('employees.columns.organization') },
                                    { value: 'Region', label: EM.t('employees.columns.region') },
                                    { value: 'SubRegion', label: EM.t('employees.columns.subRegion') },
                                    { value: 'Email', label: EM.t('employees.columns.email') },
                                    { value: 'EmployeeNumber', label: EM.t('employees.columns.employeeNumber') },
                                    { value: 'ManagerNumber', label: EM.t('employees.columns.managerNumber') },
                                    { value: 'CostCenter', label: EM.t('employees.columns.costCenter') },
                                    { value: 'EmployeeType', label: EM.t('employees.columns.employeeType') },
                                    { value: 'PositionStatus', label: EM.t('employees.columns.positionStatus') },
                                    { value: 'Contractor', label: EM.t('employees.columns.contractor') },
                                    { value: 'Custom1', label: EM.t('employees.columns.custom1') },
                                    { value: 'Custom2', label: EM.t('employees.columns.custom2') },
                                    { value: 'Custom3', label: EM.t('employees.columns.custom3') },
                                    { value: 'Custom4', label: EM.t('employees.columns.custom4') },
                                    { value: 'Custom5', label: EM.t('employees.columns.custom5') }
                                ]} />
                                <SettingsValueInput setting="StaffingUnderAllocated" enforceNumeric={true} />
                                <SettingsValueInput setting="StaffingOverAllocated" enforceNumeric={true} />
                            </div>
                        </div>
                    </div>
                    {
                      showBaseCurrencySetting && currencyCodeOptions ? <div className="card">
                            <div className="card-body">
                                <h5>{EM.t('settings.generalSettings.budgetSection')}</h5>
                                <div className="bg-light p-3">
                                    <SettingsDropdownInput setting="BaseCurrency" isMulti={false} options={currencyCodeOptions} />
                                </div>
                            </div>
                        </div> : null
                    }

                </div>
            </div>
        );
    }
}

export default GeneralSettings;
