import * as types from '../actionTypes';
import _ from 'underscore';

export default function fetching(state = [], action) {
    switch (action.type) {
        case types.START_FETCH:
            return [ ...state, action.data ];
        case types.END_FETCH:
            return state.map((item) => {
                if (item === action.data) {                        
                    return '#' + action.data;                        
                }else{
                    return item;
                }
            });
        case types.REMOVE_FETCH:
            return _.without(state, '#' + action.data);        
        default:
            return state;
    }
}