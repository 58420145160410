/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';
import EmployeesTablePreferences from '../entities/preferences/EmployeesTablePreferences';

class Employees extends Component {
    constructor(props){
        super(props);

        this.prefs = new EmployeesTablePreferences('default');

        this.state = {
            ts: null
        }
        

        EM.employees.load();
        EM.roles.load();
        EM.departments.load();
        EM.organizations.load();
        //EM.resourceRequests.load();
    } 

    render() {
        let data = null;
        let columns = null;
        const entity = EM.employees;

        if (EM.allLoaded(entity, EM.roles, EM.departments, EM.organizations)){    
            data = entity.get();
            let orgLookup = (cell, row) => {
                let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId');
                let orgId = EM.departments.lookupValue(deptId, 'OrganizationId');                        
                return EM.organizations.lookupValue(orgId);    
            };
            let deptLookup = (cell, row) => {
                let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId');
                return EM.departments.lookupValue(deptId);                        
            };
            let hiddenCols = this.prefs.hiddenColumns;

            columns = [
                {                
                    dataField: 'FirstName',
                    text: entity.columns('firstName'),
                    width: 125,        
                    validators: [ Validators.required ],
                    unhideable: true,
                    filterOptions: {
                        text: true
                    }              
                },
                {                
                    dataField: 'LastName',
                    text: entity.columns('lastName'),
                    width: 125,        
                    validators: [ Validators.required ],
                    unhideable: true,
                    filterOptions: {
                        text: true
                    }                   
                },
                {                
                    dataField: 'JobTitle',
                    text: entity.columns('jobTitle'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                      
                },                                  
                {                
                    dataField: 'RoleId',
                    text: entity.columns('role'),
                    fromEntity: EM.roles,
                    width: 125,        
                    validators: [ Validators.required ],
                    unhideable: true           
                },
                {
                    dataField: 'Department',
                    text: entity.columns('department'),
                    isReference: true,
                    width: 125,   
                    formatter: deptLookup,
                    sortValue: deptLookup,
                    filterValue: deptLookup,
                    filterOptions: {
                        dynamicEntity: EM.departments
                    }                    
                },
                {
                    dataField: 'Organization',
                    text: entity.columns('organization'),
                    isReference: true,
                    width: 125,   
                    formatter: orgLookup,
                    sortValue: orgLookup,
                    filterValue: orgLookup,
                    filterOptions: {
                        dynamicEntity: EM.organizations
                    }
                },
                {                
                    dataField: 'Region',
                    text: entity.columns('region'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                    
                }, 
                {                
                    dataField: 'SubRegion',
                    text: entity.columns('subRegion'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                     
                }, 
                {                
                    dataField: 'Email',
                    text: entity.columns('email'),
                    width: 150,
                    filterOptions: {
                        text: true
                    }               
                },                                                
                {                
                    dataField: 'EmployeeNumber',
                    text: entity.columns('employeeNumber'),
                    width: 100,
                    filterOptions: {
                        text: true
                    }               
                },                
                {                
                    dataField: 'ManagerNumber',
                    text: entity.columns('managerNumber'),
                    width: 100,
                    filterOptions: {
                        text: true
                    }                   
                },
                {                
                    dataField: 'CostCenter',
                    text: entity.columns('costCenter'),
                    width: 100,
                    filterOptions: {
                        text: true
                    }             
                },
                {                
                    dataField: 'TimeAllocation',
                    text: entity.columns('workTimePercentage'),
                    width: 75,
                    csvType: Number,
                    asPercentage: true,
                    defaultPercentage: 100,
                    validators: [ Validators.number ],
                    filterOptions: {
                        number: true
                    }  
                },
                {                
                    dataField: 'BillingRate',
                    text: entity.columns('billingRate'),
                    width: 75,
                    csvType: Number,
                    validators: [ Validators.number ],
                    filterOptions: {
                        number: true
                    }  
                },
                {                
                    dataField: 'Contractor',
                    text: entity.columns('contractor'),
                    asActive: true,
                    width: 75                    
                },
                {                
                    dataField: 'EmployeeType',
                    text: entity.columns('employeeType'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }              
                },  
                {                
                    dataField: 'PositionStatus',
                    text: entity.columns('positionStatus'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }  
                }, 
                {                
                    dataField: 'Custom1',
                    text: entity.columns('custom1'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }          
                },
                {                
                    dataField: 'Custom2',
                    text: entity.columns('custom2'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                
                },
                {                
                    dataField: 'Custom3',
                    text: entity.columns('custom3'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                      
                },
                {                
                    dataField: 'Custom4',
                    text: entity.columns('custom4'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                      
                },
                {                
                    dataField: 'Custom5',
                    text: entity.columns('custom5'),
                    width: 125,
                    filterOptions: {
                        text: true
                    }                      
                },                                                                                                                
                {                
                    dataField: 'Active',
                    text: entity.columns('active'),
                    asActive: true,
                    width: 125,
                    unhideable: true                    
                },                        
            ].map(col => {                
                if (hiddenCols.indexOf(col.dataField) > -1 && !col.unhideable){
                    col.hidden = true;
                }
                return col;
            });
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable 
                    entity={entity} 
                    data={data} 
                    columns={columns} 
                    showColumnToggle={true} 
                    onColumnToggle={(cols) => {
                        this.prefs.hiddenColumns = Object.keys(cols).filter(key => !cols[key]);
                        this.setState({ ts: new Date() });
                    }}
                    className="table-overflow-container"/>
            </div>
        );
    }
}

export default Employees;