/* global EM */
import React, { Component } from 'react';
import Spinner from '../Spinner';

export default class NoTableData extends Component {
    render() {
        return (
            <div className={this.props.className}> 
                { this.props.data ? 
                    EM.t('util.table.noData') 
                : 
                    <Spinner text={EM.t('util.loading')} classes="small" />
                } 
            </div>
        );
    }
};