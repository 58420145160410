/* global EM */
import React, { Component } from 'react';
import { Button, ButtonGroup, Label, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';
import Dates from '../../util/Dates';
export default class BudgetToolsDatasetOptions extends Component {
    render() {
        let prefs = this.props.datasetPrefs;
        let isSelected = this.props.selected.value;

        let flgs = {};
        if (this.props.selected.group === 'headcounts') {
            flgs.r = true;
            flgs.a = false;
            flgs.p = true;
        };
        if (this.props.selected.group === 'actuals') {
            flgs.r = true;
            flgs.a = true;
            flgs.p = true;
        };
        if (!isSelected) {
            flgs.r = true;
            flgs.a = true;
            flgs.p = true;
        }

        return (
            <div>
                <FormGroup>
                    <Label>{this.props.title}:</Label>
                    <Select
                        className="single-select"
                        classNamePrefix="single-select"
                        isSearchable={true}
                        options={this.props.datasets.map(item => 
                            item.label === 'Budgets' 
                                ? { ...item, label: 'Budget' } 
                                : item
                        )}
                        value={this.props.selected}
                        placeholder={EM.t('util.filtering.placeholderDefault')}
                        isDisabled={this.props.isDisabled}
                        onChange={(selectedItem) => {
                            prefs.dataset = selectedItem;
                            this.props.onChange();
                        }}
                    />

                    <FormText color="muted">{Dates.isoToDateTime(this.props.selected.createdOn)}</FormText>
                </FormGroup>
                {this.props.isDisabled ? null :
                    <ButtonGroup>
                        <Button active={prefs.chartType === 'Bar'} onClick={() => {
                            prefs.chartType = 'Bar';
                            this.props.onChange();
                        }}><i className="far fa-chart-bar btn-icon"></i></Button>
                    </ButtonGroup>
                }
            </div>
        );
    }
}