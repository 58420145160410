/* global EM */
import React, { Component } from 'react';

export default class ContactSupportButton extends Component {
    render() {
        return (
            <div className={"clearfix mt-4 mb-1 text-center " + (this.props.className ? this.props.className : '')}>
                <button className="btn btn-light btn-sm" onClick={() => {
                    window.open('https://intrinsiccs.atlassian.net/servicedesk/customer/portal/2');
                }}>{EM.t('help.support')}</button>
            </div>
        );
    };
}
