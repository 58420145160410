/* global EM */

export default class BudgetProjectionsManager {
    constructor() {
        this.dataSetCache = null;
    }

    getAvailableDataSets() {
        if (!this.dataSetCache) {
            let schedule = EM.schedules.get();
            let value = [];
            schedule.forEach(schedule => {
                schedule.Projections?.forEach(projection => {
                    if (projection.Budgets && projection.Budgets.length > 0) {
                        let budgets = projection.Budgets?.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))[0];
                        value.push({
                            ...budgets,
                            ...schedule
                        }
                        );
                    }
                });
            });

            function remap(entity) {
                let def = {
                    id: 'default',
                    value: 'default:' + entity.name,
                    group: entity.name,
                    label: 'Default ' + entity.modelNameSingular,
                    isDefault: true
                };
                return [def, ...entity.get().map(item => {
                    let id = item[entity.idField];
                    let budgets = value.filter(x => x.BudgetId == id)
                    if (budgets.length == 0) return null;

                    let hasProj = item.Projections && item.Projections.length > 0;
                    return {
                        id: id,
                        value: entity.name + id,
                        group: entity.name,
                        label: value.filter(x => x.BudgetId == id)[0]?.Name,  // Ensure we always return a valid schedule name
                        isDefault: value.filter(x => x.BudgetId == id)[0]?.IsDefault,
                        createdOn: hasProj ? item.Projections[0].CreatedOn : item.CreatedOn
                    };
                }).filter(item => item !== null)];

            }

            let output = [];
            output.push({ label: EM.budgets.t('title'), options: remap(EM.budgets) });
            this.dataSetCache = output;
        }

        return this.dataSetCache;
    }

    async loadDefaultProjections() {
        let projectionsOverride = EM.getSetting('ManualProjectionOverride');
        if (projectionsOverride) {
            return await this.loadDataset({ group: 'actuals', id: projectionsOverride });
        } else {
            return await this.loadDataset({ group: 'schedules', id: 'default' });
        }
    }

    async loadDataset(dataset) {
        if (dataset.value === null) {
            return null;
        } else {
            let entityClean = dataset.group.toLowerCase();;
            if (!EM[entityClean]) throw new Error('Entity not found: ' + entityClean);

            let item = null;
            let budget = null;
            if (dataset.id === 'default') {
                entityClean = "schedules"
                budget = EM[entityClean].findDefaultItem();
                budget?.Projections?.forEach(projection => {
                    if (projection.Budgets && projection.Budgets.length > 0) {
                        item = projection.Budgets?.sort((a, b) => new Date(b.CreatedOn) - new Date(a.CreatedOn))[0];
                    }
                });
            } else {
                item = EM[entityClean].byId(dataset.id);
            }
            if (!item) throw new Error('Item not found: ' + entityClean + ' - ' + dataset.id);
            let method = '_' + (entityClean ? 'budgetsLoad' : entityClean + 'Load');
            return await this[method](item).catch(err => {
                console.warn(err);
            });
        }
    }

    async loadDatasetsFromPrefs(preferences, defaultScheduleId) {
        let datasets = await this._getDatasetsFromPrefs(preferences, defaultScheduleId);
        return datasets;
    }

    async _getDatasetsFromPrefs(preferences, defaultScheduleId) {
        let primary = null;
        let pData = preferences.primary.dataset;
        if (pData && pData.id !== null) {
            primary = await this.loadDataset(pData).catch((ex) => {
                EM.warn('Failed to load primary dataset: ' + ex.message);
            });
        } else {
            if(!defaultScheduleId) return {primary:undefined,secondary:null,tertiary:null};
            primary = await this.loadDataset({ group: 'budgets', id: defaultScheduleId }).catch((ex) => {
                EM.warn('Failed to load primary dataset: ' + ex.message);
            });
        }

        let secondary = null;
        let sData = preferences.secondary.dataset;
        if (sData) {
            secondary = await this.loadDataset(sData).catch((ex) => {
                EM.warn('Failed to load secondary dataset: ' + ex.message);
            });
        }

        let tertiary = null;
        let tData = preferences.tertiary.dataset;
        if (tData) {
            tertiary = await this.loadDataset(tData).catch((ex) => {
                EM.warn('Failed to load tertiary dataset: ' + ex.message);
            });
        }

        return { primary, secondary, tertiary };
    }

    async _budgetsLoad(budget) { 
        let schedule = EM.schedules.get();
        let ScheduleName = schedule
            .filter(schedule =>
                schedule.Projections.some(projection =>
                    projection.Budgets.some(x => x.BudgetId === budget.BudgetId)
                )
            )
            .map(schedule => schedule.Name);
        let domain = EM.getActiveDomain();
        let domainIdToSearch = domain.ParentDomainId ? domain.ParentDomainId : domain.DomainId;
        if (EM.budgets.isOverridden()) domainIdToSearch = domain.DomainId;
        let newestBudgets = budget.Status.toLowerCase().indexOf('success') === 0 && budget.DomainId === domainIdToSearch ? budget : null;

        if (!newestBudgets) {
            throw new Error('No projections found.');
        }

        await EM.budgets.loadFile(budget.BudgetId);

        let projections = EM.budgets.getFile(budget.BudgetId);

        if (projections) {
            projections.name = ScheduleName.length > 0 && ScheduleName[0];
        }
        return projections;
    }
}