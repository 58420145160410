/* global EM */
import React, { Component } from 'react';
import DemandDifferenceTable from '../components/Demand/DemandDifferenceTable';
import DemandGraph from '../components/Demand/DemandGraph';
import DemandGraphLegend from '../components/Demand/DemandGraphLegend';
import DemandListingTable from '../components/Demand/DemandListingTable';
import PageTitle from '../components/PageTitle';
import DemandPreferences from '../entities/preferences/DemandPreferences';
import ProjectionsManager from '../entities/ProjectionsManager';
import ReportConfiguration from '../entities/reports/ReportConfiguration';
import PublishOptionsDialog from '../components/PublishOptionsDialog';
import ReportDetailsDialog from '../components/ReportDetailsDialog';
import Routes from '../app/Routes';

export default class DemandReport extends Component {
    constructor(props) {
        super(props, DemandPreferences);
        
        this.state = {
            config: null, 
            preferences: null,            
            primary: null,
            secondary: null,
            tertiary: null,
            selectedSeries: null,
            publishModalOpen: false
        }

        this._isMounted = false;
        this.PM = new ProjectionsManager(this);
        this.demandGraphRef = React.createRef();

        this.onPrint = this.onPrint.bind(this);    
        this.onPublish = this.onPublish.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onPublishModalClose = this.onPublishModalClose.bind(this);      
    }

    componentDidMount() {
        this._isMounted = true;

        let self = this;
        EM.loadEntities([EM.configurations, EM.configurationTypeLookup, EM.roles, EM.organizations, EM.departments, EM.activities, EM.schedules, EM.headcounts, EM.actuals]).then(() => {
            self.onLoadInitial();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadInitial() {
        let self = this;
        let config = ReportConfiguration.fromQueryString(DemandPreferences);
        this.setState({ config: config, preferences: config.baseConfig});            

        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule) return;
            this.defaultSchedule = defaultSchedule;

            try {
                this.PM.loadDatasetsFromPrefs(self.state.preferences, this.defaultSchedule.ScheduleId).then(datasets => {
                    this.setState(datasets);
                });
            } catch (err) {
                EM.warn(err);
            }
        });
    }

    onPrint(){
        window.setTimeout(() => {
            let chart = this.demandGraphRef.current.chart;
            chart.tooltip.destroy();
            window.print();  
        }, 0);
    }

    onPublish(options){
        let self = this;
        let type = EM.configurationTypeLookup.findByKey('DemandReport');
        if (!options)return;
        if (this.state.config.storedConfiguration){
            let newConfig = Object.assign(this.state.config.storedConfiguration, options);
            EM.configurations.update(this.state.config.storedConfiguration, newConfig).then((newObj)=>{
                let config = ReportConfiguration.fromQueryString(DemandPreferences);
                self.setState({ config: config });
                self.onPublishModalClose();
            });
        }else{
            let newConfig = Object.assign({ Value: this.state.config.asJSON(), ConfigurationTypeLookupId: type.ConfigurationTypeLookupId }, options);
            EM.configurations.create(newConfig).then((newObj)=>{
                let reportAddress = Routes.compose(Routes.client.dashboard, { DomainName: this.props.domain.Name });
                this.props.history.push(reportAddress);
            });
        }
    }

    onDelete(){
        let self = this;
        self.onPublishModalClose();
        EM.configurations.delete([this.state.config.storedConfiguration.ConfigurationId]).then(()=>{
            let reportAddress = Routes.compose(Routes.client.dashboard, { DomainName: this.props.domain.Name });
            this.props.history.push(reportAddress);
        });
    }

    onPublishModalClose(){
        this.setState({ publishModalOpen: false });
    }

    onLegendItemClicked(seriesClicked, isFocusMode){
        if (this.demandGraphRef.current)this.demandGraphRef.current.onLegendItemClicked(seriesClicked, isFocusMode);
        this.setState({ selectedSeries: seriesClicked });
    }

    onLegendItemsToggled(visible){
        if (this.demandGraphRef.current)this.demandGraphRef.current.onLegendItemsToggled(visible);
    }    

    render() {
        let readyToRender = EM.allLoaded(EM.configurations, EM.activities, EM.roles, EM.organizations, EM.departments, EM.schedules, EM.headcounts, EM.actuals);
        if (!readyToRender) return null;
        if (!this.state.config)return null;
        let tableType = this.state.config.options.tableType ? this.state.config.options.tableType.toLowerCase() : 'listing';

        return (
            <div key="contents" className={"page page-visualization container-fluid "}>
                <PageTitle icon={this.state.config.icon} title={this.state.config.name + (this.state.selectedSeries?' - ' + this.state.selectedSeries.name:'')} bar={true}>
                    {this.state.config?
                        <div className="btn-group">
                            <button className="btn btn-light btn-sm" onClick={() => {
                                this.setState({ reportDefinitionOpen: true });
                            }}>
                                <i className="fas fa-info-circle"></i> {EM.t('reports.publishing.viewDefinition')}
                            </button>
                        </div>
                    : null }
                    {this.state.config.source === 'configuration' && (EM.isMyEntity(this.state.config.storedConfiguration) || EM.isDomainResourceManager())? 
                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                this.setState({ publishModalOpen: true });
                            }}>
                                <i className="far fa-edit"></i> {EM.t('reports.publishing.edit')}
                            </button>
                        </div>
                    : null }
                    {EM.isDomainEditor() && this.state.config.source === 'custom' ? 
                        <div className="btn-group">
                            <button className="btn btn-success btn-sm" onClick={() => {
                                this.setState({ publishModalOpen: true });
                            }}>
                                <i className="fas fa-save"></i> {EM.t('reports.publishing.publish')}
                            </button>
                        </div>
                    : null }
                    <div className="btn-group">       
                        <button className="btn btn-secondary btn-sm" onClick={this.onPrint}>
                            <i className="fas fa-print"></i> {EM.t('reports.publishing.print')}
                        </button>                
                    </div>                    
                </PageTitle>
                <div className={"demand-container report-mode " + (this.state.config.options.showChart ? '' : 'no-chart')}>
                    <DemandGraphLegend 
                        isReportMode={true}
                        preferences={this.state.config.baseConfig} 
                        onLegendItemClicked={this.onLegendItemClicked.bind(this)}
                        onLegendItemsToggled={this.onLegendItemsToggled.bind(this)}
                    /> 
                    {this.state.config.options.showChart ? 
                        <DemandGraph
                            isReportMode={true}
                            ref={this.demandGraphRef}
                            preferences={this.state.config.baseConfig}
                            primary={this.state.primary}
                            secondary={this.state.secondary}
                            tertiary={this.state.tertiary}
                            onPointSelected={this.onPointSelected}                            
                            selectedSeries={this.state.selectedSeries}
                            printSize={[1000, 250]}
                        />
                    : null }
                    {tableType === 'primarydifference' || tableType === 'difference' ?
                        <DemandDifferenceTable
                            preferences={this.state.config.baseConfig}
                            primary={this.state.primary}
                            secondary={this.state.secondary}
                            tertiary={this.state.tertiary}
                            selectedSeries={this.state.selectedSeries}
                        />
                    : null }
                    {tableType === 'listing' ?
                        <DemandListingTable
                            preferences={this.state.config.baseConfig}
                            primary={this.state.primary}
                            secondary={this.state.secondary}
                            tertiary={this.state.tertiary}
                            selectedSeries={this.state.selectedSeries}
                        />
                    : null }            

                    <PublishOptionsDialog 
                        onClose={this.onPublishModalClose}
                        isOpen={this.state.publishModalOpen}
                        onPublish={this.onPublish}    
                        onDelete={this.onDelete} 
                        configuration={this.state.config.storedConfiguration}                
                    />

                    <ReportDetailsDialog
                        onClose={() => {
                            this.setState({ reportDefinitionOpen: false });
                        }}
                        isOpen={this.state.reportDefinitionOpen}       
                        configuration={this.state.config.storedConfiguration || { Name: this.state.config.name, Description: null }}   
                        reportOptions={this.state.config.baseConfig.inst}
                        i18nKeyBase="demand.tools"
                    />        
                </div>
            </div>
        );
    }
}
