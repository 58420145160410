/* global EM */
/* eslint no-return-assign: 0 */
import React, { Component } from 'react';
import Creatable from 'react-select/creatable';

export default class AssignmentLabelEditField extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            labelList: [],
            currentValue: this.props.value || ''
        }
    }

    componentDidMount() {
        let labelList = []; 
        let labelSetting = EM.getSetting('AdditionalLabels');  
        if (labelSetting){
            labelSetting.split(',').forEach((label) => {
                labelList.push({ value: label.trim(), label: label.trim() });
            });
        }   

        this.setState({ labelList });
    }

    render() {
        let cv = this.state.currentValue;
        let value = cv ? { value: cv, label: cv } : this.props.value;

        return (
            <Creatable
                className="single-select"
                classNamePrefix="single-select"
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                options={this.state.labelList}
                placeholder={EM.t('util.filtering.placeholderDefault')}
                formatCreateLabel={(input) => { return EM.t('util.select-create', null, [input]) }}
                value={value} 
                ref={this.props.innerRef}        
                onChange={(selected) => {
                    this.setState({ currentValue: selected ? selected.value.trim() : null });
                    if (this.props.onChange)this.props.onChange(selected);
                    if (this.props.onUpdate)this.props.onUpdate(selected ? selected.value.trim() : null);
                }}  
            />
        );
    }
}