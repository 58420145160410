import React from 'react';
import { Label, FormGroup, Input, FormText } from 'reactstrap';

export default function BooleanField(props){        
    return (
        <FormGroup>      
            <Label title={props.description} check>
                <Input type="checkbox" 
                    className="position-static ml-0"
                    checked={props.value}
                    onChange={event => props.onChange(props.name, event.target.checked)} 
                /> {props.display || props.name}
            </Label>
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null }             
        </FormGroup>
    );
}