/* global EM */
import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import MonthPicker from '../MonthPicker';
import Dates from '../../util/Dates';
import Select from 'react-select';
import StaffingEmployeePreferences from '../../entities/preferences/StaffingEmployeePreferences';
import FilterSelection from '../FilterSelection';
import CannedDateRangeSelector from '../CannedDateRangeSelector';
import OptionSwitcher from '../OptionSwitcher';

export default class StaffingEmployeeToolsPanel extends Component {
    constructor(props) {
        super(props);

        this.defaultPrefs =  new StaffingEmployeePreferences('default');
        this.state = {
            currentPreferences: this.defaultPrefs
        }

        this.prefs = this.defaultPrefs.group;
        this.onChange = this.onChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.addResetPreferences = this.addResetPreferences.bind(this);
        this.setPreferences = this.setPreferences.bind(this);
        this.deletePreferences = this.deletePreferences.bind(this);

        this.hiddenColumns = [];
        let hiddenColumnsStr = EM.getSetting('HiddenEmployeeFilters');
        if (hiddenColumnsStr){
            this.hiddenColumns = hiddenColumnsStr.split(',');
        }
    }

    addResetPreferences(name, startFromCurrent) {
        let self = this;
        let newSet = startFromCurrent ? self.state.currentPreferences.clone().inst : {};
        self.prefs(name, newSet);
        self.setPreferences(name);       
    }

    deletePreferences(name){
        let self = this;
        self.prefs(name, null, true);
        self.setPreferences('default');
    }

    setPreferences(name, suppressNotification) {
        let self = this;
        let newPrefs = new StaffingEmployeePreferences(name);
        self.setState({ currentPreferences: newPrefs });
        if (!suppressNotification) self.props.onChange(newPrefs);
    }    

    onChange() {
        let self = this;
        let newPrefs = self.state.currentPreferences.clone();  
        self.setState({ currentPreferences: newPrefs }, () => {
            window.setTimeout(() => {
                self.props.onChange(newPrefs);
            }, 100);
        });        
    }

    onTabChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        let preferences = this.state.currentPreferences;

        let optSets = [{ label: 'Default', value: 'default' }, ...this.prefs().map(key => {
            if (key === 'default') return null;
            return { label: key, value: key }
        }).filter((el) => el != null)];
        let selectedOptSet = optSets.find(os => os.value === preferences.name);
            
        let beginDate = preferences.begin ? Dates.fromStr(preferences.begin, Dates.DATE_PREF) : null;        
        let endDate = preferences.end ? Dates.fromStr(preferences.end, Dates.DATE_PREF) : null;
        
        let groupings = [
            { label: 'None', value: null },
            { value: 'JobTitle', label: EM.t('employees.columns.jobTitle') },
            { value: 'Role', label: EM.t('employees.columns.role') },
            { value: 'Department', label: EM.t('employees.columns.department') },
            { value: 'Organization', label: EM.t('employees.columns.organization') },
            { value: 'Region', label: EM.t('employees.columns.region') },
            { value: 'SubRegion', label: EM.t('employees.columns.subRegion') },
            { value: 'Email', label: EM.t('employees.columns.email') },
            { value: 'EmployeeNumber', label: EM.t('employees.columns.employeeNumber') },
            { value: 'ManagerNumber', label: EM.t('employees.columns.managerNumber') },
            { value: 'CostCenter', label: EM.t('employees.columns.costCenter') },
            { value: 'EmployeeType', label: EM.t('employees.columns.employeeType') },
            { value: 'PositionStatus', label: EM.t('employees.columns.positionStatus') },
            { value: 'Contractor', label: EM.t('employees.columns.contractor') },
            { value: 'Custom1', label: EM.t('employees.columns.custom1') },
            { value: 'Custom2', label: EM.t('employees.columns.custom2') },
            { value: 'Custom3', label: EM.t('employees.columns.custom3') },
            { value: 'Custom4', label: EM.t('employees.columns.custom4') },
            { value: 'Custom5', label: EM.t('employees.columns.custom5') },
        ];

        let selectedGrouping = groupings.find((grp) => {
            return grp.value === preferences.grouping;
        });

        let employeeSets = [
            { label: EM.t('staffing.tools.activeEmployees'), value: 'Active' },
            { label: EM.t('staffing.tools.inactiveEmployees'), value: 'Inactive' },
            { label: EM.t('staffing.tools.allEmployees'), value: 'All' },
        ];

        let selectedEmployeeSet = employeeSets.find((set) => {
            return set.value === preferences.employeeSet;
        });

        return (
            <div className="tools-panel">
                <div className="tools-panel-header">
                    <h5>{EM.t('staffing.tools.title')}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.onFilterClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="tools-panel-content">                                      
                    <OptionSwitcher 
                        options={optSets}
                        value={selectedOptSet}
                        addResetPreferences={this.addResetPreferences} 
                        deletePreferences={this.deletePreferences}
                        onChange={this.setPreferences}
                    />                                                  
                    <FormGroup>
                        <label>{EM.t('staffing.tools.dateRange')}:</label><br />
                        <div className="month-range">
                            <MonthPicker
                                placeholder={EM.t('staffing.tools.from')}
                                date={beginDate}
                                onChange={(date) => {
                                    preferences.begin = date.toFormat(Dates.DATE_PREF);
                                    this.onChange();
                                }}
                            />
                            <MonthPicker
                                placeholder={EM.t('staffing.tools.to')}
                                date={endDate}
                                onChange={(date) => {
                                    preferences.end = date.toFormat(Dates.DATE_PREF);
                                    this.onChange();
                                }}
                            />
                            <CannedDateRangeSelector 
                                onSelect={(begin, end) => {
                                    preferences.begin = begin ? begin.toFormat(Dates.DATE_PREF) : null;
                                    preferences.end = end ? end.toFormat(Dates.DATE_PREF) : null;
                                    this.onChange();
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <label>{EM.t('staffing.tools.grouping')}:</label><br />
                        <Select
                            className="single-select"
                            classNamePrefix="single-select"
                            isSearchable={true}
                            options={groupings}
                            value={selectedGrouping}
                            placeholder={EM.t('util.filtering.placeholderDefault')}
                            onChange={(selectedItem) => {
                                preferences.grouping = selectedItem.value;
                                this.onChange();
                            }}
                        /> 
                    </FormGroup>
                    <hr/>
                    <div>
                        {Object.keys(this.props.filterValues).map((filterKey, index) => {
                            if (this.hiddenColumns.indexOf(filterKey) > -1)return null;
                            return <FilterSelection
                                selected={preferences.get(filterKey)}
                                key={filterKey}
                                options={this.props.filterValues[filterKey]}
                                label={EM.t('employees.columns.' + filterKey.decapitalize())}
                                onChange={(values) => { 
                                    preferences.set(filterKey, values);
                                    this.onChange();
                                }} />
                        })}
                    </div>
                    <div className="inputs-inline">                                
                        <FormGroup>
                            <label>{EM.t('staffing.tools.employeeSet')}:</label><br />
                            <Select
                                className="single-select"
                                classNamePrefix="single-select"
                                isSearchable={false}
                                options={employeeSets}
                                value={selectedEmployeeSet}
                                placeholder={EM.t('util.filtering.placeholderDefault')}
                                onChange={(selectedItem) => {
                                    preferences.employeeSet = selectedItem.value;
                                    this.onChange();
                                }}
                            />
                        </FormGroup>
                    </div>                    
                </div>
            </div>
        );
    }
}
