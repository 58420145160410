/* global EM */
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Routes from './Routes';
import { withRouter, matchPath } from "react-router";
import { connect } from "react-redux";
import Header from '../components/Header.js';
import Home from '../pages/Home';
import Domain from '../pages/Domain';
import Admin from '../pages/Admin';
import NotFound from '../pages/NotFound';
import HelpPanel from '../components/Help/HelpPanel';
import Help from '../pages/Help';
import HelpManual from '../pages/HelpManual';
import FetchIndicator from '../components/FetchIndicator';
import ErrorIndicator from '../components/ErrorIndicator';
import TemporaryStatusMessage from '../components/TemporaryStatusMessage';
import PreferenceManager from '../entities/preferences/PreferenceManager';
import TermsOfService from '../components/TermsOfService';
import ErrorBoundary from "../components/ErrorBoundary";

const HeaderComp = withRouter(Header);
const HomePage = withRouter(Home);
const DomainPage = withRouter(Domain);
const AdminPage = withRouter(Admin);
const HelpPanelComp = withRouter(HelpPanel);
const HelpPage = withRouter(Help);
const HelpManualPage = withRouter(HelpManual);

class App extends Component {
    constructor(props) {
        super(props);

        this.prefs = new PreferenceManager('app');
        this.isHelpOpen = this.prefs.asFlag('help', 'open');

        this.state = {
            helpMode: this.isHelpOpen()
        }        

        this.onHelpClick = this.onHelpClick.bind(this);
    }

    onHelpClick(){
        let newState = !this.state.helpMode;
        this.isHelpOpen(newState);
        this.setState({ helpMode: newState });
        EM.helpMode = newState;
        EM.triggerWindowResize();       
    }

    render() {
        if (!EM.hasAcceptedTerms()){
            return <TermsOfService account={this.props.account} />
        }else{
            const isHelpPage = !!matchPath(window.location.pathname, Routes.client.help) || !!matchPath(window.location.pathname, Routes.client.manual);  
            return (         
                <BrowserRouter key="router">  
                    <div id="page-container" className={'page-container ' + (EM.currentTheme === 'Dark' ? 'dark-mode ' : ' ') + (this.state.helpMode && !isHelpPage?'help-mode':'')}>
                        <HeaderComp {...this.props} onHelpClick={this.onHelpClick} isHelpMode={this.state.helpMode} />
                        <ErrorBoundary>
                            <Switch>
                                <Route exact path={Routes.client.home} render={() => <HomePage {...this.props} />} />
                                <Route path={Routes.admin.home}  render={() => <AdminPage {...this.props} />} />
                                <Route path={Routes.client.help} component={HelpPage} />
                                <Route path={Routes.client.manual} component={HelpManualPage} />
                                <Route path={Routes.client.notFound} component={NotFound} />
                                <Route path={Routes.client.domain}  render={() => <DomainPage {...this.props} />} />
                                <Route component={NotFound} />
                            </Switch>  
                        </ErrorBoundary>
                        { !isHelpPage && this.state.helpMode ? 
                            <HelpPanelComp onHelpClick={this.onHelpClick} notes={this.props.notes}></HelpPanelComp>
                        : null }
                        <TemporaryStatusMessage ref={EM.getStatusMessageRef(React)}/>
                        <FetchIndicator items={this.props.fetching} />  
                        <ErrorIndicator error={this.props.error} />
                    </div>
                </BrowserRouter>         
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.account,
        activities: state.activities,
        complexities: state.complexities,
        complexityLookup: state.complexityLookup,
        departments: state.departments,
        domain: state.domain,
        domainState: state.domainState,
        error: state.error,
        fetching: state.fetching,
        organizations: state.organizations,
        roles: state.roles,
        files: state.files,
        notes: state.notes,
        notifications: state.notifications
    };
};

export default connect(mapStateToProps, null)(App);