/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';
import Dates from '../util/Dates';
import { Workbook } from '../util/Workbook';
import PanelModal from '../components/PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpleJsonObjectEditor from '../components/JsonEditor/SimpleJsonObjectEditor';
import PageTitle from '../components/PageTitle';
import PieChart from '../components/PieChart';
import ColorManager from '../util/ColorManager';

export default class AdminTenants extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingFeature: null,
            featureEditorProps: null,
            newFeatureStr: '',
            editingOptions: null,
            optionsEditorProps: null,
            newOptionsStr: '',
            uptimePercent: 100
        };

        EM.clearActiveDomain();
        EM.tenants.load(false, '*');
        EM.users.load();

        this.runUptimeReport = this.runUptimeReport.bind(this);
        this.onCloseFeatures = this.onCloseFeatures.bind(this);
        this.onSaveFeatures = this.onSaveFeatures.bind(this);
        this.onCloseOptions = this.onCloseOptions.bind(this);
        this.onSaveOptions = this.onSaveOptions.bind(this);
        
        this.defaultSort= {
            dataField: 'Active',
            order: 'desc'
        };
    }

    async runUptimeReport(fromDate, toDate, justData){    
        let failedPings = await EM.api.uptimeReport(fromDate.toISO());
        if (!failedPings)return;
        let filteredPings = failedPings.filter(ping => {
            let ts = Dates.fromISO(ping.Timestamp);
            return ts > fromDate && ts < toDate;
        });

        let duration = toDate.diff(fromDate, 'minutes');
        let mins = duration.values.minutes;
        let periods = Math.floor(mins / 15);
        let decimalUptime  = 1 - (filteredPings.length / periods);
        let percentageUptime = (decimalUptime * 100).toFixed(6);

        if (justData){
            return percentageUptime;
        }else{
            new Workbook((wb) => {
                wb.addWorksheet('Uptime Summary', [ 
                    { data: [ 'From', 'To', 'Percentage', '# of Measurements', '# of Failures' ], style: 'header' }, 
                    { data: [ fromDate.toLocaleString(), toDate.toLocaleString(), percentageUptime + '%', periods, filteredPings.length ] }
                ]);
                wb.save('rm-uptime-report');
            });
        }
    }

    onSaveFeatures(){        
        if (this.state.featureEditorProps && this.state.editingFeature && this.state.newFeatureStr){            
            let jsonObj;
            try{
                jsonObj = JSON.parse(this.state.newFeatureStr);
            }catch(e){}

            if (!jsonObj && this.state.newFeatureStr){
                alert('Invalid JSON.');
                return;
            }

            this.state.featureEditorProps.onUpdate(this.state.newFeatureStr);        
        }        
        this.onCloseFeatures();
    }

    onCloseFeatures(){
        if (this.state.featureEditorProps){
            try{
                this.state.featureEditorProps.onBlur();
            }catch(e){}
        }
        this.setState({ editingFeature: null, featureEditorProps: null, newFeatureStr: '' });
    }

    onSaveOptions(){        
        if (this.state.optionsEditorProps && this.state.editingOptions && this.state.newOptionsStr){            
            let jsonObj;
            try{
                jsonObj = JSON.parse(this.state.newOptionsStr);
            }catch(e){}

            if (!jsonObj && this.state.newOptionsStr){
                alert('Invalid JSON.');
                return;
            }

            this.state.optionsEditorProps.onUpdate(this.state.newOptionsStr);        
        }        
        this.onCloseOptions();
    }

    onCloseOptions(){
        if (this.state.optionsEditorProps){
            try{
                this.state.optionsEditorProps.onBlur();
            }catch(e){}
        }
        this.setState({ editingOptions: null, optionsEditorProps: null, newOptionsStr: '' });
    }    

    componentDidMount(){
        if (!EM.isSuperAdmin())return EM.denyFeature();

        let startDate = Dates.now().plus({ days: -30 });
        this.runUptimeReport(startDate, Dates.now(), true).then((uptimePercent) => {
            this.setState({ uptimePercent: parseInt(uptimePercent) });
        });
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.tenants;

        let isTrialModeFunc = (cell, row) => { 
            if (!row.Options)return null;
            try{
                let tmp = JSON.parse(row.Options);                        
                return tmp.isTrialMode ? <span className='badge badge-success'>Trial</span> : null;
            }catch(e){
                return null;
            }
        };

        let licenseCountFunc = (cell, row) => {
            if (!row.Options)return null;
            try{
                let tmp = JSON.parse(row.Options);                        
                return tmp.licenseCount;
            }catch(e){
                return null;
            }
        };

        if (EM.allLoaded(entity, EM.users)) {
            data = entity.get();
            columns = [{
                dataField: 'DisplayName',
                width: 200,
                text: entity.columns('displayName'),
                validators: [Validators.required],
                filterOptions: {
                    text: true
                }  
            }, {
                dataField: 'Name',
                width: 150,
                text: entity.columns('name'),
                validators: [ Validators.required, Validators.tenantName ],
                editable: false,
                description: 'Cannot be changed after creation.',
                filterOptions: {
                    text: true
                }  
            }, {
                dataField: 'isTrialMode',
                width: 75,
                text: 'Trial?',
                isReference: true,
                formatter: isTrialModeFunc,
                filterValue: (cell, row) => !!isTrialModeFunc(cell, row),
                filterOptions: {
                    bool: true
                },
                sortValue: (cell, row) => !!isTrialModeFunc(cell, row)
            },{
                dataField: 'licenseCount',
                width: 125,
                text: 'Licenses',
                isReference: true,
                formatter: licenseCountFunc,
                filterValue: licenseCountFunc,
                filterOptions: {
                    text: true
                },
                sortValue: licenseCountFunc
            },{
                dataField: 'Features',
                text: entity.columns('features'),
                asFeature: true,  
                width: 300,              
                onStartEdit: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    window.setTimeout(() => {
                        this.setState({ editingFeature: row, featureEditorProps: editorProps });
                    }, 0);                    
                },
            }, {
                dataField: 'Options',
                text: entity.columns('options'), 
                width: 75,
                asOptions: true,           
                onStartEdit: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    window.setTimeout(() => {
                        this.setState({ editingOptions: row, optionsEditorProps: editorProps });
                    }, 0);                    
                },
            }, {
                dataField: 'Active',
                text: entity.columns('active'),
                asActive: true,
                width: 75
            }];
        }

        let currentFeature = this.state.editingFeature;
        let currentFeatureConfigObj = JSON.parse(currentFeature ? (currentFeature.Features || '{}') : '{}');

        let currentOptions = this.state.editingOptions;
        let currentOptionsConfigObj = JSON.parse(currentOptions ? (currentOptions.Options || '{}') : '{}');

        let uptime = this.state.uptimePercent;
        let downtime = 100 - this.state.uptimePercent;

        return (
            <div key="contents" className="page container-fluid">
                <PageTitle icon="fas fa-building" title={entity.t('title')} bar={true} />
                <div className="pre-table pre-table-content">
                    {data ? 
                        <PieChart id="uptime" seriesName="Uptime" width={120} height={120} data={[
                            { name: 'Up', y: uptime, color: ColorManager.COLORS[4] },
                            downtime > 0 ? { name: 'Down', y: downtime, color: ColorManager.COLORS[0] } : null,
                        ]}/>
                    : null }
                    <div className="pl-2">
                        <h5>30-Day Uptime Percentage</h5>
                        <h6><i className="fas fa-circle font-sm" style={{color: ColorManager.COLORS[4]}}></i> Uptime: {uptime}%</h6>
                        <h6><i className="fas fa-circle font-sm" style={{color: ColorManager.COLORS[0]}}></i> Downtime: {downtime}%</h6>
                    </div>
                    <div className="ml-auto text-center">
                        <div className="btn-group">       
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                let startDate = Dates.now().plus({ days: -30 });
                                this.runUptimeReport(startDate, Dates.now());
                            }}>30-Day Uptime</button> 
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                let startOfMonth = Dates.now().startOf('month');
                                this.runUptimeReport(startOfMonth, Dates.now());
                            }}>Month-to-Date Uptime</button> 
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                let startOfMonth = Dates.now().plus({ months: -1 }).startOf('month');
                                let endOfMonth = Dates.now().plus({ months: -1 }).endOf('month');
                                this.runUptimeReport(startOfMonth, endOfMonth);
                            }}>Previous Month Uptime</button>    
                        </div>                        
                    </div>
                </div>                
                <EntityTable 
                    ref={ ref => { this.tableRef = ref; }} 
                    forceMeta={true} 
                    pageTitle={' '}
                    entity={entity} 
                    data={data} 
                    columns={columns} 
                    hideImportExport={true} 
                    hideClearAll={true} 
                    hideDelete={true} 
                    hideAudit={true}
                    defaultSort={this.defaultSort} 
                    className={"table-overflow-container with-pre-table"}
                >
                    <div className="btn-group">                        
                        <button className="btn btn-warning btn-sm" onClick={() => {
                            let tenant = this.tableRef.selectedRows[0];
                            if (!tenant)return;
                            if (window.confirm('Are you sure you want to switch to the tenant ' + tenant.Name + '?')){
                                EM.tenants.switch(this.tableRef.selectedRows[0].TenantId);
                            }                        
                        }}>Switch Tenant</button>
                    </div>
                </EntityTable>                
                <PanelModal fade={false} isOpen={!!currentFeature} toggle={this.onCloseFeatures} className={'panel expression-modal'} key="features-modal">
                    <ModalHeader toggle={this.onCloseFeatures}>Edit Features: {currentFeature ? currentFeature.DisplayName : ''}</ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="mb-3 font-weight-bold">All Domains:</div>       
                            <div className="pl-3">                 
                                <SimpleJsonObjectEditor 
                                    template={{
                                        staffing: { type: "boolean", display: 'Staffing' },
                                        actuals: { type: "boolean", display: 'Actuals' },
                                        scenarios: { type: "boolean", display: 'Scenarios' },
                                        dashboard: { type: "boolean", display: 'Dashboard' },
                                        notes: { type: "boolean", display: 'Notes' },
                                        pipelines: { type: "boolean", display: 'Pipelines' },
                                        dataExplorer: { type: "boolean", display: 'Demand Data Explorer' },
                                        budgets: { type: "boolean", display: 'Budgets' },
                                    }} 
                                    defaultValue={currentFeatureConfigObj['*']} 
                                    onChange={(newObject) => {
                                        this.setState({ newFeatureStr: JSON.stringify({ '*': newObject }) });
                                    }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.onCloseFeatures}>{EM.t('util.cancel')}</Button>
                        <Button color="primary" onClick={this.onSaveFeatures}>{EM.t('util.save')}</Button>
                    </ModalFooter>
                </PanelModal>   
                <PanelModal fade={false} isOpen={!!currentOptions} toggle={this.onCloseOptions} className={'panel expression-modal'} key="options-modal">
                    <ModalHeader toggle={this.onCloseOptions}>Edit Options: {currentOptions ? currentOptions.DisplayName : ''}</ModalHeader>
                    <ModalBody>
                        <div> 
                            <div className="pl-3">                 
                                <SimpleJsonObjectEditor 
                                    template={{
                                        isTrialMode: { type: "boolean", display: 'Is Trial Mode?', description: 'Indicates if the tenant is in trial mode or not.' },
                                        licenseCount: { type: "string", display: 'License Count', description: 'Used for tracking purposes. Optional.' },
                                        pbixTemplate: { type: "string", display: 'Analytics Link URL', description: 'URL of the analytics link.' },
                                        pbixLinkText: { type: "string", display: 'Analytics Link Text', description: 'Display text of the analytics link.' },
                                        pbixAuthType: { type: "option", display: 'Analytics Authorization Type', options: ['Generate Token', 'Request Token', 'Connector', 'None'] },
                                        fileSizeLimit: { type: "string", display: 'File Size Limit', description: 'Defaults to 10485760 (10 MB).' },
                                        scheduleLimit: { type: "string", display: 'Schedule Import Limit', description: 'Defaults to 35,000 rows.' },
                                        importLimit: { type: "string", display: 'Row Import Limit', description: 'Defaults to 10,000 rows.' },
                                        staffingVisualizationRowLimit: { type: "string", display: 'Staffing Visualization Row Limit', description: 'Defaults to 1,000 rows.' },
                                        activityLength: { type: "option", display: 'Custom Activity Length', options: ['50', '100'], description: 'Defaults to 50 characters. Must be set in database too.' },
                                        redirectLink: { type: "string", display: "Redirect Link", description: 'Adding a URL here will stop domains from showing and direct users to a new location.' },
                                        allowAlgoImportInDomains: { type: "string", display: "Allow Algorithm Import in Domains", description: 'Comma-separated list (no spaces) of numeric Domain IDs to allow algorithm import within.' },                                       
                                    }} 
                                    defaultValue={currentOptionsConfigObj} 
                                    onChange={(newObject) => {
                                        this.setState({ newOptionsStr: JSON.stringify(newObject) });
                                    }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.onCloseOptions}>{EM.t('util.cancel')}</Button>
                        <Button color="primary" onClick={this.onSaveOptions}>{EM.t('util.save')}</Button>
                    </ModalFooter>
                </PanelModal>                                    
            </div>
        );
    }
}
