import React, { useEffect, useRef, useMemo } from 'react';
import Highcharts from './Demand/HighchartsWrapper';
import HighchartsTheme from './Demand/HighcartsTheme';

export default function PieChart(props) {
    const chart = useRef(null);
    const data = useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const plotOptions = useMemo(() => props.plotOptions, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const seriesOptions = useMemo(() => props.seriesOptions, []);

    useEffect(() => {
        Highcharts.theme = HighchartsTheme;
        Highcharts.setOptions(HighchartsTheme);
        
        chart.current = Highcharts.chart("pie-" + props.id, {
            chart: {
                backgroundColor: 'transparent',
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: props.width,
                height: props.height,
                margin: [0, 0, 0, 0],
            },
            plotOptions: {
                pie: Object.assign({
                    borderColor: null,
                    borderWidth: 0,
                }, (plotOptions || {})),
                series: (seriesOptions || {})
            },
            title: {
                text: ''
            },
            credits: { enabled: false },
            legend: { 
                enabled: false,
                useHTML: true,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            series: [{
                name: props.seriesName || 'Data',
                colorByPoint: true
            }]
        });
    }, [props.width, props.height, props.id, props.seriesName, plotOptions, seriesOptions]);

    useEffect(() => {
        if (!data.current || (JSON.stringify(data.current) !== JSON.stringify(props.data))){       
            data.current = props.data;
            let series = chart.current.series[0];
            series.update({
                data: props.data
            });
        }
    }, [props.data]);

    return (
        <div className={"pie-chart " + (props.className || '')} id={"pie-" + props.id}></div>
    );
}