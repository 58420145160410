export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const LOCAL_ERROR = 'LOCAL_ERROR';
export const CLEAR_LOCAL_ERROR = 'CLEAR_LOCAL_ERROR';

export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';

export const SET_DOMAIN = 'SET_DOMAIN';
export const CLEAR_DOMAIN = 'CLEAR_DOMAIN';
export const MARK_DOMAIN_DIRTY = 'MARK_DOMAIN_DIRTY';
export const MARK_DOMAIN_CLEAN = 'MARK_DOMAIN_CLEAN';

export const LOAD_TRANSLATION = 'LOAD_TRANSLATION';

export const START_FETCH = 'START_FETCH';
export const END_FETCH = 'END_FETCH';
export const REMOVE_FETCH = 'REMOVE_FETCH';

export const LOAD_FILE = 'LOAD_FILE';
export const CLEAR_FILES = 'CLEAR_FILES';
export const FILE_ERROR = 'FILE_ERROR';
export const UPDATE_FILE = 'UPDATE_FILE';

export const CLEAR_PROJECTIONS = 'CLEAR_PROJECTIONS';
export const LOAD_PROJECTIONS = 'LOAD_PROJECTIONS';
export const PROJECTIONS_ERROR = 'PROJECTIONS_ERROR';

export const LOAD_USERS = 'LOAD_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const CREATE_PERMISSIONS = 'CREATE_PERMISSIONS';

export const AUDIT_ENTITY_ITEM = 'AUDIT_ENTITY_ITEM';

export const LOAD_TERMS = 'LOAD_TERMS';
export const LOAD_RELEASE = 'LOAD_RELEASE';

export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const POST_RESOURCE_REQUEST_SUCCESS = 'POST_RESOURCE_REQUEST_SUCCESS';
export const POST_RESOURCE_REQUEST_FAILURE = 'POST_RESOURCE_REQUEST_FAILURE';