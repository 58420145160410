/* global EM */
import React, { Component } from 'react';
import { Input, Button, ButtonGroup, Label, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';
import Dates from '../../util/Dates';
import NoRender from '../NoRender';

export default class DemandToolsDatasetOptions extends Component {
    render() {
        let prefs = this.props.datasetPrefs;
        let isSelected = this.props.selected.value;

        let flgs = {};
        if (this.props.selected.group === 'headcounts'){
            flgs.r = true;
            flgs.a = false;
            flgs.p = true;
        };
        if (this.props.selected.group === 'actuals'){
            flgs.r = true;
            flgs.a = true;
            flgs.p = true;
        };
        if (!isSelected){
            flgs.r = true;
            flgs.a = true;
            flgs.p = true;
        }

        return (
            <div>
                <FormGroup>
                    <Label>{this.props.title}:</Label>
                    <Select
                        className="single-select"
                        classNamePrefix="single-select"
                        isSearchable={true}
                        options={this.props.datasets}
                        value={this.props.selected}
                        placeholder={EM.t('util.filtering.placeholderDefault')}
                        isDisabled={this.props.isDisabled}
                        onChange={(selectedItem) => {
                            prefs.dataset = selectedItem;
                            this.props.onChange();
                        }}
                    /> 
                    <FormText color="muted">{Dates.isoToDateTime(this.props.selected.createdOn)}</FormText>
                </FormGroup>
                {this.props.isDisabled ? null :
                    <ButtonGroup>
                        <Button active={prefs.chartType === 'Column'} onClick={() => {
                            prefs.chartType = 'Column';
                            this.props.onChange();
                        }}><i className="far fa-chart-column btn-icon"></i></Button>
                        <Button active={prefs.chartType === 'Line'} onClick={() => {
                            prefs.chartType = 'Line';
                            this.props.onChange();
                        }}><i className="fas fa-chart-line btn-icon"></i></Button>
                        <Button active={prefs.chartType === 'Area'} onClick={() => {
                            prefs.chartType = 'Area';
                            this.props.onChange();
                        }}><i className="fas fa-chart-area btn-icon"></i></Button>
                    </ButtonGroup>    
                }
                {this.props.isDisabled ? null :
                    <div className="inputs-inline">                                
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={prefs.reserve} onChange={(event) => {
                                    prefs.reserve = event.target.checked;
                                    this.props.onChange();
                                }} disabled={flgs.r}/>
                                {EM.t('demand.tools.showWithReserve')}
                            </Label>
                        </FormGroup> 
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={prefs.attrition} onChange={(event) => {
                                    prefs.attrition = event.target.checked;
                                    this.props.onChange();
                                }} disabled={flgs.a}/>
                                {EM.t('demand.tools.showWithAttrition')}
                            </Label>
                        </FormGroup> 
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={prefs.pos} onChange={(event) => {
                                    prefs.pos = event.target.checked;
                                    this.props.onChange();
                                }} disabled={flgs.p}/>
                                {EM.t('demand.tools.showWithPos')}
                            </Label>
                        </FormGroup>                         
                        <NoRender>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked={prefs.totalOnly} onChange={(event) => {
                                        prefs.totalOnly = event.target.checked;
                                        this.props.onChange();
                                    }}/>
                                    {EM.t('demand.tools.totalOnly')}
                                </Label>
                            </FormGroup>                     
                        </NoRender>
                    </div>                             
                }
            </div>
        );
    }
}