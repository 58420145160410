/* global EM */
import React from 'react';
import Dates from '../util/Dates';
import UserIconAndName from './UserIconAndName';

export default function MetaStack(props){  
    let { CreatedOn, CreatedBy, ModifiedOn, ModifiedBy } = props.item;
    let user = EM.users.byId(CreatedBy);
    let modUser = EM.users.byId(ModifiedBy) || null;
    return (
        <div className="meta-stack">
            <div>
                <h6>{EM.t('util.meta.created')}</h6>
                <div>{Dates.isoToDateTime(CreatedOn)}</div>
                <UserIconAndName user={user} className="font-xs"/>
            </div>
            {ModifiedOn ? 
                <div>
                    <h6>{EM.t('util.meta.modified')}</h6>
                    <div>{Dates.isoToDateTime(ModifiedOn)}</div>
                    <UserIconAndName user={modUser} className="font-xs"/>
                </div>               
            : null }                             
        </div>
    );
}