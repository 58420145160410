/* global EM */
import FileTypeBase from './FileTypeBase';
import * as IdbKv from 'idb-keyval';

export default class ProjectionsFileBase extends FileTypeBase{
    constructor(data, entityName, id) {
        super(data, entityName, id);    
        this.projectionLikeFile = true;
        this.dateRangeCache = {};
        this.meta = null;
        this.summaryCachePrefix = EM.getActiveDomain().DomainId;

        this.projectionsStore = new IdbKv.Store('ProjectionsStoreDB', 'ProjectionsStore');
    }

    setMeta(meta){
        this.meta = meta;
    }

    getMeta(){
        return this.meta;
    }

    getGroupKey(grouping, value) {
        return grouping + ':' + value; 
    }

    getDateGroup(dateGrouping, dt) {
        let millis = dt.toMillis();
        let dgkey = dateGrouping + millis;

        if (!this.dateRangeCache[dgkey]){
            if (dateGrouping === this.defaultDateGrouping) {
                this.dateRangeCache[dgkey]  = { begin: dt, end: dt.endOf('month'), key: millis };
            }else if (dateGrouping === 'Quarter') {        
                this.dateRangeCache[dgkey]  =  { begin: dt.startOf('quarter'), end: dt.endOf('quarter'), key: dt.year + '-' + dt.quarter };
            }else if (dateGrouping === 'Year') {
                this.dateRangeCache[dgkey]  =  { begin: dt.startOf('year'), end: dt.endOf('year'), key: dt.year };
            }
        }

        return this.dateRangeCache[dgkey]
    }        

    getCacheKey(preferences, reserve, attrition, pos) {
        let obj = preferences; //Why is this necessary? IDK, but if I don't do it, prefs can get "stuck" when switching to full date range. - JS. 
        let inst = obj.inst;
        return JSON.stringify({ preferences: inst, reserve: reserve, attrition: attrition, pos: pos });
    }

    async getFromSummaryCache(sKey){
        let fullKey = this.summaryCachePrefix + '-' + this.getKey() + ':' + sKey;
        let cachedValue = await IdbKv.get(fullKey, this.projectionsStore);
        return cachedValue || null;
    }

    saveToSummaryCache(sKey, object){
        let fullKey = this.summaryCachePrefix + '-' + this.getKey() + ':' + sKey;
        IdbKv.set(fullKey, object, this.projectionsStore);
    }

    getReserve(){
        let settings = EM.settings.asKeyed();
        let storedValue = parseFloat(settings.Reserve ? settings.Reserve.Value : null);
        return isNaN(storedValue) ? 0 : storedValue;
    }

    getAttrition(){
        let settings = EM.settings.asKeyed();
        let storedValue = parseFloat(settings.Attrition ? settings.Attrition.Value : null);
        return isNaN(storedValue) ? 0 : storedValue;
    }    

    getRA(preferences, datasetName){
        let reserve = preferences[datasetName].reserve ? this.getReserve() : 0;
        let attrition = preferences[datasetName].attrition ? this.getAttrition() : 0;
        let pos = preferences[datasetName].pos;
        return { reserve, attrition, pos };
    }

    async getSummary(preferences, datasetName, ignoreCache){
        let ra = this.getRA(preferences, datasetName);
        return await this.getSummaryByDate(preferences, ra.reserve, ra.attrition, ra.pos, ignoreCache);   
    }

    getDetail(ms, seriesName, preferences, datasetName){
        let ra = this.getRA(preferences, datasetName);
        return this.getDetailByDate(ms, seriesName, preferences, ra.reserve, ra.attrition, ra.pos);   
    }
}