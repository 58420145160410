/* global EM */
import React, { Component, Suspense } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import NoTableData from './NoTableData';
import PageTitle from '../PageTitle';
import EntityTableTools from './EntityTableTools';
import ColumnMapper from '../ColumnMapper';
import EntityColumnConfig from './EntityColumnConfig';
import PipelineRunner from '../../entities/pipelines/PipelineRunner';
import DataOutputHelper from '../../util/DataOutputHelper';
import { numberFilter, Comparator } from 'react-bootstrap-table2-filter';

const EntityAuditTrail = React.lazy(() => import('./EntityAuditTrail'));

export default class EntityTable extends Component {
    constructor(props) {
        super(props);
        this.columns = null;
        this.selectedRows = [];

        this.state = {
            auditId: null,
            showingColumnToggle: false,
            showingFilters: false,
            selectedRowsNumber: 0
        }

        this.onDelete = this.onDelete.bind(this);
        this.onAudit = this.onAudit.bind(this);
        this.onPipelineExport = this.onPipelineExport.bind(this);
        this.filterRegistry = [];

    }

    registerFilter(filter, type){
        this.filterRegistry.push({ filter, type });        
    }

    clearFilters(){
        this.filterRegistry.forEach(filterOpt => {
            try{
                if (filterOpt.filter){
                    if (filterOpt.type === 'date'){
                        filterOpt.filter({ date: '', comparator: Comparator.EQ });
                    }else if (filterOpt.type === 'number'){
                        filterOpt.filter({ number: '', comparator: Comparator.EQ });
                    }else{
                        filterOpt.filter('');
                    }
                }
            }catch(e){
            }
        })        
    }

    mapColumns() {
        if (this.props.columns) {
            this.columns = ColumnMapper(this.props.columns, this.registerFilter.bind(this));
            if (!this.props.neverShowId) {
                if (!this.columns.find(col => col.text === EM.t('util.table.idColumn'))){
                    this.columns.unshift({
                        dataField: this.props.entity.idField + 'Ref',
                        text: EM.t('util.table.idColumn'),
                        isDummyField: true,
                        headerClasses: 'column-id',
                        classes: 'column-id',
                        editable: false,
                        sort: true,
                        csvExport: false,
                        formatter: (cell, row) => {
                            let value = row[this.props.entity.idField];
                            return value;
                        },
                        csvFormatter: (cell, row) => {
                            return row[this.props.entity.idField]
                        },
                        unhideable: true,
                        filterValue: (cell, row) => {
                            let value = row[this.props.entity.idField];
                            return value;
                        },
                        sortValue: (cell, row) => {
                            let value = row[this.props.entity.idField];
                            return value;
                        },
                        filter: numberFilter({
                            withoutEmptyComparatorOption: true,
                            className: 'filter-label',
                            placeholder: 'Value',
                            comparators: [Comparator.EQ, Comparator.GT, Comparator.LT]
                        })
                    });
                }
            }

            if ((EM.debugMode && !this.props.suppressMeta) || this.props.forceMeta){
                let metaCols = [{
                    dataField: 'CreatedOn',
                    text: EM.t('util.meta.created'),
                    isDateReference: true,
                    asMeta: true,
                    width: 150
                },{
                    dataField: 'CreatedBy',
                    text: EM.t('util.meta.createdBy'),
                    asUser: true,
                    asMeta: true,
                    width: 125
                },{
                    dataField: 'ModifiedOn',
                    text: EM.t('util.meta.modified'),
                    isDateReference: true,
                    asMeta: true,
                    width: 150
                },{
                    dataField: 'ModifiedBy',
                    text: EM.t('util.meta.modifiedBy'),
                    asUser: true,
                    asMeta: true,
                    width: 125
                }];

                Array.prototype.push.apply(this.columns, ColumnMapper(metaCols, this.registerFilter.bind(this)));
            }
        } else {
            this.columns = [
                {
                    dataField: 'loading',
                    text: this.props.entity.t('title'),
                    editable: false,
                    isDummyField: true,
                    sort: true
                }
            ];
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.data === this.props.data) {
            if (nextState.auditId !== this.state.auditId || 
                nextState.showingColumnToggle !== this.state.showingColumnToggle ||
                nextState.showingFilters !== this.state.showingFilters ||
                this.state.selectedRowsNumber !== this.selectedRows.length
            ){
                return true;
            }else{
                return false;
            }
        }
        return true;
    }

    async onDelete() {
        let self = this;
        if (self.selectedRows.length <= 0) return;
        let ids = self.selectedRows.map(item => { return item[self.props.entity.idField] });            

        if (self.props.onBeforeDelete) {
            let beforeDeleteResult = self.props.onBeforeDelete(ids);
            if (beforeDeleteResult && beforeDeleteResult.cancel)return;
        }

        if (!self.props.suppressDeleteConfirmation) {    
            if (!window.confirm(EM.t('util.table.deleteConfirmation'))) {
                return;
            }
        }

        if (self.props.onDelete) {
            let result = await self.props.onDelete(ids);
            if (result && result.cancel)return;
        } else {
            self.props.entity.delete(ids);
        }
        
        self.selectedRows = [];
        self.setState({ selectedRowsNumber: 0 });

        if (self.props.onAfterDelete) {
            self.props.onAfterDelete(ids);
        }
    }

    onAudit(){
        let self = this;
        if (self.selectedRows.length > 1){
            window.alert(EM.t('util.table.auditMultipleWarning'));
            return;
        }

        if (self.selectedRows.length <= 0 && !EM.isSuperAdmin()){
            window.alert(EM.t('util.table.auditMultipleWarning'));
            return;
        }

        let selected = self.selectedRows[0];
        let id = selected ? selected[self.props.entity.idField] : '*';
        self.setState({ auditId: id });
    }

    onPipelineExport(){
        DataOutputHelper.exportEntity(this.props.entity, '-pipeline');
    }

    render() {
        let self = this;
        this.mapColumns();
        let domain = EM.getActiveDomain();
        let isChildDomain = domain ? (domain.ParentDomainId ? true : false) : false;
        let restrictedOperations = isChildDomain && this.props.entity !== EM.settings && this.props.entity !== EM.permissions;

        let hasDuplicates = this.props.entity.hasDuplicates();
        let highlighted = EM.debugMode ? this.props.entity.findDuplicates() : {};
        let rowClasses = (row, rowIndex) => {
            let classes = [];
            if (row.hasOwnProperty('Active')) {
                if (row.Active === false) classes.push('dimmed');
            }
            if (row.hasOwnProperty('IsDefault')) {
                if (row.IsDefault === true) classes.push('default');
            }
            if (EM.debugMode) {
                if (highlighted[row[this.props.entity.idField]]) {
                    classes.push('highlight');
                }
            }
            if (this.props.setRowClasses){
                let newRowClasses = this.props.setRowClasses(row, rowIndex);
                return [...classes, (newRowClasses || [])].join(' ');
            }else{
                return classes.join(' ');
            }
            
        };

        if (isChildDomain) {
            if (this.props.entity.isOverridden()) restrictedOperations = false;
        }

        if (!this.props.columns && this.props.showColumnToggle){
            return null;
        }

        let addAlertClass = hasDuplicates || restrictedOperations;

        let hasOutputPipeline = PipelineRunner.hasOutputPipeline(this.props.entity, 'Output');

        return (
            <ToolkitProvider
                keyField={this.props.entity.idField}
                data={this.props.data || []}
                columns={this.columns.filter(column => !column.hideInTable)}
                columnToggle={this.props.showColumnToggle}
                exportCSV={{
                    fileName: this.props.entity.name + '.csv',
                    exportAll: this.state.showingFilters ? false : true,
                    onlyExportFiltered: this.state.showingFilters ? true : false,
                    onlyExportSelection: this.state.selectedRowsNumber > 0
                }}
                search={{
                    searchFormatted: true
                }}
            >{props => (
                <div className={(this.props.className || (((EM.debugMode && !this.props.suppressMeta) || this.props.forceMeta) ? ' table-overflow-container': null)) + (addAlertClass ? ' table-alert' : '')}>
                    {!this.props.hideToolbar ?
                        <PageTitle title={this.props.pageTitle || this.props.entity.t('title')} bar={true} icon={this.props.pageIcon} className={this.props.pageTitleClassName}>
                            {this.props.columns ?
                                <EntityTableTools                                    
                                    onBeforeImport={this.props.onBeforeImport}
                                    restrictedOperations={restrictedOperations}
                                    onCreate={this.props.onCreate}
                                    columns={this.columns}
                                    entity={this.props.entity}
                                    searchProps={props.searchProps}
                                    csvProps={props.csvProps}
                                    hideImportExport={this.props.hideImportExport}
                                    hideImport={this.props.hideImport}
                                    hideClearAll={this.props.hideClearAll}
                                    hideDelete={this.props.hideDelete}
                                    hideAddRecord={this.props.hideAddRecord}
                                    hideSearch={this.props.hideSearch}
                                    hideAudit={this.props.hideAudit}
                                    onDelete={this.onDelete}
                                    onAudit={this.onAudit}
                                    hasOutputPipeline={hasOutputPipeline}
                                    onPipelineExport={this.onPipelineExport}
                                    customExportFunc={this.props.customExportFunc}
                                    customExportLabel={this.props.customExportLabel}
                                    onClear={(event, csvProps) => {
                                        let confirmationText = EM.t('util.table.clearConfirmation', false, [this.props.entity.t('title')]);
                                        let promptResult = window.prompt(EM.t('util.table.clearPrompt', false, [confirmationText]));
                                        if (promptResult === confirmationText) {                                            
                                            let entityLength = this.props.entity.get().length;
                                            if (entityLength > 10000 && !window.confirm(EM.t('util.table.clearConfirmationOver10k')))return;
                                            this.props.entity.clearAll().then(() => {
                                                if (entityLength > 10000){
                                                    window.setTimeout(() => {                                                        
                                                        this.props.entity.load(true);                                                        
                                                    }, 500);                                                
                                                }
                                            });                                      
                                        }
                                    }}
                                    
                                    showingExtraTools={this.state.showingColumnToggle}
                                    shouldShowColumnToggle={this.props.showColumnToggle}
                                    onToggleColumnToggle={() => {
                                        this.setState({ showingColumnToggle: !this.state.showingColumnToggle });
                                    }}

                                    showingFilters={this.state.showingFilters}
                                    shouldShowFilters={!this.props.hideFilters}
                                    onToggleFilters={() => {
                                        this.clearFilters();
                                        this.setState({ showingFilters: !this.state.showingFilters });
                                    }}
                                    onClearFilters={() => {
                                        this.clearFilters();
                                    }}
                                    selectedRowsNumber = {this.state.selectedRowsNumber}
                                    selectedRows = {this.selectedRows}
                                >{this.props.children}</EntityTableTools>
                            : null}
                        </PageTitle>
                    : null }
                    {this.props.subtitle ?
                        <p>{this.props.subtitle}</p>
                        : null}
                    {hasDuplicates && !this.props.entity.allowDuplicates ?
                        <div className="alert alert-danger">
                            {EM.t('util.table.duplicatesMessage')}
                        </div>
                    : null}
                    {restrictedOperations ?
                        <div className="alert alert-secondary">
                            {this.props.baseCurrencyRestrictedMessage ? this.props.baseCurrencyRestrictedMessage : EM.t('util.table.restrictedMessage')}
                        </div>
                    : null}

                    {this.props.showColumnToggle && props.columnToggleProps.toggles ?
                        <EntityColumnConfig 
                            isOpen={this.state.showingColumnToggle} 
                            onColumnToggle={this.props.onColumnToggle}
                            onClose={event => {
                                this.setState({ showingColumnToggle: !this.state.showingColumnToggle })
                            }}
                            columnToggleProps={props.columnToggleProps} 
                        />
                    : null }

                    <BootstrapTable
                        remote={{ cellEdit: true }}
                        classes={"table table-bordered table-striped " + (this.state.showingFilters?'table-filter':'')}
                        rowClasses={rowClasses}
                        noDataIndication={() => <NoTableData data={this.props.data} />}
                        expandRow={this.props.expandRow}
                        filter={ filterFactory() } 
                        sort={this.props.defaultSort || null} 
                        cellEdit={cellEditFactory({
                            mode: restrictedOperations ? 'false' : 'click',
                            blurToSave: true,
                            beforeSaveCell: (oldValueIn, newValueIn, row, column, done) => {
                                let oldValue = oldValueIn || '';
                                let newValue = newValueIn || '';

                                if (oldValue === newValue) {
                                    done(false);
                                    return;
                                }

                                let newItem = Object.assign({}, row);
                                newItem[column.dataField] = (newValue.trim ? newValue.trim() : newValue);
                                
                                if (column.allowNulls && newValue === ''){
                                    newItem[column.dataField] = null;
                                }

                                let oldItem = row;
                                if (self.props.beforeUpdate)self.props.beforeUpdate(oldItem, newItem);
                                this.props.entity.update(oldItem, newItem).then((item) => {
                                    if (self.props.onUpdate)self.props.onUpdate(item);
                                });
                            }
                        })}
                        pagination={paginationFactory({
                            sizePerPage: this.props.pageSize || 100,
                            showTotal: (this.props.data && !this.props.hideTotals) ? true : false,
                            hideSizePerPage: true,
                            hidePageListOnlyOnePage: true,
                            paginationTotalRenderer: (from, to, size) => {
                                let selCnt = this.state.selectedRowsNumber;
                                let selectionText = selCnt > 0 ? EM.t('util.table.selectionText', false, [selCnt]) : '';
                                return EM.t('util.table.paginationTotal', false, [from, to, size]) + ' ' + selectionText;
                            }
                        })}
                        selectRow={{
                            mode: 'checkbox',
                            classes: 'selected-row',
                            onSelect: (row, isSelect, rowIndex, e) => {
                                if (isSelect) {
                                    this.selectedRows.push(row);
                                } else {
                                    this.selectedRows = this.selectedRows.filter(item => {
                                        return item[this.props.entity.idField] !== row[this.props.entity.idField];
                                    });
                                }
                                this.setState({ selectedRowsNumber:  this.selectedRows.length});
                            },
                            onSelectAll: (isSelect, rows, e) => {
                                if (isSelect) {
                                    this.selectedRows = rows;
                                } else {
                                    this.selectedRows = [];
                                }
                                this.setState({ selectedRowsNumber:  this.selectedRows.length});
                            }
                        }}
                        onTableChange={(type, newState) => { }}
                        {...props.baseProps}
                    />
                    { !this.props.disableAudit ? 
                        <Suspense fallback="Loading...">
                            <EntityAuditTrail entityColumns={this.columns} entity={this.props.entity} id={this.state.auditId} onClose={() => {
                                this.setState({ auditId: null });
                            }} />
                        </Suspense>
                    : null }
                </div>
            )}
            </ToolkitProvider>
        );
    }
};