export function register(config) {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/cache-control.js`;
            registerValidSW(swUrl);
        });
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker.register(swUrl, { scope: "./" }).then(registration => {
        registration.onupdatefound = () => {};
    }).catch(error => {
        console.error('Error during service worker registration:', error);
    });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
