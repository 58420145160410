/* global EM */
import React from 'react';
import { Input, Label, FormGroup, FormText } from 'reactstrap';

const SettingsValueInput = ({setting, classes, enforceNumeric}) => {
    let keyTbase = 'settings.generalSettings.' + setting.toLowerCase() + '.';
    let settingObj = EM.settings.findByKey(setting);
    let value = '';
    if (settingObj)value = settingObj.Value;
    return (
        <FormGroup className={classes}>
            <Label className='labelText'>{EM.t(keyTbase + 'label')}:</Label> 
            <FormText className='labelDescription'>{EM.t(keyTbase + 'description')}</FormText>           
            <Input defaultValue={value} onBlur={(event) => {
                let newValue = event.target.value;
                if (enforceNumeric && newValue){
                    if (!parseFloat(newValue)){
                        event.target.value = value;
                        return;
                    }
                }
                if (newValue !== value){
                    if (settingObj){
                        let newObj = Object.assign({}, settingObj, { Value: newValue });
                        EM.settings.update(settingObj, newObj);
                    }else{
                        EM.settings.create({
                            Key: setting,
                            Value: newValue
                        });
                    }                    
                }
            }}/>
        </FormGroup>
    );
};

export default SettingsValueInput;