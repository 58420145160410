/* global EM */
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import Dates from "../util/Dates";
import PageTitle from "../components/PageTitle";
import filterFactory, { Comparator } from "react-bootstrap-table2-filter";
import NoTableData from "../components/EntityTables/NoTableData";
import ResourceRequestEditOptionDialog from "../components/ResourceRequests/ResourceRequestEditOptionDialog";
import { Button } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ColumnMapper from "../components/ColumnMapper";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Routes from "../app/Routes";

const statuses = [
    { value: 1, label: "Submitted" },
    { value: 2, label: "In Progress" },
    { value: 3, label: "Approved" },
    { value: 4, label: "Denied" },
    { value: 5, label: "Partial Approval" },
];
let RecipientList = [
    {
        value: "null",
        label: null,
    },
];
let AssigneeRecipientList = [
    {
        value: null,
        label: null,
    },
];
const { SearchBar, ClearSearchButton } = Search;

export default class RequestsQueue extends Component {
    constructor(props) {
        super(props);
        this.selectedRows = [];
        this.columns = null;
        this.state = {
            requests: [],
            activityEntity: [],
            roleEntity: [],
            userEntity: [],
            allUserEntity: [],
            requestModalOpen: false,
            ticketNumber: "",
            statusID: "",
            workItem: "",
            activity: "",
            activityId: "",
            roleId: "",
            role: "",
            RequestTypeName: "",
            requestMessage: "",
            recipientId: "",
            requesterId: "",
            recipientList: [],
            recipientMessage: "",
            requesterAcknowledged :"",
            oldItem: {},
            column: {},
            resourceRequestId: "",
            showingFilters: false,
            selectedRowsNumber: 0,
            currentUser:'',
        };
        this.selectedRows = [];
        this.onLoadInitial = this.onLoadInitial.bind(this);
        this.onRequestModalClose = this.onRequestModalClose.bind(this);
        this.handleChildUpdate = this.handleChildUpdate.bind(this);
        this.updateRequesterAcknowledged = this.updateRequesterAcknowledged.bind(this);
        this.filterRegistry = [];
    }
    registerFilter(filter, type) {
        this.filterRegistry.push({ filter, type });
    }
    clearFilters() {
        this.filterRegistry.forEach((filterOpt) => {
            try {
                if (filterOpt.filter) {
                    if (filterOpt.type === "date") {
                        filterOpt.filter({ date: "", comparator: Comparator.EQ });
                    } else if (filterOpt.type === "number") {
                        filterOpt.filter({ number: "", comparator: Comparator.EQ });
                    } else {
                        filterOpt.filter("");
                    }
                }
            } catch (e) { }
        });
    }

    componentDidMount() {
        if (EM.isDomainResourceManager() || EM.isStrictlySuperAdmin()) {
            EM.loadEntities([
                EM.resourceRequests,
                EM.users,
                EM.roles,
                EM.activities,
                EM.permissions,
            ]).then(() => {
                let requests = EM.resourceRequests.get();
                this.setState({
                    requests: requests.sort((a, b) => {
                        if (EM.isDomainResourceManager()) {
                            if (!a.RecipientId && b.RecipientId ) {
                                return -1;
                            }
                            if (a.RecipientId  && !b.RecipientId ) {
                                return 1;
                            }
                        }
                        if (EM.isSuperAdmin()) {
                            if (a.RequesterAcknowledged === null && b.RequesterAcknowledged !== null) {
                                return -1;
                            }
                            if (a.RequesterAcknowledged !== null && b.RequesterAcknowledged === null) {
                                return 1;
                            }
                        }
                        let CreatedOnA = new Date(a.CreatedOn);
                        let CreatedOnB = new Date(b.CreatedOn);
                        return CreatedOnB - CreatedOnA;
                    })
                });
                this.onLoadInitial();
            });
        } else if (EM.isStrictlyDomainSubscriber()) {
            EM.loadEntities([
                EM.resourceRequests,
                EM.roles,
                EM.activities,
            ]).then(() => {
                let requests = EM.resourceRequests.get();
                this.setState({
                    requests: requests.sort((a, b) => {
                        if (a.RequesterAcknowledged === null && b.RequesterAcknowledged !== null) {
                            return -1;
                        }
                        if (a.RequesterAcknowledged !== null && b.RequesterAcknowledged === null) {
                            return 1;
                        }
                        let CreatedOnA = new Date(a.CreatedOn);
                        let CreatedOnB = new Date(b.CreatedOn);
                        return CreatedOnB - CreatedOnA;
                    })
                });
                this.onLoadInitial();
            });
        }
    }

    componentWillUnmount() {
        RecipientList = [
            {
                value: null,
                label: null,
            },
        ];
        AssigneeRecipientList = [
            {
                value: null,
                label: null,
            },
        ];
    }
    onLoadInitial() {
        var newRequestsData = [];

        const activityEntities = EM.activities.get();
        const roleEntities = EM.roles.get();
        let userEntities = [];
        if (!EM.isStrictlyDomainSubscriber()) {
            userEntities = EM.users.get();
        }
        const account = EM.store?.getState();
        if (EM.isDomainResourceManager() || EM.isSuperAdmin()) {
            const PermissionLookupId = EM.permissions.get();
            PermissionLookupId.forEach((element) => {
                if (element.UserId == account?.account?.User?.UserId && (element?.PermissionLookupId == 100 || element?.PermissionLookupId == 1000)) {
                    this.setState({
                        currentUser : element.UserId
                    })
                    let allUser =[];
                    userEntities?.forEach((element)=>{
                        allUser.push({
                            label: element.Name,
                            value: element.UserId,
                        })
                    })
                    this.setState({
                        allUserEntity:allUser
                    })
                    let user = userEntities?.filter((x) => x.UserId == element.UserId);
                    RecipientList.push({
                        ...element,
                        label: user[0].Name,
                        value: user[0].UserId,
                    });
                }
            });
            if (EM.isSuperAdmin()) {
                userEntities?.forEach((element) => {
                    RecipientList.push({
                        label: element.Name,
                        value: element.UserId,
                    });
                });
            }
        }
        this.setState({
            activityEntity: activityEntities,
            roleEntity: roleEntities,
            userEntity: userEntities,
        });
        this.state.requests?.map((element) => {
            element.Status = statuses[0].value;
            element.RecipientName = RecipientList[0]?.value;

            if (element?.RequestTypeId === 1) {
                element.RequestTypeName = "Add Resource";
            } else if (element?.RequestTypeId === 2) {
                element.RequestTypeName = "Remove Resource";
            }
            const role = roleEntities.find((x) => x.RoleId == element.RoleId);
            const activity = activityEntities?.find((x) => x.ActivityId == element.ActivityId);
            const requester = userEntities?.find((x) => x.UserId == element.RequesterId);

            newRequestsData.push({
                ...element,
                roleName: role ? role.Name : "",
                requesterId: requester ? requester.UserId : "",
                activityName: activity ? activity.Name : "",
                userName: requester ? requester.Name : "",
            });

        });

        this.setState({ requests: newRequestsData });

        if (EM.isStrictlyDomainSubscriber()) {
            let newSubscriberRequest = [];
            this.state.requests.forEach((element) => {
                if (element.RequesterId == account?.account?.User?.UserId) {
                    newSubscriberRequest.push(element);
                }
            });
            this.setState({ requests: newSubscriberRequest });
        }
    }

    onRequestModalClose() {
        this.setState({ requestModalOpen: false });
    }
    handleChildUpdate(updatedRequest) {
        // Update the request in the state
        const updatedRequests = this.state.requests.map((request) =>
            request.ResourceRequestId === updatedRequest.ResourceRequestId
                ? updatedRequest
                : request
        );
        this.setState({ requests: updatedRequests });
    }

    determineButtonLabel() {
        if (this.selectedRows.length === 0) {
            return 'Mark Unread/Read';
        }

        const allUnread = this.selectedRows.every(row => row.RequesterAcknowledged === null); 
        if (allUnread) {
            return 'Mark as Read';  // If all selected rows are unread, allow marking them as read
        }

        const allRead = this.selectedRows.every(row => row.RequesterAcknowledged !== null);
        if (allRead) {
            return 'Mark as Unread';  // If all selected rows are read, allow marking them as unread
        }

        return 'Mark as Read'; // Default to marking as read if mixed selection
    }

    updateRequesterAcknowledged() {
        const selectedRows = this.selectedRows;

        // Check if all selected rows are currently read (RequesterAcknowledged !== null)
        const allRead = selectedRows.every(row => row.RequesterAcknowledged !== null);

        // If all rows are unread, mark them as read (RequesterAcknowledged = null)
        // If all rows are read, mark them as unread (RequesterAcknowledged = timestamp)
        const newRequesterAcknowledgedValue = allRead ? null : new Date().toISOString();

        // Create promises to update the selected rows
        const updatePromises = selectedRows.map(row => {
            const updatedData = {
                ...row,
                RequesterAcknowledged: newRequesterAcknowledgedValue
            };

            // Update each resource request with the new RequesterAcknowledged value
            return EM.resourceRequests.update({ ResourceRequestId: row.ResourceRequestId }, updatedData)
                .then((updatedItem) => {
                    return updatedItem;
                })
                .catch((error) => {
                    console.error('Error updating request:', error);
                    throw error;
                });
        });

        // After all updates are done, update the state
        Promise.all(updatePromises)
            .then((updatedRequests) => {
                const updatedStateRequests = this.state.requests.map((request) => {
                    const updatedRequest = updatedRequests.find(
                        (updatedItem) => updatedItem.ResourceRequestId === request.ResourceRequestId
                    );
                    return updatedRequest ? { ...request, ...updatedRequest } : request;
                });

                // Set the updated requests and clear the selection
                this.setState({ requests: updatedStateRequests }, () => {
                    this.selectedRows = [];
                    this.setState({ selectedRowsNumber: 0 });

                    if (this.tableRef && this.tableRef.selectionContext) {
                        this.tableRef.selectionContext.selected = [];
                    }
                });
            })
            .catch((error) => {
                console.error('Error in Promise.all:', error);
            });
    }



    render() {
        const entity = EM.resourceRequests;
        const buttonLabel = this.determineButtonLabel();
        const self = this;
        const columns = [
            {
                dataField: "ResourceRequestId",
                text: "ID",
                editable: false,
                headerTitle: true,
                title: true,
                creatable: false,
                headerClasses: "column-id",
                classes: "column-id",
                width: 125,
                filterOptions: {
                    number: true,
                },
            },
            {
                dataField: "TicketNumber",
                text: "Ticket No.",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                style: { color: '#66823F', fontWeight: 'bold', cursor: 'pointer' },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.setState({
                            recipientList: RecipientList,
                        });
                        this.setState({
                            oldItem: row,
                            column: column,
                            requestModalOpen: true,
                            ticketNumber: row.TicketNumber,
                            statusID: row.StatusId,
                            workItem: row.WorkItem,
                            requesterId: row.requesterId,
                            activity: this.state.activityEntity?.find(
                                (x) => x.ActivityId == row.ActivityId
                            )?.Name,
                            activityId: row.ActivityId,
                            role: this.state.roleEntity?.find((x) => x.RoleId == row.RoleId)
                                ?.Name,
                            roleId: row.RoleId,
                            RequestTypeName: row.RequestTypeName,
                            requestMessage: row.RequesterNote,
                            recipientId: row.RecipientId,
                            recipientList: RecipientList,
                            recipientMessage: row.RecipientNote,
                            resourceRequestId: row.ResourceRequestId,
                            requesterAcknowledged:row.RequesterAcknowledged
                        });
                    },
                },
                filterOptions: {
                    text: true,
                },
            },
            {
                dataField: "StatusId",
                text: "Status",
                width: 125,
                editable: EM.isStrictlyDomainSubscriber() ? false : true,
                editor: {
                    type: Type.SELECT,
                    options: statuses,
                },
                headerTitle: true,
                title: (cell, row) => {
                    let value = statuses?.filter((x) => x.value == row["StatusId"])[0]
                        ?.label;
                    return value;
                },

                formatter: (cell, row) => {
                    let value = statuses?.filter((x) => x.value == row["StatusId"])[0]
                        ?.label;
                    return value;
                },
                csvFormatter: (cell, row) => {
                    return row[this.state.requests.RecipientId];
                },
                filterOptions: {
                    select: true,
                    options: statuses,
                },
            },

            {
                dataField: "WorkItem",
                text: "Work Item",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    text: true,
                },
                style: { color: '#66823F', fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline' },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        let domain = EM.getActiveDomain();
                        let staffingWiUrl = Routes.compose(Routes.client.staffingWorkItem, { DomainName: domain.Name }) + '#' + row.WorkItem.toIdToken();
                        this.props.history.push(staffingWiUrl);
                    }
                },

            },
            {
                dataField: "activityName",
                text: "Activity",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    dynamicEntity: EM.activities,
                },
            },

            {
                dataField: "roleName",
                text: "Role",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    dynamicEntity: EM.roles,
                },
            },
            {
                dataField: "RequestTypeName",
                text: "Request Type",
                headerTitle: true,
                title: true,
                editable: false,
                width: 125,
                filterOptions: {
                    text: true,
                },
            },
            ...(!EM.isStrictlyDomainSubscriber() ? [{
                dataField: "userName",
                text: "Requester",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    text: true,
                },
            }] : []),
            {
                dataField: "RequesterNote",
                text: "Message",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    text: true,
                },
            },
            ...(!EM.isStrictlyDomainSubscriber() ? [
                {
                    dataField: "RecipientId",
                    text: "Assignee",
                    width: 125,
                    editable: true,
                    value: (row) => row["RecipientId"].toString(),
                    editor: {
                        type: Type.SELECT,
                        options: RecipientList,
                    },
                    headerTitle: true,
                    title: EM.isStrictlyDomainSubscriber()? (cell, row) => {
                        let value = this.state.allUserEntity?.find(
                            (x) => x.value == row["RecipientId"]
                        )?.label;
                        return value;
                    }:(cell, row) => {
                        let value = RecipientList?.filter(
                            (x) => x.value == row["RecipientId"]
                        )[0]?.label;
                        return value;
                    },
                    formatter:EM.isStrictlyDomainAdmin()? (cell, row) => {
                        let value = this.state.allUserEntity?.find(
                            (x) => x.value == row["RecipientId"]
                        )?.label;
                        return value;
                    }:(cell, row) => {
                        let value = RecipientList?.filter(
                            (x) => x.value == row["RecipientId"]
                        )[0]?.label;
                        return value;
                    },
                    csvFormatter: (cell, row) => {
                        return row[this.state.requests.RecipientId];
                    },
                    filterOptions: {
                        recipient: true,
                        recipientList: AssigneeRecipientList,
                    },
                }
            ] : []),
            {
                dataField: "RecipientNote",
                text: "Response",
                width: 125,
                editable: EM.isStrictlyDomainSubscriber() ? false : true,
                editor: {
                    type: Type.TEXT,
                },
                headerTitle: true,
                title: true,
                formatter: (cell, row) => {
                    let value = row["RecipientNote"];
                    return value;
                },
                filterOptions: {
                    text: true,
                },
            },

            {
                dataField: "CreatedOn",
                text: "Created",
                editable: false,
                headerTitle: true,
                title: true,
                width: 125,
                filterOptions: {
                    text: true,
                },
                formatter: (cell, row) => {
                    return Dates.isoToDateTime(row.CreatedOn);
                },

            },

        ];
        if ((EM.isStrictlyDomainAdmin() || EM.isStrictlySuperAdmin())) {
            if ((EM.debugMode && !this.props.suppressMeta) || this.props.forceMeta) {
                const metaCols = [

                    {
                        dataField: 'CreatedBy',
                        text: EM.t('util.meta.createdBy'),
                        asUser: true,
                        asMeta: true,
                        width: 150
                    },
                    {
                        dataField: 'ModifiedOn',
                        text: EM.t('util.meta.modified'),
                        isDateReference: true,
                        asMeta: true,
                        width: 150
                    },
                    {
                        dataField: 'ModifiedBy',
                        text: EM.t('util.meta.modifiedBy'),
                        asUser: true,
                        asMeta: true,
                        width: 150
                    }
                ];
                Array.prototype.push.apply(columns, ColumnMapper(metaCols, this.registerFilter.bind(this)));
            }
        }
        this.columns = ColumnMapper(columns, this.registerFilter.bind(this));

        let rowClasses = (row, rowIndex) => {
            let classes = [];

            // For Resource Managers - If recipientId is null, apply bold styling
            if (EM.isDomainResourceManager() && (!row.RecipientId || row.RecipientId === null || row.RecipientId === 'null')) {
                classes.push('font-weight-bold');
            }

            // For Domain Subscriber or Super Admin - If RequesterAcknowledged is not null (Unread), apply bold styling
            if ((EM.isStrictlyDomainSubscriber() || EM.isStrictlySuperAdmin()) && row.RequesterAcknowledged === null) {
                classes.push('font-weight-bold');
            }

            if (this.props.setRowClasses) {
                let newRowClasses = this.props.setRowClasses(row, rowIndex);
                return [...classes, newRowClasses || []].join(" ");
            }

            return classes.join(" ");
        };
        let userEntities = [];
        if(EM.isStrictlyDomainAdmin() || EM.isStrictlySuperAdmin()){
            userEntities = EM.users.get()
        }
        this.state.requests?.forEach((element) => {
            if(element.RecipientId){
                !AssigneeRecipientList.find((user) => user.value == element.RecipientId) &&
                AssigneeRecipientList.push({
                    label: userEntities.find((user) => user.UserId == element.RecipientId)?.Name,
                    value: element.RecipientId,
                });
            }
        });

        return (
            <div key="contents" className={"page container-fluid "}>
                <ToolkitProvider
                    keyField="ResourceRequestId"
                    data={this.state.requests || []}
                    columns={this.columns.filter((column) => !column.hideInTable)}
                    search={{
                        searchFormatted: true,
                    }}
                >
                    {(props) => (
                        <div className="table-overflow-container">
                            <PageTitle title={entity.t("title")} bar={true}>
                                <div className="table-tools" key="table-tools">
                                    <div className="btn-group">

                                        {this.state.selectedRowsNumber > 0 && !EM.isDomainResourceManager() && (
                                            <Button
                                                size="sm"
                                                color="light"
                                                onClick={() => this.updateRequesterAcknowledged()}
                                            >
                                                {buttonLabel}
                                            </Button>
                                        )}
                                        <Button
                                            size="sm"
                                            color="light"
                                            title={EM.t("util.table.toggleFilters")}
                                            className={
                                                "btn-filters " +
                                                (this.state.showingFilters ? "active" : "")
                                            }
                                            onClick={(event) => {
                                                event.target.blur();
                                                window.setTimeout(() => {
                                                    this.clearFilters();
                                                    this.setState({
                                                        showingFilters: !this.state.showingFilters,
                                                    });
                                                }, 0);
                                            }}
                                        >
                                            <i className={"fas fa-filter"}></i>
                                        </Button>
                                        {this.state.showingFilters ? (
                                            <Button
                                                size="sm"
                                                color="light"
                                                title={EM.t("util.table.clearFilters")}
                                                className={"btn-filters-clear active"}
                                                onClick={(event) => {
                                                    event.target.blur();
                                                    window.setTimeout(() => {
                                                        this.clearFilters();
                                                    }, 0);
                                                }}
                                            >
                                                <i className="fas fa-eraser"></i>
                                                <span>Clear Filters</span>
                                            </Button>
                                        ) : null}
                                    </div>
                                    <SearchBar
                                        id="FldSearch"
                                        className="form-control-sm"
                                        {...props.searchProps}
                                        placeholder={EM.t("util.table.searchPlaceholder")}
                                    />
                                    <ClearSearchButton
                                        {...props.searchProps}
                                        className="btn-search-clear"
                                        text="&nbsp;"
                                    />
                                </div>
                            </PageTitle>
                            {this.state.requests ? (
                                <div className={"tabular-responsive"}>
                                    <BootstrapTable
                                        ref={el => (this.tableRef = el)}
                                        keyField="ResourceRequestId"
                                        remote={{ cellEdit: true }}
                                        classes={
                                            "table table-bordered table-striped " +
                                            (this.state.showingFilters ? "table-filter" : "")
                                        }
                                        columns={columns}
                                        rowClasses={rowClasses}
                                        noDataIndication={() => (
                                            <NoTableData data={this.state.requests} />
                                        )}
                                        expandRow={this.props.expandRow}
                                        filter={filterFactory()}
                                        sort={this.props.defaultSort || null}
                                        className="sortable width-200"
                                        striped
                                        condensed={true}
                                        cellEdit={cellEditFactory({
                                            mode: "click",
                                            blurToSave: true,
                                            beforeSaveCell: (
                                                oldValueIn,
                                                newValueIn,
                                                row,
                                                column,
                                                done
                                            ) => {
                                                let oldValue = oldValueIn || "";
                                                let newValue = newValueIn || "";
                                                if(column.dataField === "RecipientId" && row.RecipientId == oldValueIn && newValueIn == ""  && oldValueIn != this.state.currentUser && EM.isStrictlyDomainAdmin()){
                                                    const updatedRequests = (column.dataField === "StatusId" || column.dataField === "RecipientNote") ? this.state.requests.map((request) => {
                                                        if (request.ResourceRequestId === row.ResourceRequestId) {
                                                           
                                                            return {
                                                                ...request,
                                                                [column.dataField]: newValue,
                                                                RequesterAcknowledged:null
                                                            };
                                                        }
                                                        return request;
                                                    }) : this.state.requests.map((request) => {
                                                        if (request.ResourceRequestId === row.ResourceRequestId) {
                                                           
                                                            return {
                                                                ...request,
                                                                [column.dataField]: newValue
                                                            };
                                                        }
                                                        return request;
                                                    });
                                                    this.setState({ requests: updatedRequests });
                                                }

                                                if (oldValue == newValue) {
                                                    done(false);
                                                    return;
                                                }
                                                newValue = newValue == "null" ? null :newValue
                                                  const updatedRequests = (column.dataField === "StatusId" || column.dataField === "RecipientNote") ? this.state.requests.map((request) => {
                                                    if (request.ResourceRequestId === row.ResourceRequestId) {
                                                       
                                                        return {
                                                            ...request,
                                                            [column.dataField]: newValue,
                                                            RequesterAcknowledged:null
                                                        };
                                                    }
                                                    return request;
                                                }) : this.state.requests.map((request) => {
                                                    if (request.ResourceRequestId === row.ResourceRequestId) {
                                                       
                                                        return {
                                                            ...request,
                                                            [column.dataField]: newValue
                                                        };
                                                    }
                                                    return request;
                                                });
                                                this.setState({ requests: updatedRequests });
                                                let newItem = (column.dataField === "StatusId" || column.dataField === "RecipientNote") ? {
                                                    ...row,
                                                    [column.dataField]: newValue,
                                                    RequesterAcknowledged: null,
                                                } :{
                                                    ...row,
                                                    [column.dataField]: newValue,
                                                };
                                                if (column.allowNulls && newValue === "") {
                                                    newItem[column.dataField] = null;
                                                }

                                                let oldItem = row;
                                                if (self.state.beforeUpdate)
                                                    self.state.beforeUpdate(oldItem, newItem);                                                
                                                (oldItem.RecipientId !== newItem.RecipientId || oldItem.StatusId !== newItem.StatusId || oldItem.RecipientNote !== newItem.RecipientNote) &&
                                                EM.resourceRequests
                                                    .update(oldItem, newItem)
                                                    .then((item) => {
                                                        if (self.props.onUpdate) self.props.onUpdate(item);
                                                    });
                                            },
                                        })}
                                        pagination={paginationFactory({
                                            sizePerPage: 25,
                                            showTotal: this.state.requests ? true : false,
                                            hideSizePerPage: true,
                                            hidePageListOnlyOnePage: true,
                                            paginationTotalRenderer: (from, to, size) => {
                                                return EM.t('util.table.paginationTotal', false, [from, to, size])
                                            }
                                        })}

                                        selectRow={{
                                            mode: 'checkbox',
                                            classes: 'selected-row',
                                            onSelect: (row, isSelect, rowIndex, e) => {
                                                if (isSelect) {
                                                    this.selectedRows.push(row);
                                                } else {
                                                    this.selectedRows = this.selectedRows.filter(
                                                        (item) => item["ResourceRequestId"] !== row["ResourceRequestId"]
                                                    );
                                                }
                                                this.setState({ selectedRowsNumber: this.selectedRows.length });
                                            },
                                            onSelectAll: (isSelect, rows, e) => {
                                                if (isSelect) {
                                                    this.selectedRows = rows;
                                                } else {
                                                    this.selectedRows = [];
                                                }
                                                this.setState({ selectedRowsNumber: this.selectedRows.length });
                                            }
                                        }}


                                        onTableChange={(type, newState) => { }}
                                        {...props.baseProps}
                                    />

                                    {this.state.requestModalOpen ? (
                                        <ResourceRequestEditOptionDialog
                                            onClose={this.onRequestModalClose}
                                            isOpen={true}
                                            ticketNumber={this.state.ticketNumber}
                                            statusID={this.state.statusID}
                                            workItem={this.state.workItem}
                                            activity={this.state.activity}
                                            role={this.state.role}
                                            RequestTypeName={this.state.RequestTypeName}
                                            requestMessage={this.state.requestMessage}
                                            recipientId={this.state.recipientId == null ||this.state.recipientId == "" ? 'null':this.state.recipientId}
                                            requesterId={this.state.requesterId}
                                            recipientList={this.state.recipientList}
                                            recipientMessage={this.state.recipientMessage}
                                            roleId={this.state.roleId}
                                            activityId={this.state.activityId}
                                            resourceRequestId={this.state.resourceRequestId}
                                            oldItem={this.state.oldItem}
                                            column={this.state.column}
                                            onUpdate={this.handleChildUpdate}
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        );
    }
}