/* global EM */
import React, { Component } from 'react';
import Select from 'react-select';
import NoRender from './NoRender';
import { Button, ButtonGroup, Input, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader, FormFeedback } from 'reactstrap';

export default class PublishOptionsDialog extends Component {
    constructor(props) {
        super(props);

        let config = props.configuration; 
        let icon = 'fas fa-file-alt';        
        if (config && config.Styling){
            let styleObj = JSON.parse(config.Styling);
            if (styleObj && styleObj.icon)icon = styleObj.icon;
        }

        this.state = {
            name: config ? config.Name : null,
            nameError: null,
            description: config ? (config.Description || '') : '',
            icon: icon,
            isPublic: config ? config.IsPublic : false,
            grouping: config ? config.Grouping : null,
            isEdit: config ? true : false
        };

        this.onPublish = this.onPublish.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onPublish(){
        if (!this.validate())return;

        let opts = {
            Name: this.state.name,
            Description: this.state.description,
            Grouping: this.state.grouping,
            Styling: JSON.stringify({ icon: null }),
            IsPublic: this.state.isPublic
        };
        this.props.onPublish(opts);
    }

    onDelete(){
        if (!window.confirm(EM.t('reports.publishing.deletePrompt')))return;
        this.props.onDelete();
    }

    validate(){
        if (!this.state.name){
            this.setState({ nameError: 'A name is required.' });
            return false;
        }else{
            this.setState({ nameError: null });
            return true;
        }
    }

    render() {  
        let groups = [{ value: 'Default', label: 'Default' }];            
        let groupList = EM.getSetting('ReportGroups');    
        if (groupList){
            groupList.split(',').map((group) => {
                return groups.push({ value: group, label: group });
            });
        }
        return (
            <Modal ref={el => (this.componentRef = el)} fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'demand-report-options ' + this.props.className} key="demand-report-options">
                <ModalHeader toggle={this.props.onClose}>
                    {EM.t('reports.publishing.title')}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>{EM.t('reports.publishing.name')}:</Label> 
                        <Input value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} invalid={this.state.nameError?true:false}/>   
                        <FormFeedback>{this.state.nameError}</FormFeedback>                    
                    </FormGroup>
                    <FormGroup>
                        <Label>{EM.t('reports.publishing.description')}:</Label> 
                        <Input value={this.state.description} onChange={(event) => this.setState({ description: event.target.value })}/>                       
                    </FormGroup>                    
                    <NoRender>
                        <FormGroup>
                            <Label>{EM.t('reports.publishing.grouping')}:</Label>
                            <Select
                                className="single-select"
                                classNamePrefix="single-select"
                                isSearchable={false}
                                options={groups}
                                value={this.state.grouping? { value: this.state.grouping, label: this.state.grouping } : groups[0]}
                                placeholder={EM.t('reports.publishing.grouping')}
                                onChange={(selectedItem) => {
                                    this.setState({ grouping: selectedItem.value });
                                }}
                            />   
                            <FormText>{EM.t('reports.publishing.reporGroupsMessage')}</FormText>
                        </FormGroup> 
                    </NoRender>
                    {EM.isDomainEditor()?
                        <FormGroup>
                            <Label>{EM.t('reports.publishing.public')}</Label> 
                            <ButtonGroup className="d-block">
                                <Button active={this.state.isPublic} onClick={() => {
                                    this.setState({ isPublic: true });
                                }}>{EM.t('util.common.yes')}</Button>
                                <Button active={!this.state.isPublic} onClick={() => {
                                    this.setState({ isPublic: false });
                                }}>{EM.t('util.common.no')}</Button>
                            </ButtonGroup>                              
                        </FormGroup>
                    : null }                                            
                </ModalBody>
                <ModalFooter>   
                    {this.state.isEdit ?                               
                        <Button color="danger" onClick={this.onDelete} className="mr-auto">{EM.t('reports.publishing.delete')}</Button>
                    : null }
                    <Button color="secondary" onClick={this.props.onClose}>{EM.t('util.closeButton')}</Button>
                    <Button color="success" onClick={this.onPublish}>{EM.t('reports.publishing.publish')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}