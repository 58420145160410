import React, { Component } from 'react';
import classNames from 'classnames';

class PageTitle extends Component {
    render() {
        return (
            <div className={classNames('clearfix page-title', this.props.bar?'page-title-bar':'', this.props.className)}>
                <h1>
                    {this.props.icon?<i className={this.props.icon}/>:null}
                    {this.props.img?<img src={this.props.img} alt={this.props.title}/>:null}
                    {this.props.title}
                </h1>    
                {!this.props.bar && this.props.text? 
                    <h2>{this.props.text}</h2>
                : null }
                {this.props.children?
                    <div className="page-title-children">
                        {this.props.children}
                    </div>
                :null}
                {this.props.bar && this.props.text? 
                    <p>{this.props.text}</p>
                : null }                
            </div>
        );
    }
}

export default PageTitle;
