/* global EM */
import React from 'react';
import Select from 'react-select';
import { InputGroup, InputGroupAddon, Button, FormGroup, ButtonGroup, FormText } from 'reactstrap';

export default function MetaStack(props){  
    return (
        <FormGroup className="option-switcher">
            <InputGroup>
                <Select
                    className="option-switcher single-select"
                    classNamePrefix="single-select"
                    isSearchable={true}
                    options={props.options}
                    value={props.value}
                    placeholder={EM.t('util.filtering.placeholderDefault')}
                    onChange={(selectedItem) => props.onChange(selectedItem.value) }
                /> 
                <InputGroupAddon addonType="append" title={EM.t('util.filtering.add')}>
                    <Button color="success" onClick={() => {
                        let name = window.prompt(EM.t('util.filtering.promptFilterSave'));
                        if (name) props.addResetPreferences(name);
                    }}><i className="fas fa-plus"></i></Button>
                </InputGroupAddon>
                <InputGroupAddon addonType="append" title={EM.t('util.filtering.copy')}>
                    <Button color="secondary" onClick={() => {
                        let name = window.prompt(EM.t('util.filtering.promptFilterSaveCopy'), props.value.value + ' Copy');
                        if (name) props.addResetPreferences(name, true);
                    }}><i className="fas fa-clone"></i></Button>
                </InputGroupAddon>                                   
            </InputGroup>
            <FormText className="clearfix btn-group-overflow">
                <ButtonGroup className="btn-group-sm float-right">
                    <Button className="" color="warning" title={EM.t('util.filtering.reset')} outline onClick={() => {
                        let confirmation = window.confirm(EM.t('util.filtering.confirmFilterReset'));
                        if (!confirmation)return;
                        props.addResetPreferences(props.value.value);
                    }}><i className="fas fa-sync"></i></Button>
                    {props.value.value !== 'default' ?
                        <Button className="" color="danger" title={EM.t('util.filtering.delete')} outline onClick={() => {
                            let confirmation = window.confirm(EM.t('util.filtering.confirmFilterDelete'));
                            if (!confirmation)return;
                            props.deletePreferences(props.value.value);
                        }}><i className="far fa-trash-alt"></i></Button>
                    : null }
                </ButtonGroup>
            </FormText>
        </FormGroup>
    );
}