/* global EM */
import React, { Component } from 'react';
import HelpIndex from '../../entities/help/HelpIndex';
import Routes from '../../app/Routes';
import ContactSupportButton from '../ContactSupportButton';
import MetaInfo from  '../MetaInfo';
import { Nav, NavItem, NavLink as BsNavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import DomainNotes from '../DomainNotes';

export default class HelpPanel extends Component {
    constructor(props){
        super(props);

        this.state = {
            title: null,
            body: null,
            pathname: null,
            activeTab: 'help',
            contentKey: null
        }

        this.loading = false;

        this.onTabChange = this.onTabChange.bind(this);
    }

    componentDidMount(){
        this.loadContent();        
    }

    componentDidUpdate(){
        if (window.location.pathname !== this.state.pathname)this.loadContent();
    }

    async loadContent(){
        if (this.loading)return;
        this.loading = true;
        HelpIndex.getContent().then((content) => {
            this.setState({ title: content.title, body: content.body, pathname: window.location.pathname, contentKey: content.contentKey });
            this.loading = false;
        });                
    }

    onTabChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {    
        let domain = EM.getActiveDomain();
        return (
            <div className="help-panel" id="help-panel"> 
                <div className="help-panel-header">                    
                    <Nav tabs>
                        <NavItem>
                            <BsNavLink className={classnames({ active: this.state.activeTab === 'help' || !domain })}
                                onClick={() => { this.onTabChange('help'); }}
                            ><h5>{EM.t('help.panelTitle')}</h5></BsNavLink>
                        </NavItem>
                        { domain && EM.hasFeature('notes') ? 
                            <NavItem>
                                <BsNavLink className={classnames({ active: this.state.activeTab === 'chat' })}
                                    onClick={() => { this.onTabChange('chat'); }}
                                ><h5>{EM.t('help.notesTitle')}</h5></BsNavLink>
                            </NavItem>
                        : null }
                    </Nav>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.onHelpClick}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <TabContent className="" activeTab={!domain ? 'help' : this.state.activeTab}>
                    <TabPane tabId="help">
                        {this.state.body ?
                            <div className="help-content" id="help-content">
                                <div className="help-title">
                                    <h6>{this.state.title}</h6>
                                    <a className="popout" href={Routes.compose(Routes.client.help, {}, {path: this.props.location.pathname})} target="manual" rel="noopener noreferrer">
                                        <i className="fas fa-external-link-alt"></i>
                                    </a>
                                </div>
                                <div className="help-body" dangerouslySetInnerHTML={{ __html: this.state.body }} />
                                <ContactSupportButton />                   
                                <MetaInfo className="text-center"/>               
                            </div>          
                        : null }
                    </TabPane>
                    <TabPane tabId="chat">
                        {domain && EM.hasFeature('notes')?                        
                            <DomainNotes contentKey={this.state.contentKey} notes={this.props.notes}/>
                        : null }
                    </TabPane>
                </TabContent>
            </div>
        );
    }
};