/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';
import _ from 'underscore';
import Dates from '../util/Dates';
import { Workbook } from '../util/Workbook';
import CopyableValue from '../components/CopyableValue';
import PageTitle from '../components/PageTitle';
import PieChart from '../components/PieChart';
import ColorManager from '../util/ColorManager';

export default class AdminUsers extends Component {
    constructor(props){
        super(props);
        EM.clearActiveDomain();    

        this.runAuditReport = this.runAuditReport.bind(this);

        EM.users.load();    
        
        this.defaultSort= {
            dataField: 'Active',
            order: 'desc'
        };
    }

    componentDidMount(){
        if (!EM.isTenantAdmin())return EM.denyFeature(); 
    }

    generateToken(userId){
        if (!EM.isSuperAdmin())return;
        let enteredText = window.prompt(EM.t('home.tokenPrompt'));
        if (enteredText !== userId.toString()){
            alert(EM.t('home.tokenGenCancelled'));
            return;
        }
        EM.api.generateUserToken(EM.me.Tenant.TenantId, userId).then((tokenDetails) => {
            let content = (
                <div>                
                    {EM.t('home.tokenLabel')}
                    <CopyableValue extraClasses="copyable-field">{(tokenDetails ? tokenDetails.Token : null) || EM.t('home.tokenError')}</CopyableValue>
                    <ul className="text-danger mt-3">
                        <li>{EM.t('home.tokenWarning1')}</li>
                        <li>{EM.t('home.tokenWarning2')}</li>
                        <li>{EM.t('home.tokenWarning3', false, [ Dates.fromISO(tokenDetails.TokenExpiration).toLocaleString() ] )}</li>
                    </ul>
                </div>
            );
            EM.setStatusMessage(content, 'dialog', 120000);
        }).catch((e) => {
            EM.setStatusMessage(EM.t('home.tokenError', false, [ e.message ]), 'error', 50000);
        });
    }

    async runAuditReport(){
        let usersFull = await EM.api.auditUsers(EM.me.Tenant.TenantId);
        let output = [{ data: ['Name', 'Email', 'Max Permission', 'Creation Date'], style: 'header' }];
        let summary = {
            Total: 0
        };
        let results = usersFull.reduce((acc, user) => {
            if (user.Email.indexOf('intrinsiccs') > -1 || 
                user.Email.indexOf('pharmica') > -1 || 
                user.Email.indexOf('onmicrosoft') > -1 ||
                user.Email.indexOf('propharmagroup') > -1 ||
                user.Email.indexOf('intrinsic-insights') > -1
            )return acc;
            let maxPerm = _.max(user.Permissions, perm => perm.PermissionLookupId);
            let permission = 'None';
            if (maxPerm && maxPerm.PermissionLookup){
                permission = maxPerm.PermissionLookup.Name;            
            }
            if (!summary[permission])summary[permission] = 0;
            summary[permission] += 1;
            summary.Total++;

            acc.push({ data: [ user.Name, user.Email, permission, user.CreatedOn ]});
            return acc;
        }, []);

        let summaryRow = Object.keys(summary).map(key => {
            return key + ': ' + summary[key];
        });

        Array.prototype.push.apply(output, results);
        Array.prototype.push.apply(output, [ { data: summaryRow, style: 'total' }]);

        new Workbook((wb) => {
            wb.addWorksheet('Max Licenses', output);
            wb.save('license-audit-report');
        });
    }

    async runLoginsReport(){
        let loginsFull = await EM.api.auditLogins(EM.me.Tenant.TenantId);
        if (!loginsFull)return;
        let output = [ { data: ['Name', 'Email', 'Last Login'], style: 'header' } ];
        loginsFull.reverse().forEach(row => {
            output.push({ data: [ row.Name, row.Email, Dates.fromISO(row.Timestamp).toJSDate() ], style: {
                cellFormats: [null, null, 'mmmm dd, yyyy']
            } });
        });

        new Workbook((wb) => {
            wb.addWorksheet('Logins', output);
            wb.save('login-audit-report');
        });
    }

    render() {    
        let data = null;
        let columns = null;
        let licensedUserCount = 0;
        let freeLicenses = 0;
        const entity = EM.users;        

        if (EM.allLoaded(entity)){    
            data = entity.get();
            columns = [{
                dataField: 'UserId',
                text: entity.columns('icon'),
                asUserIcon: true,
                width: 40,
                editable: false,
                creatable: false,
                csvExport: false
            },{
                dataField: 'Name',
                width: 200,
                text: entity.columns('name'),
                validators: [ Validators.required],
                filterOptions: {
                    text: true
                }        
            },{
                dataField: 'Email',
                width: 200,
                text: entity.columns('email'),
                editable: EM.isSuperAdmin(),
                validators: [ Validators.required ],
                filterOptions: {
                    text: true
                }
            },{
                dataField: 'Active',
                text: entity.columns('active'),
                asActive: true,
                width: 60
            }];

            let tenantLicenseCountOption = EM.getTenantOption('licenseCount');
            if (tenantLicenseCountOption){
                let bits = tenantLicenseCountOption.split(/[;,]/);
                freeLicenses = bits.reduce((memo, value) => {
                    return memo + parseInt(value.trim());
                }, 0);
            }

            licensedUserCount = data.reduce((memo, user) => {    
                if (!user.Active)return memo;
                if (user.Email.indexOf('intrinsiccs') > -1 || 
                    user.Email.indexOf('pharmica') > -1 || 
                    user.Email.indexOf('onmicrosoft') > -1 ||
                    user.Email.indexOf('propharmagroup') > -1 ||
                    user.Email.indexOf('intrinsic-insights') > -1
                ){
                    return memo;
                }else{
                    return memo + 1;
                }
            }, 0);
        }

        let title = EM.me.Tenant.DisplayName + ': ' + entity.t('title');        

        return (
            <div key="contents" className="page container-fluid">
                <PageTitle icon="fas fa-users" title={title} bar={true} />
                <div className="pre-table pre-table-content">
                    {data ? 
                        <PieChart id="licensesUsed" seriesName="Licenses" width={200} height={120} data={[
                            { name: 'Used', y: licensedUserCount, color: ColorManager.COLORS[0] },
                            { name: 'Free', y: freeLicenses, color: ColorManager.COLORS[7] },
                        ]} plotOptions={{
                            startAngle: -90,
                            endAngle: 90,
                            center: ['50%', '90%'],
                            size: '200%',
                            innerSize: 60,
                        }} seriesOptions={{
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }}/>
                    : null }
                    <div className="pl-2">
                        <h5>Licenses</h5>
                        <h6><i className="fas fa-circle font-sm" style={{color: ColorManager.COLORS[7]}}></i> Free: {freeLicenses}</h6>
                        <h6><i className="fas fa-circle font-sm" style={{color: ColorManager.COLORS[0]}}></i> Used: {licensedUserCount}</h6>
                    </div>
                    <div className="ml-auto">
                        <div className="btn-group">       
                            <button className="btn btn-info btn-sm" onClick={() => {
                                this.runAuditReport()
                            }}>Run License Report</button>    
                            <button className="btn btn-primary btn-sm" onClick={() => {
                                this.runLoginsReport();
                            }}>Run Login Report</button>       
                        </div>
                    </div>
                </div>
                <EntityTable forceMeta={true} pageTitle={' '} entity={entity} data={data} columns={columns}
                    className={"table-overflow-container with-pre-table"} 
                    hideClearAll={true} 
                    hideAudit={true} 
                    hideAddRecord={!EM.isSuperAdmin()}  
                    hideDelete={!EM.isSuperAdmin()}
                    hideImport={!EM.isSuperAdmin()} 
                    defaultSort={this.defaultSort} 
                    ref={ ref => {
                        this.tableRef = ref;
                    }} 
                >
                    {EM.isSuperAdmin() ? 
                        <div className="btn-group">
                            <button className="btn btn-warning btn-sm" onClick={() => {
                                if (this.tableRef.selectedRows.length === 0 || this.tableRef.selectedRows.length > 1){
                                    alert('Please select a single user.');
                                    return;
                                }
                                let user = this.tableRef.selectedRows[0];
                                if (!user)return;
                                this.generateToken(user.UserId);
                            }}>Generate a Token</button>   
                        </div> 
                    : null }
                </EntityTable>
            </div>
        );
    }
}
