import * as types from '../actionTypes';

export function globalError(error) {
    return { type: types.GLOBAL_ERROR, data: error };
}

export function localError(eid, error) {
    return { type: types.LOCAL_ERROR, eid: eid, data: error };
}

export function clearLocalError(eid) {
    return { type: types.CLEAR_LOCAL_ERROR, eid: eid };
}