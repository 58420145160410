/* global EM */
export default class FileTypeBase{
    constructor(data, entityName, id){
        if (data.statusCode){
            this.data = null;
        }else{
            this.data = data;
        }
        this.entityName = entityName;
        this.typeName = EM.t(this.entityName + '.dataTypeName');
        this.id = id;
    }

    asArray(){
        return this.data;
    }

    static validate() {
        console.log('FileBase validation called. No-op.');
        return null;
    }

    static preSaveTransform(csvContents) {    
        console.log('FileBase preSaveTransform called. No-op.');
        return csvContents;
    }   

    static preExportTransform(csvContents) {    
        console.log('FileBase preExportTransform called. No-op.');
        return csvContents;
    } 

    getKey(){
        return this.entityName + '-' + this.id;
    }
    
    getTypeName(){
        return this.typeName;
    }

    getShortName(prefs){        
        let tmp = this.typeName;
        if (this.name){
            tmp = `${tmp}: ${this.name}`;
        }
        return tmp;
    }

    getFullName(prefs){        
        let tmp = this.typeName;
        if (this.name){
            tmp = `${tmp}:\u000a${this.name}`;
        }
        if (prefs){
            if (prefs.reserve||prefs.attrition)tmp += '\u000a';
            if (prefs.reserve)tmp += '+\u00a0' + EM.t('reports.demand.reserve') + ' ';
            if (prefs.attrition)tmp += '+\u00a0' + EM.t('reports.demand.attrition');
        }
        return tmp;
    }
}