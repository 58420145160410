/* global EM */
import Entity from './Entity';
import * as projectionActions from './actions/projections';

export default class ProjectionsEntity extends Entity{
    async regenerate(scheduleId, isSilent){
        let state = this.store.getState();
        if (!state.domain)return;       
        EM.markDomainClean(state.domain.DomainId);
        return await this.dispatch(projectionActions.regenerateProjections(state.domain.DomainId, this, scheduleId, isSilent));
    }    

    async regenerateLocally(schedule){
        let state = this.store.getState();
        if (!state.domain)return;      
        //Do not mark domain clean at this point      
        return await this.dispatch(projectionActions.regenerateProjectionsLocally(state.domain.DomainId, this, schedule));
    }    

    async runDiagnosticsLocally(schedule){
        let state = this.store.getState();
        if (!state.domain)return;      
        //Do not mark domain clean at this point      
        return await this.dispatch(projectionActions.regenerateProjectionsLocally(state.domain.DomainId, this, schedule, true));
    }        
}