/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import OrganizationTabs from '../components/OrganizationsTabs';
import { Validators } from '../util/EntityValidators';

class Departments extends Component {
    constructor(props){
        super(props);

        EM.roles.load();
        EM.departments.load();
    }

    render() {    
        let data = null;
        let columns = null;
        const entity = EM.roles;        

        if (EM.allLoaded(entity, EM.departments)){    
            data = entity.get();
            columns = [{
                dataField: 'Name',
                text: entity.columns('name'),        
                validators: [ Validators.required ],
                filterOptions: {
                    text: true
                }   
            },{
                dataField: 'DepartmentId',
                text: entity.columns('department'),
                fromEntity: EM.departments,        
                validators: [ Validators.required ]
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <OrganizationTabs {...this.props} />
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container tabs"/>
            </div>
        );
    }
}

export default Departments;
