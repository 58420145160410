/* global EM */
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import Select from 'react-select';
import _ from 'underscore';

const customMultiValueLabel = ({ data, innerProps }) => (
    <div className={innerProps.className} title={data.value}>{ data.label }</div>
);

export default class NotificationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recipientList: [],
            recipients: [],
            message: ''
        };

        this.onClose = this.onClose.bind(this);
        this.onSend = this.onSend.bind(this);


        EM.loadEntities([EM.users, EM.employees]).then(() => {
            let recipientList = [];
            EM.users.get().forEach(user => {
                if (user.Active){
                    recipientList.push({ value: user.Email, label: user.Name });
                }
            });

            EM.employees.get().forEach(employee => {
                if (employee.Active && employee.Email){
                    recipientList.push({ value: employee.Email, label: employee.FirstName + ' ' + employee.LastName });
                }
            });
            recipientList = _.sortBy(recipientList, 'label');
            this.setState({ recipientList });
        });
    }

    onClose(){
        this.props.onClose();
    }     

    onSend(){    
        let message = null;
        if (this.props.messageTemplate){
            message = this.props.messageTemplate;
            if (message.indexOf('####') > -1){
                message = message.replace('####', this.state.message);
            }else{
                message = message + `<p>${this.state.message}</p>`;
            }
        }else{
            message = this.state.message;  
        }

        if (!message){
            alert('A message is required.');
            return;
        }

        let recipients = this.state.recipients.map(rec => {
            return rec.value;
        });

        if (recipients.length === 0){
            alert('At least one recipient is required.');
            return;
        }

        let details = {
            subject: this.props.subject,
            message: message,
            from: EM.me.User.Email,
            recipients: recipients.join(';')
        };

        EM.api.notify(details).then(() => {
            console.log('1');
            EM.setStatusMessage('Your message has been sent.');
        }).catch((e) => {
            console.log('2');
            EM.setStatusMessage('There was an error sending your message: ' + e.message, 'error', 10000);
        });

        this.props.onSend();
    }

    render() {
        return (
            <Modal fade={false} isOpen={this.props.isOpen} className={'notifications-dialog ' + (EM.currentTheme === 'Dark'?'dark-mode ': ' ') + this.props.className} backdrop="static">
                <ModalHeader toggle={this.onClose}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label>Recipients:</label><br />
                        <Select
                            className="multi-select"
                            classNamePrefix="multi-select"
                            isSearchable={true}
                            isMulti={true}
                            value={this.state.recipients}
                            options={this.state.recipientList}
                            placeholder={EM.t('util.filtering.placeholderDefault')}
                            onChange={(selectedItems) => {
                                this.setState({ recipients: selectedItems });
                            }}
                            components={ { MultiValueLabel: customMultiValueLabel } }
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Message:</label><br />
                        <Input type="textarea" value={this.state.message} maxLength={1000} onChange={(event) => {
                            this.setState({ message: event.target.value });
                        }}/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>               
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                    <Button color="primary" onClick={this.onSend}>{EM.t('util.send')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}