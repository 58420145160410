import React, { Component } from "react";
import { Button, Tooltip } from "reactstrap";
import Select from 'react-select';


class CustomTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
        };
    }

    toggleTooltip = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    };

    render() {
        const { targetId, text, placement } = this.props;
        return (
            <Tooltip
                isOpen={this.state.tooltipOpen}
                target={targetId}
                toggle={this.toggleTooltip}
                placement={placement}
            >
                {text}
            </Tooltip>
        );
    }
}

export default class BudgetToolSortByAmount extends Component {

    handleSortOrder = (bool) => {
        this.props.onSortChange(bool)
    };

    handleChange = (selectedOption) => {
        this.props.onChange(selectedOption);
    };

    toggleTooltip = () => { this.setState({ isHovered: !this.state.isHovered }) }
    render() {
        return (
            <div className="d-flex align-item-space-center mb-2" >
                <div style={{ width: "60%" }}>
                    <Select
                        className="single-select mr-2"
                        classNamePrefix="single-select"
                        options={this.props.dataOptions}
                        isSearchable={false}
                        value={this.props.selectedDataOption}
                        onChange={(selectedItem) => {
                            this.handleChange(selectedItem)
                        }}
                    />

                </div>
                <Button id="up-btn"
                    color={ this.props.selectedDataOption.isAscending ? "light" : "white"}
                    onClick={() => this.handleSortOrder(true)}
                    style={{ borderRadius: "5px 0 0 5px" }}>
                    <i className="fas fa-arrow-up"></i>
                </Button>
                <CustomTooltip targetId="up-btn" text="Sort Ascending" placement="bottom" />

                <Button id="bown-btn" color={ this.props.selectedDataOption.isAscending ? "white" : "light"}
                    onClick={() => this.handleSortOrder(false)}
                    style={{ borderRadius: "0 5px 5px 0" }}>
                    <i className="fas fa-arrow-down"></i>
                </Button>
                <CustomTooltip targetId="bown-btn" text="Sort Descending" placement="bottom" />
            </div>
        );
    }
}