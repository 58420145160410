/* global EM */
import React, { Component } from 'react';
import Spinner from './Spinner';
import _ from 'underscore';

export default class FetchIndicator extends Component {
    render() {
        if (this.props.items.length === 0)return null;
        return (
            <div className="fetch-indicator">
                <Spinner text={EM.t('util.loading')} classes="small"/>
                {_.uniq(this.props.items).map((item, index) => {
                    let itemName = item[0] === '#'?item.slice(1):item;
                    return (
                        <div key={index} className={item[0] === '#'?'item hiding':'item'}>
                            {itemName}
                        </div>
                    );
                })}
            </div>
        );
    }
}
